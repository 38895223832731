import React, {Component} from 'react';
import {EndPoints} from '../../utils/EndPoints';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      style: {
        // height: 300,
        width: 380,
        overflow: 'hidden',
        // border: "3px solid red",
        // borderStyle: "hidden",
        borderRadius: '8%',
        // display: "inline-block"
        // backgroundColor: "blue"
        // marginRight: 20,
        flex: '0 0 auto',
        alignSelf: 'flex-end',
        // pointerEvents: "none",
        position: 'relative',
      },
    };

    this.image = React.createRef();
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleX = this.handleX.bind(this);
  }

  handleMouseOver = () => {
    this.setState({
      style: {...this.state.style, borderStyle: 'inset'},
    });
  };

  handleMouseOut = () => {
    this.setState({
      style: {...this.state.style, borderStyle: 'hidden'},
    });
  };

  handleButtonPress() {
    this.buttonPressTimer = setTimeout(1500);
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  handleX = () => {
    console.log(this.image.current);
  };

  render() {
    return (
      <div style={{position: 'relative'}}>
        <img
          src={EndPoints.mediaURL + this.props.pictureURL}
          onClick={this.state.onClick}
          style={{...this.state.style, ...this.props.style}}
          // onClick={(e) => e.stopPropagation()}
          onLoad={this.props.onImgLoad}
          alt={''}
        ></img>
      </div>
    );
  }
}

export default Image;
