import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import Header from 'components/Headers/Header.js';
import {Button, Card, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table} from 'reactstrap';

import Filters from './Filters';
import BackendService from 'services/BackendService';
import {Formik} from 'formik';
import AddNew from "./AddNew";


const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(null);
  const [users, setUsers] = useState([]);
  const [modal, setModal] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    async function onMount() {
      setIsLoading(true);
      const users = await BackendService.getLocationsUsers(
        search,
      );
      setIsLoading(false);
      setUsers(users);
    }

    onMount();
  }, [search, refetch]);
  const toggle = () => setModal(!modal)
  return (
    <div className="Users">
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          Delete location user
        </ModalHeader>
        <ModalBody>
          Are you sure that you want to delete location user?
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="danger"
            onClick={async () => {
              const [location, userId] = modal.split('-')
              try {
                await BackendService.deleteLocationAssignedUsers([userId], location)
                toast.success('Successfully deleted user');
                setRefetch(!refetch)
                setModal(false)
              } catch (e) {
                toast.error('Something went wrong');
              }
            }}
          >
            Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer/>
      <Header/>
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filters
                {...{
                  search,
                  setSearch,
                  addNew,
                  setAddNew
                }}
              />
              {isLoading ? (
                <div className="Loading__wrapper">
                  <Spinner color="primary"/>
                </div>
              ) : (
                <>
                  {addNew ? <AddNew {...{refetch, setRefetch, setAddNew}} /> :
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                      <tr style={{marginLeft: 10}}>
                        <th scope="col">ID</th>
                        <th scope="col">Full Name</th>
                        <th scope="col">Location</th>
                        <th scope="col">Actions</th>
                      </tr>
                      </thead>
                      <tbody>
                      {(users || []).length ? (
                        users.map(
                          ({
                             userId,
                             firstName,
                             lastName,
                             location,
                           }) => {
                            return (
                              <Formik
                                key={`${location}-${userId}`}
                              >
                                {() => (
                                  <tr>
                                    <td>{userId}</td>
                                    <td>{`${firstName} ${lastName}`}</td>
                                    <td>{location}</td>
                                    <td>
                                      <span
                                        className="btn btn-danger btn-xm"
                                        onClick={() => setModal(`${location}-${userId}`)}
                                        type="button"
                                      >
                                        Delete
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Formik>
                            );
                          },
                        )
                      ) : (
                        <div className="no-results">No results found</div>
                      )}
                      </tbody>
                    </Table>}
                </>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Users;
