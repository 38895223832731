import React, {useEffect, useState} from 'react';
import Select from 'react-virtualized-select';
import BackendService from 'services/BackendService';

const CountrySelect = ({onChange, value}) => {
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState([]);
  useEffect(() => {
    async function onMount() {
      const {countries = []} = (await BackendService.getCountries()) || {};
      setCountries(
        countries.map((country) => {
          return {label: country.name, value: country.id};
        }),
      );
    }
    onMount();
  }, []);

  return (
    <Select
      name="country-select"
      value={value !== undefined ? value : countryId}
      options={countries}
      placeholder="Country"
      clearable={true}
      onChange={(e) => {
        setCountryId(e && e.value);
        onChange && onChange(e);
      }}
    ></Select>
  );
};

export default CountrySelect;
