import * as React from 'react';
import {EndPoints} from '../utils/EndPoints';
import BackendService from '../services/BackendService';
import {Row} from 'react-bootstrap';
const {Input, Col, Button, Card, CardBody, CardFooter} = require('reactstrap');

function transformGeoPointToStr(geoPoint) {
  // transform geoPoint into string of coordinates for readability
  if (geoPoint && geoPoint.coordinates) {
    let coordStr = `${geoPoint.coordinates[1]},${geoPoint.coordinates[0]}`;
    return coordStr;
  }
  return '';
}

const PictureItem = ({picture, onDeletePicture, onEditPicture, alert}) => {
  const [coordinates, setCoordinates] = React.useState(
    transformGeoPointToStr(picture?.geoPoint ?? {coordinates: [0, 0]}),
  );
  const [originalUrl, setOriginalUrl] = React.useState(
    picture?.originalUrl ?? '',
  );
  const [order, setOrder] = React.useState(picture?.order ?? 0);

  const onDeletePress = () => {
    BackendService.deletePicture(picture?.pictureId || picture?.id).then(
      (response) => {
        if (response.status === 200) {
          onDeletePicture && onDeletePicture();
          alert('Picture successfully deleted. Restart page to view result');
        }
      },
    );
  };

  const onEditPress = () => {
    BackendService.editPicture(picture?.pictureId || picture?.id, {
      ...(coordinates ? {coordinates} : {}),
      ...(originalUrl ? {originalUrl} : {}),
      order,
    })
      .then((response) => {
        if (response.status === 200) {
          onEditPicture && onEditPicture();
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          alert(err.response.data.errors.map((err) => err.msg).join(','), true);
        }
      });
  };
  return (
    <Col style={{maxWidth: '400px', minWidth: '350'}}>
      <Card style={{marginBottom: '10px'}}>
        <img
          style={{
            maxWidth: '300px',
            maxHeight: '300px',
            borderRadius: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginTop: '10px',
          }}
          src={EndPoints.mediaURL + picture?.bucketUrl}
          alt=""
        />

        <CardBody>
          {/* <Input
            className="form-control-alternative"
            placeholder="Order"
            type="number"
            value={order}
            onChange={(e) => setOrder(e.target.value)}
            style={{
              width: '100%',
              height: 40,
              float: 'left',
              marginTop: 10,
              position: 'relative',
            }}
          />
          <Input
            className="form-control-alternative"
            placeholder="Original Image URL"
            value={originalUrl || ''}
            type="text"
            style={{
              width: '100%',
              height: 40,
              float: 'left',
              marginTop: 10,
              position: 'relative',
            }}
            onChange={(e) => setOriginalUrl(e.target.value)}
          /> */}
          <Input
            className="form-control-alternative"
            placeholder="Coordinates"
            type="text"
            value={coordinates || ''}
            onChange={(e) => setCoordinates(e.target.value)}
            style={{
              width: '100%',
              height: 40,
              float: 'left',
              marginTop: 10,
              position: 'relative',
            }}
          />
        </CardBody>

        <CardFooter>
          <Row>
            <Col>
              <Button
                color={'success'}
                onClick={onEditPress}
                style={{width: '100%'}}
              >
                Save
              </Button>
            </Col>
            <Col>
              <Button
                color="danger"
                onClick={onDeletePress}
                style={{width: '100%'}}
              >
                Remove
              </Button>
            </Col>
          </Row>
        </CardFooter>
      </Card>
    </Col>
  );
};

export default PictureItem;
