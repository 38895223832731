require('dotenv').config();

const baseURL = 'https://us-central1-framey-io.cloudfunctions.net/';
// const coreURL = 'http://localhost:3000/v1/';
const coreURL = `${process.env.REACT_APP_API_URL}/v1/`;
const coreURLV2 = `${process.env.REACT_APP_API_URL}/v2/`;
//const coreURL = 'https://core.framey.io/v1/';
const mediaURL = process.env.REACT_APP_MEDIA_URL || 'https://development.img.framey.io/photos/'
// const previewURL = 'http://207.154.215.202/photos/300/';

const getUserInfos = 'user';
const createUser = 'users/createUser';
const loginUser = 'login';

const removePic = 'pictures';
const getAllAssignees = 'user/allAssignees';
const getAllCountries = 'country/getCountries';

const uploadPhoto = 'pictures';

const getMyCollections = 'collection/getMyCollections';
const createCollection = 'users/createCollection';
const getMySavedItemsByCollection = 'users/getMySavedItemsByCollection?id=';

const getAttractions = 'attractions/dashboard/';
const getAttraction = 'attractions/';
const getNearbyAttractions = 'attraction/getNearby/';
const addToCollection = 'collection/addToCollection';
const removeAttractionFromCollection = 'attractions/unsaveAttraction';
const editAttraction = 'attractions/';
const removeAttraction = 'attractions/';
const removeDestination = 'destination/removeDestination/';
const addAttraction = 'attractions/';
const addDestination = 'destination/addDestination/';
const editDestination = 'destination/editDestination/';

const getContributorAttractions = 'getAttractionsByOwner?name=';

const getDestinations = 'destination/getDestinations/';
const getDestination = 'destinations/';
const getAllDestinations = 'destination/getAllDestinations/';

const getManagedCollections = 'collection/getManagedCollections/';
const searchAttractionsByName = 'attraction/searchAttractionsByName/';
const getAllTags = 'tags/';

export const EndPoints = {
  baseURL,
  coreURL,
  coreURLV2,
  uploadPhoto,
  removePic,
  mediaURL,
  getManagedCollections,
  getAllAssignees,
  getAllCountries,

  getUserInfos,
  loginUser,
  createUser,
  getMyCollections,
  createCollection,
  getMySavedItemsByCollection,

  addDestination,

  getAttractions,
  getAttraction,
  getNearbyAttractions,
  addToCollection,
  removeAttractionFromCollection,
  editAttraction,
  removeAttraction,
  removeDestination,
  addAttraction,

  editDestination,

  getDestinations,
  getDestination,
  getAllDestinations,

  searchAttractionsByName,

  getContributorAttractions,

  getAllTags,
};
