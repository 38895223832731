import React, {useEffect, useState} from 'react';

import Select from 'react-select';
import {Button, Col, FormGroup, Input, Label} from 'reactstrap';
import BackendService from "../../services/BackendService";

const AddLocationAssignedUsers = ({refetch, setRefetch, setAddNew}) => {
  const [assignedUsers, setAssignedUsers] = useState([])
  const [location, setLocation] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState("")
  useEffect(() => {
    const onMount = async () => {
      const {users} = await BackendService.getUsers(search)
      setUsers(users.map(({firstName, lastName, username, id}) => ({value: id, label: `${firstName} ${lastName}, (${username})`})))
    }
    onMount()
  }, [search])

  const updateAssignedUser = async () => {
    setIsLoading(true);
    await BackendService.addLocationAssignedUsers(assignedUsers.map(({value}) => value), location)
    setRefetch(!refetch);
    setAddNew(false);
    setIsLoading(false);
  }
  return (
    <div className="pl-2">
      <Col xs={4}>
        <Label>Location</Label>
        <Input onChange={e => setLocation(e.target.value)} />
      </Col>
      <Col xs={4}>
        <FormGroup className="mt-3">
          <Label>Assigned Users</Label>
          <Select
            id="filter-contentType"
            name="contentType"
            value={assignedUsers}
            disabled={isLoading}
            onInputChange={value => setSearch(value)}
            onChange={(value) => {
              setAssignedUsers(value)
            }}
            options={users}
            placeholder="Assign users on category"
            multi
          />
        </FormGroup>
        <Button onClick={updateAssignedUser} className="mt-3 mb-3" color="primary">Save</Button>
      </Col>
    </div>
  );
};

export default AddLocationAssignedUsers;
