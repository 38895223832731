import React, {useState} from 'react';
import Input from 'reactstrap/lib/Input';

const Select = ({options = [], defaultValue = {}, onInputChange, onChange}) => {
  const [menu, setMenu] = useState(false);
  const [selectValue, setValue] = useState(defaultValue);
  return (
    <div className="CustomSelect">
      {!menu && (
        <span className="selected" onClick={() => setMenu(true)}>
          {selectValue.label}
          <span className="Select-arrow"></span>
        </span>
      )}
      {menu && (
        <>
          <Input
            className="input"
            autoFocus
            onBlur={() => setTimeout(() => setMenu(false), 150)}
            onChange={(e) => {
              onInputChange && onInputChange(e.target.value);
            }}
          />
          <div className="menu">
            {options.length ? (
              options.map(({value, label}) => (
                <span
                  key={value}
                  className={value === selectValue?.value && 'selected'}
                  onClick={() => {
                    setValue({value, label});
                    onChange && onChange({value, label});
                  }}
                  value={value}
                >
                  {label}
                </span>
              ))
            ) : (
              <span>No results found</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Select;
