import React, {useEffect, useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import Pagination from 'react-pagination-js';
import Header from 'components/Headers/Header.js';
import {
  Card,
  Container,
  Row,
  Table,
  Spinner,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  ModalFooter,
  Button, Modal
} from 'reactstrap';
import CardFooter from 'reactstrap/lib/CardFooter';

import Filters from './Filters';
import BackendService from 'services/BackendService';
import {Form, Formik} from 'formik';
import Select from 'react-virtualized-select';
import backendService from "services/BackendService";

const ELEMENTS_PER_PAGE = 20;

const getHumanReadableTime = (time) => {
  var localDate = new Date(time);
  return localDate.toLocaleString();
};

const levels = [
  {
    value: 7,
    label: 'Potential',
  },
  {
    value: 6,
    label: 'Ambassador',
  },
  {
    value: 5,
    label: 'Creator',
  },
  {
    value: 4,
    label: 'Regular',
  },
];

const Users = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [verified, setVerified] = useState(null);
  const [level, setLevel] = useState(null);
  const [search, setSearch] = useState(null);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [modal, setModal] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false)

  useEffect(() => {
    async function onMount() {
      setIsLoading(true);
      const {users, count} = await BackendService.getUsers(
        search,
        level,
        verified,
        (currentPage - 1) * ELEMENTS_PER_PAGE,
        20,
      );
      setIsLoading(false);
      setUsers(users);
      setCount(count);
    }
    onMount();
  }, [level, currentPage, verified, search, shouldRefetch]);
  const toggle = () => setModal(!modal)
  return (
    <div className="Users">
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          Delete user
        </ModalHeader>
        <ModalBody>
          Are you sure that you want to delete user and related posts?
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            color="danger"
            onClick={async () => {
              try {
                await backendService.deleteUser(modal)
                toast.success('Successfully deleted user');
                setShouldRefetch(!shouldRefetch)
                setModal(false)
              } catch (e) {
                toast.error('Something went wrong');
              }
            }}
          >
           Delete
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <ToastContainer />
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filters
                {...{
                  level,
                  setLevel,
                  verified,
                  setVerified,
                  search,
                  setSearch,
                }}
              />
              {isLoading ? (
                <div className="Loading__wrapper">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{marginLeft: 10}}>
                      <th scope="col">Full Name</th>
                      <th scope="col">Username</th>
                      <th scope="col">Email</th>
                      <th scope="col">Level</th>
                      <th scope="col">Verified</th>
                      <th scope="col">Actions</th>
                      <th scope="col">Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(users || []).length ? (
                      users.map(
                        ({
                          id,
                          firstName,
                          lastName,
                          username,
                          email,
                          userRoleId,
                          verifiedBadge,
                          createdAt,
                        }) => {
                          return (
                            <Formik
                              key={id}
                              initialValues={{
                                userRoleId,
                                verifiedBadge,
                              }}
                              onSubmit={async (values) => {
                                try {
                                  await BackendService.updateUser(id, values);
                                  toast.success('Successfully updated user');
                                } catch (error) {
                                  toast.error('Something went wrong');
                                }
                                return false;
                              }}
                            >
                              {({
                                handleSubmit,
                                handleChange,
                                values,
                                setFieldValue,
                              }) => (
                                <tr>
                                  <td>{`${firstName} ${lastName}`}</td>
                                  <td>{username}</td>
                                  <td>{email}</td>
                                  <td>
                                    <Select
                                      style={{minWidth: 130}}
                                      onChange={({value}) =>
                                        setFieldValue('userRoleId', value)
                                      }
                                      value={values.userRoleId}
                                      options={levels}
                                    />
                                  </td>
                                  <td>
                                    <input
                                      checked={values.verifiedBadge}
                                      type="checkbox"
                                      name="verifiedBadge"
                                      onChange={handleChange}
                                    />
                                  </td>
                                  <td>
                                    <span
                                      className="btn btn-primary btn-xm"
                                      onClick={handleSubmit}
                                      type="button"
                                    >
                                      Save
                                    </span>
                                    <span
                                      className="btn btn-danger btn-xm"
                                      onClick={() => setModal(id)}
                                      type="button"
                                    >
                                      Delete
                                    </span>
                                  </td>
                                  <td>{getHumanReadableTime(createdAt)}</td>
                                </tr>
                              )}
                            </Formik>
                          );
                        },
                      )
                    ) : (
                      <div className="no-results">No results found</div>
                    )}
                  </tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                <Pagination
                  currentPage={currentPage}
                  totalSize={count}
                  sizePerPage={ELEMENTS_PER_PAGE}
                  changeCurrentPage={(page) => setCurrentPage(page)}
                  theme="bootstrap"
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Users;
