import React from 'react';

import BackendService from '../../services/BackendService';
import { JWT_KEY, USER_KEY } from '../../utils/consts';

import { withRouter, Link } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import { Creatable } from 'react-select';
import { UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faImages } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { EndPoints } from 'utils/EndPoints';
import { Album } from '../../components/Album';

class Destination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      destination: {
        name: '',
        description: '',
        shortDescription: '',
        geoPoint: '',
        pictures: [],
        Country: {
          id: null,
          name: '',
        },
        status: '',
        type: '',
        parent: {
          value: null,
          label: '',
        }
      },
      countryList: [
        {
          value: null,
          label: null,
        },
      ],
      tagList: [],
      assigneesList: [],
      selectedTags: null,
      selectedCountry: null,
      selectedAssignee: null,
      selectedParent: null,
      selectedType: null,
      updateButtonText: 'Add',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertText: '',
      isTextPublish: true,
      isAdmin: false,
      historyObj: {},
      showSaveButton: true,
      typeList: [
        {
          value: 'village',
          label: 'village'
        },
        {
          value: 'town',
          label: 'town'
        },
        {
          value: 'city',
          label: 'city'
        },
        {
          value: 'region',
          label: 'region'
        }
      ],
      parentList: [
        {
          value: null,
          label: null,
        },
      ]
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onUpdateDestinationData = this.onUpdateDestinationData.bind(this);
    this.onUpdatePics = this.onUpdatePics.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  async componentDidMount() {
    let historyObj;
    if (this.props.location) {
      const destinationId = this.props.match.params.id;
      historyObj = this.props.location.historyObj;
      if (destinationId && destinationId !== 'new') {
        this.getDestination(destinationId);
      }
    }
    this.getAllAssignees();
    const { countries } = await BackendService.getCountries();
    const tagList = await BackendService.getAllTags();
    const isAdmin = await this.isUserAdmin();
    const destinations = await BackendService.getAllDestinations();

    this.setState({
      parentList: destinations.result.map((dest) => ({
        value: dest.value,
        label: dest.label,
      }))
    });
    this.setState({
      countryList: countries.map((country) => ({
        value: country.id,
        label: country.name,
      })),
      tagList,
      isAdmin,
      historyObj,
    });

  }

  onImageUpload = (e) => {
    const { id } = this.props.match.params;

    const errs = [];
    const files = Array.from(e.target.files);
    console.log(files);

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time';
      return this.alertErrorMsg(msg);
    }

    const formData = new FormData();
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    const supportedFiles = files.filter((file) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
        return false
      }
      return true
    });

    if (errs.length) {
      return this.alertErrorMsg(errs.join(','));
    }

    this.setState({ uploadingImages: true });
    const jwt = localStorage.getItem(JWT_KEY);

    supportedFiles.forEach(async (file, i) => {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${EndPoints.coreURL}pictures/destination/${id}`, formData, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ uploadingImages: false });
            this.getDestination(id);
          }
        })
        .catch((err) => {
          this.setState({ uploadingImages: false });
          if (err.response) {
            this.alertErrorMsg(err.response.data.message, true);
          } else {
            this.alertErrorMsg(
              'Something went wrong. Operation was not completed. Please try again',
              true,
            );
          }
        });


    });


  };

  getImages = async () => {
    const { id } = this.props.match.params;

    BackendService.getPictures('destination', id)
      .then(({ data }) => {
        if (data.message === 'OK') {
          this.setState({
            destination: {
              ...this.state.destination,
              pictures: data.pictures.sort((a, b) => a.order - b.order),
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        }
      });
  };

  getAllAssignees = async () => {
    const { result } = await BackendService.getAllAssignees('dropdown');
    result.map(
      (assignee) => (assignee.label = assignee.label + ' ' + assignee.label2),
    );
    this.setState({ assigneesList: result });
  };

  isUserAdmin = async () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    // attention: user stored in localStorage (loggedIn user) is different than Destination.User (assignee)
    console.log(user);
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin' ||
        user.userRole === 'Admin')
    ) {
      return true;
    }
    return false;
  };

  getDestination = async (id) => {
    const { destination } = await BackendService.getDestination(id);
    /// get all tags of Destination and add them in state
    if (destination) {
      const destinationTags =
        (destination.Tags &&
          destination.Tags.map((tag) => tag.label).join(',')) ||
        null;

      if (destination.parent) {
        this.setState({
          selectedParent: {
            value: destination.parent.id,
            label: destination.parent.name,
          }
        })
      }

      if (destination.type) {
        this.setState({
          selectedType: {
            value: destination.type,
            label: destination.type,
          }
        })
      }


      this.setState({
        destination,
        selectedCountry: {
          value: destination.country.id,
          label: destination.country.name,
        },
        selectedTags: destinationTags,
        // selectedAssignee: {
        //   value: destination.user.id,
        //   label: destination.user.firstName + ' ' + destination.user.lastName,
        // },
        updateButtonText: 'Update',
        isTextPublish: !(destination.status === 'PUBLISHED')
      });
    } else {
      console.log('Something went wrong. Please try again.');
    }
  };

  submit = () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: `Do you want to permanently delete ${this.state.destination.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await BackendService.removeDestination(this.state.destination.id);
            this.props.history.push('/admin/destinations');
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  onPublishDestination = async () => {
    const { id } = this.state.destination;
    const status = (this.state.isTextPublish && 'PUBLISHED') || 'UNPUBLISHED';
    await BackendService.editDestination({ id, status });
    this.setState({ isTextPublish: !this.state.isTextPublish });
  };

  onFieldChange = (fieldName, value) => {
    const newState = { ...this.state.destination };

    let newValue = value
    if(value && value.length > 0){
      newValue = value.trim()
    }

    if (fieldName === 'coordinates') {
      newValue = newValue === '' ? '0,0' : newValue;
      newState['geoPoint'] = {
        type: 'Point',
        coordinates: this.transformStrToGeoPoint(newValue),
      };
    } else {
      newState[`${fieldName}`] = newValue;
    }
    this.setState({ destination: newState });
  };

  isDataValid = (obj) => {
    if (!obj.name || obj.name === '') {
      return 'Invalid name';
    }
    if (!this.state.selectedCountry) {
      return 'Invalid country';
    }


    // if (!obj.tags) {
    //   return "Invalid tags";
    // }
    // if (!obj.Pictures || obj.Pictures.length < 1) {
    //   return "Minimum one picture required";
    // }
    // if (!obj.assigneeId) {
    //   return 'Sorry, you have to log in again';
    // }
    // if (!this.state.selectedAssignee || !this.state.selectedAssignee.value) {
    //   return 'Assignee is required';
    // }
    return true;
  };

  onUpdateDestinationData = async () => {
    const destination = this.state.destination;

    //change tag format
    destination.Tags = undefined;
    destination.tags =
      (this.state.selectedTags && this.state.selectedTags.split(',')) || [];

    const user = JSON.parse(localStorage.getItem(USER_KEY));
    // destination['assigneeId'] = user.id;

    const isError = this.isDataValid(destination);
    //// if there are validation errors, show error alert with message
    if (isError !== true) {
      window.scrollTo(0, 0);
      this.setState({ showErrorAlert: true, errorAlertText: isError }, () => {
        setTimeout(() => {
          this.setState({ showErrorAlert: false, errorAlertText: '' });
        }, 3000);
      });
    } else {
      /// call add/edit endpoint and handle the result types / show alerts based on result
      // change destinationId format needed for the backend
      let result;
      destination.Country = undefined;
      destination.countryId = this.state.selectedCountry.value;
      // destination.assigneeId = this.state.selectedAssignee.value || null;

      if (this.state.selectedParent) {
        destination.parentId = this.state.selectedParent.value;
      }

      destination.type = this.state.selectedType ? this.state.selectedType.value : undefined;

      if (this.state.updateButtonText === 'Add') {
        result = await BackendService.addDestination(destination);
        this.props.history.push(`/admin/destination/${result.destination.id}`);
      } else if (this.state.updateButtonText === 'Update') {
        result = await BackendService.editDestination(destination);
        /// get updated version of Destination
        this.getDestination(destination.id);
      }

      // this.getDestination(Destination.id);
      if (result.type === 'error') {
        /// show error alert for 3 seconds if there are server errors
        this.setState(
          { showErrorAlert: true, errorAlertText: result.message },
          () => {
            setTimeout(() => {
              this.setState({ showErrorAlert: false, errorAlertText: '' });
            }, 3000);
          },
        );
      } else {
        /// show success alert for 3 seconds if all is good
        this.setState({ showSuccessAlert: true, showSaveButton: false }, () => {
          setTimeout(() => {
            this.setState({
              showSuccessAlert: false,
              updateButtonText: 'Update',
              showSaveButton: true,
            });
          }, 3000);
        });
      }
    }
  };

  removePic = async (id) => {
    if (this.state.destination.id) {
      await BackendService.removePic(id);
      this.getDestination(this.state.destination.id);
    }
  };

  transformGeoPointToStr(coord) {
    // transform geoPoint into string of coordinates for readability
    if (coord && coord.coordinates) {
      let coordStr = `${coord.coordinates[1]},${coord.coordinates[0]}`;
      return coordStr;
    }
    return '';
  }

  transformStrToGeoPoint(coord) {
    if (coord) {
      let newCoord;
      newCoord = coord.replace(' ', '');
      newCoord = newCoord.split(',');
      let coordStr = [newCoord[1], newCoord[0]];
      return coordStr;
    }
    return '';
  }

  onUpdatePics = (newPictures) => {
    this.getDestination(this.state.destination.id);
  };

  handleCountryChange = (selectedCountry) => {
    this.setState({ selectedCountry });
  };

  handleParentChange = (selectedParent) => {
    this.setState({ selectedParent });
  }

  handleTypeChange = (selectedType) => {
    this.setState({ selectedType });
  }

  handleTagChange = (selectedTags) => {
    this.setState({ selectedTags });
  };

  handleAssigneeChange = (selectedAssignee) => {
    this.setState({
      selectedAssignee,
    });
  };

  render() {
    const successAlertText = `Destination ${(this.state.updateButtonText === 'Add' && 'added') || 'updated'
      }`;
    const errorAlertText = `${this.state.errorAlertText}`;
    const { destination } = this.state;
    const albums = destination?.albums ?? [];
    return (
      <>
        <div style={{ paddingTop: 200 }} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Link
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            to={{
              pathname: '/admin/destinations',
              historyObj: this.state.historyObj,
            }}
            style={{ marginRight: 5, paddingBottom: 20 }}
          >
            <i className="fas fa-arrow-circle-left fa-lg"></i>
            <i
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              BACK
            </i>
          </Link>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row>
                    <Col xs="8">
                      {/* <h3 className="mb-0"></h3> */}
                      <UncontrolledAlert
                        color={
                          (this.state.showErrorAlert && 'danger') || 'success'
                        }
                        style={{ width: 400, position: 'absolute' }}
                        isOpen={
                          this.state.showSuccessAlert ||
                          this.state.showErrorAlert
                        }
                      >
                        {(this.state.showSuccessAlert && successAlertText) ||
                          errorAlertText}
                      </UncontrolledAlert>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.isAdmin &&
                        this.state.updateButtonText !== 'Add' && (
                          <Button
                            color={
                              (this.state.isTextPublish && 'success') || 'info'
                            }
                            // href="#pablo"
                            onClick={this.onPublishDestination}
                            size="m"
                          >
                            {(this.state.isTextPublish && 'Publish') ||
                              'Unpublish'}
                          </Button>
                        )}
                      {this.state.isAdmin && this.state.showSaveButton && (
                        <Button
                          color="primary"
                          // href="#pablo"
                          onClick={this.onUpdateDestinationData}
                          size="m"
                        >
                          {this.state.updateButtonText}
                        </Button>
                      )}

                      {this.state.isAdmin && (
                        <Button
                          style={{ color: 'red' }}
                          // color="#E5E5E5"
                          // href="#pablo"
                          onClick={this.submit}
                          size="m"
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Destination information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              NAME
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.destination.name || ''}
                              id="input-attrname"
                              placeholder="Name"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('name', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        {/* {this.state.isAdmin && (
                          // <Col lg="6">
                          //   <FormGroup>
                          //     <label
                          //       className="form-control-label"
                          //       htmlFor="input-last-name"
                          //     >
                          //       ASSIGNEE
                          //     </label>
                          //     <Select
                          //       name="assignee"
                          //       // value="one"
                          //       options={this.state.assigneesList}
                          //       value={this.state.selectedAssignee}
                          //       onChange={(val) =>
                          //         this.handleAssigneeChange(val)
                          //       }
                          //     />
                          //   </FormGroup>
                          // </Col>
                        )} */}
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              COUNTRY
                            </label>
                            <Select
                              name="country"
                              // value="one"
                              options={this.state.countryList}
                              value={this.state.selectedCountry}
                              onChange={(val) => this.handleCountryChange(val)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              TAGS
                            </label>
                            <Select
                              name="tags"
                              // value="one"
                              clearable={true}
                              // autofocus
                              labelKey="label"
                              options={this.state.tagList}
                              value={this.state.selectedTags || ''}
                              valueKey="label"
                              searchable={true}
                              multi={true}
                              simpleValue
                              onChange={(val) => this.handleTagChange(val)}
                              selectComponent={Creatable}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Parent
                            </label>
                            <Select
                              name="parent"
                              // value="one"
                              options={this.state.parentList}
                              value={this.state.selectedParent}
                              onChange={(val) => this.handleParentChange(val)}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Type
                            </label>
                            <Select
                              name="type"
                              // value="one"
                              options={this.state.typeList}
                              value={this.state.selectedType}
                              onChange={(val) =>
                                this.handleTypeChange(val)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>


                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Shareable Branch URL
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly
                              defaultValue={
                                this.state.destination.branchUrl || ''
                              }
                              id="input-branch-url"
                              placeholder="Auto-generated Branch URL"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row></Row>
                    </div>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              SHORT DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={
                                this.state.destination.shortDescription
                              }
                              id="input-address"
                              placeholder={
                                this.state.destination.shortDescription
                              }
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'shortDescription',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      {/* <div className="pl-lg-4"> */}
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              placeholder={this.state.destination.description}
                              rows="4"
                              defaultValue={this.state.destination.description}
                              type="textarea"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'description',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr className="my-4" />
                      {/* Address */}
                      <h6 className="heading-small text-muted mb-4">
                        LOCATION
                      </h6>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-coord"
                            >
                              Coordinates
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={
                                (this.state.destination.geoPoint &&
                                  this.transformGeoPointToStr(
                                    this.state.destination.geoPoint,
                                  )) ||
                                ''
                              }
                              id="input-coord"
                              placeholder="Ex.: 45.106, 24.541"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'coordinates',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="2">
                          <h4 className="alert-heading">Single image upload</h4>

                          <FormGroup>
                            <label htmlFor="single">
                              <FontAwesomeIcon
                                icon={faImage}
                                color="#3B5998"
                                size="3x"
                              />
                            </label>
                            <input
                              type="file"
                              id="single"
                              onChange={this.onImageUpload}
                              className="upload-input-file"
                            />
                          </FormGroup>
                        </Col>

                        <Col xs="2">
                          <h4 className="alert-heading">
                            Multiple images upload
                          </h4>

                          <FormGroup>
                            <label htmlFor="multi">
                              <FontAwesomeIcon
                                icon={faImages}
                                color="#3B5998"
                                size="3x"
                              />
                            </label>
                            <input
                              className="upload-input-file"
                              type="file"
                              id="multi"
                              onChange={this.onImageUpload}
                              multiple
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Container>
                        {albums.map((album, index) => {
                          return (
                            <Album
                              key={`album${album.albumId}`}
                              album={album}
                              contentType="destination"
                              contentId={this.state.destination.id}
                              onMutate={this.onUpdatePics}
                              alert={console.log}
                            />
                          );
                        })}
                      </Container>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Destination);
