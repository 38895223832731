import React from 'react';

import BackendService from '../../services/BackendService';
import { USER_KEY, JWT_KEY } from '../../utils/consts';

import { withRouter, Link } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
// core components

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import { Creatable } from 'react-select';
import { UncontrolledAlert } from 'reactstrap';
import { client } from 'utils/api-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faImages } from '@fortawesome/free-solid-svg-icons';
import { Album } from '../../components/Album';
import axios from 'axios';
import { EndPoints } from 'utils/EndPoints';
import AttractionCategories from "../../components/AttractionCategories";

class Attraction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attraction: {
        name: '',
        description: '',
        openHours: {
          monday: {},
          tuesday: {},
          wednesday: {},
          thursday: {},
          friday: {},
          saturday: {},
          sunday: {},
        },
        pictures: [],
        destination: {
          id: null,
          name: '',
        },
        status: '',
      },
      attractions: [],
      destinationList: [
        {
          value: null,
          label: null,
          country: {}
        },
      ],
      tagList: [],
      selectedTags: null,
      selectedDestination: null,
      updateButtonText: 'Save',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertText: '',
      isTextPublish: true,
      isAdmin: false,
      historyObj: {},
      showSaveButton: true,
      uploadingImages: false,
    };
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onUpdateAttractionData = this.onUpdateAttractionData.bind(this);
    this.handleDestinationChange = this.handleDestinationChange.bind(this);
    this.getAttraction = this.getAttraction.bind(this);
    this.getAllAttractions = this.getAllAttractions.bind(this);

  }

  async componentDidMount() {
    let historyObj;
    if (this.props.location) {
      const attractionId = this.props.match.params.id;
      historyObj = this.props.location.historyObj;

      if (attractionId && attractionId !== 'new') {
        this.getAllAttractions(attractionId)
        this.getAttraction(attractionId);
      }
    }
    let destinations;
    const isAdmin = this.isUserAdmin();
    if (!isAdmin) {
      const user = JSON.parse(localStorage.getItem(USER_KEY));
      destinations = await BackendService.getAllDestinations({
        assigneeId: user.id,
      });
    } else {
      destinations = await BackendService.getAllDestinations();
    }

    const tagList = await BackendService.getAllTags();

    const destinationList = destinations.result.map(({Country = {}, value, label}) => ({value, label: `${label}${Country?.name ? `, ${Country?.name}` : ''}`}));
    this.setState({
      destinationList,
      tagList,
      isAdmin,
      historyObj,
    });
  }



  isUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    //// attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  };

   async getAllAttractions(attractionId, search) {
    const {attractionLabels} =
      await BackendService.fetchAttractionsForDashboard(search);
    const finalContent = attractionLabels.filter(
      (attr) => attractionId !== attr.value,
    ).map(({label, value}) => ({label: `id: ${value} - ${label}`, value}));
    this.setState({attractions: finalContent})
  }

  async getAttraction(attractionId) {
    const token = localStorage.getItem(JWT_KEY);
    const id = this.props.match.params.id;

    const { attraction } = await client(`v1/attractions/${attractionId ?? id}`, {
      token,
    });
    /// get all tags of attraction and add them in state
    const attractionTags =
      (attraction.tags && attraction.tags.map((tag) => tag.name).join(',')) ||
      null;
    this.setState({
      attraction: {...attraction, description: attraction.description || ''},
      selectedDestination: {
        value: attraction.destinationId,
        label: attraction?.shortDescription ?? 'No Destination',
      },
      selectedTags: attractionTags,
      updateButtonText: 'Update',
      isTextPublish: !(attraction.status === 'PUBLISHED'),
    });
  }

  getImages = async () => {
    const { id } = this.props.match.params;

    BackendService.getPictures('attraction', id)
      .then(({ data }) => {
        if (data.message === 'OK') {
          this.setState({
            attraction: {
              ...this.state.attraction,
              pictures: data.pictures.sort((a, b) => a.order - b.order),
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        }
      });
  };

  submit = () => {
    const { albums } = this.state.attraction;
    if (albums?.length ?? 0) {
      alert(
        'You need to delete all pictures and albums before deleting the attraction',
      );
      return;
    }
    confirmAlert({
      title: 'Confirm to delete',
      message: `Do you want to permanently delete ${this.state.attraction.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            await BackendService.removeAttraction(this.state.attraction.id);
            this.props.history.push('/admin/attractions');
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  alertErrorMsg = (message) => {
    this.setState({ errorAlertText: message, showErrorAlert: true });
    setTimeout(() => {
      this.setState({ errorAlertText: '', showErrorAlert: false });
    }, 5000);
  };

  onFieldChange = (fieldName, value) => {
    const newState = { ...this.state.attraction };

    if(value && value.length > 0){
      newState[`${fieldName}`] = value.trim();
    }else{
      newState[`${fieldName}`] = value;
    }

    this.setState({ attraction: newState });
  };

  isDataValid = (obj) => {
    if (!obj.name || obj.name === '') {
      return 'Invalid name';
    }

    if (!this.state.selectedDestination) {
      return 'Invalid destination';
    }
    if (!obj.tags) {
      return 'Invalid tags';
    }
    if (!obj.assigneeId) {
      return 'Sorry, you have to log in again';
    }
    return true;
  };

  onUpdateAttractionData = async () => {
    const { attraction } = this.state;

    //change tag format
    attraction.Tags = undefined;
    attraction.tags =
      (this.state.selectedTags && this.state.selectedTags.split(',')) || [];

    const user = JSON.parse(localStorage.getItem(USER_KEY));
    attraction['assigneeId'] = user.id;

    const isError = this.isDataValid(attraction);
    // if there are validation errors, show error alert with message
    if (isError !== true) {
      window.scrollTo(0, 0);
      this.setState({ showErrorAlert: true, errorAlertText: isError }, () => {
        setTimeout(() => {
          this.setState({ showErrorAlert: false, errorAlertText: '' });
        }, 3000);
      });
    } else {
      // call add/edit endpoint and handle the result types / show alerts based on result
      // change destinationId format needed for the backend
      let result;

      attraction.destination = undefined;
      attraction.destinationId = this.state.selectedDestination.value;

      this.setState({ showSaveButton: false });
      if (this.state.updateButtonText === 'Save') {
        result = await BackendService.addAttraction(attraction);
        this.props.history.push(`/admin/attraction/${result.attraction.id}`);
      } else if (this.state.updateButtonText === 'Update') {
        /// when updating, don't change original assignee of the attraction
        attraction['assigneeId'] = undefined;

        result = await BackendService.editAttraction(attraction);
        /// get updated version of attraction
        this.getAttraction();
      }

      if (result.type === 'error') {
        /// show error alert for 3 seconds if there are server errors
        this.setState(
          { showErrorAlert: true, errorAlertText: result.message },
          () => {
            setTimeout(() => {
              this.setState({ showErrorAlert: false, errorAlertText: '' });
            }, 3000);
          },
        );
      } else {
        /// show success alert for 3 seconds if all is good
        this.setState(
          {
            showSuccessAlert: true,
            // showSaveButton: false
          },
          () => {
            setTimeout(() => {
              this.setState({
                showSuccessAlert: false,
                showSaveButton: true,
                updateButtonText: 'Update',
              });
            }, 3000);
          },
        );
      }
    }
  };

  onPublishPress = async () => {
    const token = localStorage.getItem(JWT_KEY);
    const { id } = this.props.match.params;
    const { attraction } = this.state;

    try {
      await client(`v1/attractions/${id}/publish`, {
        token,
        method: attraction.status === 'PUBLISHED' ? 'DELETE' : 'POST', //publish or unpublish
      });
      this.getAttraction(id);
    } catch (err) {
      this.alertErrorMsg(err.message);
    }
  };


  onImageUpload = (e) => {
    const { id } = this.props.match.params;

    const errs = [];
    const files = Array.from(e.target.files);
    console.log(files);

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time';
      return this.alertErrorMsg(msg);
    }


    const types = ['image/png', 'image/jpeg', 'image/gif'];

    const supportedFiles = files.filter((file) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
        return false
      }
      return true
    });

    if (errs.length) {
      return this.alertErrorMsg(errs.join(','));
    }

    this.setState({ uploadingImages: true });
    const jwt = localStorage.getItem(JWT_KEY);

    supportedFiles.forEach(async (file, i) => {
      const formData = new FormData();
      formData.append('file', file);


      axios.post(`${EndPoints.coreURL}pictures/attraction/${id}`, formData, {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            this.setState({ uploadingImages: false });
            this.getImages();
          }
        })
        .catch((err) => {
          this.setState({ uploadingImages: false });
          if (err.response) {
            this.alertErrorMsg(err.response.data.message, true);
          } else {
            this.alertErrorMsg(
              'Something went wrong. Operation was not completed. Please try again',
              true,
            );
          }
        });
    })

  };

  handleDestinationChange = (selectedDestination) => {
    this.setState({ selectedDestination });
  };

  handleTagChange = (selectedTags) => {
    this.setState({ selectedTags });
  };

  render() {
    const successAlertText = `Attraction ${(this.state.updateButtonText === 'Save' && 'saved') || 'updated'
      }`;
    const errorAlertText = `${this.state.errorAlertText}`;
    const albums = this.state.attraction?.albums ?? [];

    return (
      <>
        <div style={{ paddingTop: 200 }} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <span
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            onClick={() => this.props.history.goBack()}
            style={{ marginRight: 5, paddingBottom: 20 }}
          >
            <i className="fas fa-arrow-circle-left fa-lg"></i>
            <i
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              BACK
            </i>
          </span>
          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row>
                    <Col xs="8">
                      {/* <h3 className="mb-0"></h3> */}
                      <UncontrolledAlert
                        color={
                          (this.state.showErrorAlert && 'danger') || 'success'
                        }
                        style={{ width: 400, position: 'absolute' }}
                        isOpen={
                          this.state.showSuccessAlert ||
                          this.state.showErrorAlert
                        }
                      >
                        {(this.state.showSuccessAlert && successAlertText) ||
                          errorAlertText}
                      </UncontrolledAlert>
                    </Col>
                    <Col className="text-right" xs="4">
                      {this.state.isAdmin &&
                        this.state.updateButtonText !== 'Save' && (
                          <Button
                            color={
                              (this.state.isTextPublish && 'success') || 'info'
                            }
                            // href="#pablo"
                            onClick={this.onPublishPress}
                            size="m"
                          >
                            {(this.state.isTextPublish && 'Publish') ||
                              'Unpublish'}
                          </Button>
                        )}

                      {this.state.showSaveButton && (
                        <Button
                          color="primary"
                          // href="#pablo"
                          onClick={this.onUpdateAttractionData}
                          size="m"
                        >
                          {this.state.updateButtonText}
                        </Button>
                      )}
                      {this.state.isAdmin && (
                        <Button
                          style={{ color: 'red' }}
                          // color="#E5E5E5"
                          // href="#pablo"
                          onClick={this.submit}
                          size="m"
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Attraction information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-name"
                            >
                              NAME
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.attraction.name || ''}
                              id="input-attrname"
                              placeholder="Name"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('name', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-description"
                            >
                              DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-description"
                              placeholder="Attraction Description"
                              type="textarea"
                              value={this.state.attraction.description ?? ''}
                              onChange={(e) => {
                                console.log({ change: e.target.value });
                                this.onFieldChange(
                                  'description',
                                  e.target.value ?? '',
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              DESTINATION
                            </label>
                            <Select
                              name="destination"
                              options={this.state.destinationList}
                              value={this.state.selectedDestination}
                              onChange={(val) =>
                                this.handleDestinationChange(val)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              TAGS
                            </label>
                            <Select
                              name="tags"
                              // value="one"
                              clearable={true}
                              // autofocus
                              labelKey="label"
                              options={this.state.tagList}
                              value={this.state.selectedTags || ''}
                              valueKey="label"
                              searchable={true}
                              multi={true}
                              simpleValue
                              onChange={(val) => this.handleTagChange(val)}
                              selectComponent={Creatable}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              ADDRESS
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.attraction.address || ''}
                              id="input-address"
                              placeholder="Address"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('address', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-website"
                            >
                              WEBSITE
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.attraction.website || ''}
                              id="input-website"
                              placeholder="Website"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('website', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              PHONE NUMBER
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={this.state.attraction.phone || ''}
                              id="input-phone"
                              placeholder="Phone number"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('phone', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Shareable Branch URL
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly
                              defaultValue={
                                this.state.attraction.branchUrl || ''
                              }
                              id="input-branch-url"
                              placeholder="Auto-generated Branch URL"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                            >
                              Coords
                            </label>
                            <Input
                              key={this.state.attraction.id}
                              className="form-control-alternative"
                              onChange={(e) =>
                                this.onFieldChange('coordinates', e.target.value)
                              }
                              defaultValue={
                                this.state.attraction.geoPoint?.coordinates?.reverse()
                              }
                              placeholder="Coordinates"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className={'mb-5'}>
                        <Col>
                          <AttractionCategories {...{category: this.state.attraction?.category, attractionId: this.state.attraction?.id}} />
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="2">
                          <h4 className="alert-heading">Add new album</h4>

                          <FormGroup>
                            <label htmlFor="single">
                              <FontAwesomeIcon
                                icon={faImage}
                                color="#3B5998"
                                size="3x"
                              />
                            </label>
                            <input
                              type="file"
                              id="single"
                              onChange={this.onImageUpload}
                              className="upload-input-file"
                              name='file'
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Container>
                        {albums.map((album) => {
                          return album ? (
                            <Album
                              onMutate={this.getAttraction}
                              contentType="attraction"
                              contentId={this.state.attraction.id}
                              key={`Alb${album.albumId}`}
                              album={album}
                              getAllAttractions={this.getAllAttractions}
                              attractions={this.state.attractions}
                              attraction={this.state.attraction}
                              showFeatured={true}
                              alert={console.log}
                            />
                          ) : null;
                        })}
                      </Container>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Attraction);
