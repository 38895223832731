import React from "react";
import Header from "../../components/Headers/Header";
import {Card, Container, Row} from "reactstrap";
import AttractionCategories from "../../components/AttractionCategories";

export default function AttractionCategoriesPage() {
  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow pb-3">
              <h2 className="pl-3 pt-3">Categories</h2>
              <AttractionCategories editMode />
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  )
}
