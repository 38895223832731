import {useQuery} from 'react-query';
import {client} from './api-client';

import {JWT_KEY} from '../utils/consts';

const token = localStorage.getItem(JWT_KEY);

export function useUserLabels(search) {
  const queryFn = () =>
    client(`v1/user/labels?search=${search}`, {token}).then(
      (data) => data.userLabels,
    );

  return useQuery(['userLabels', search], queryFn);
}
