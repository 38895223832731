import * as React from 'react';
import BackendService from '../../services/BackendService';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Badge,
  Spinner,
} from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import { UncontrolledAlert } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages, faImage } from '@fortawesome/free-solid-svg-icons';
import './country.css';
import axios from 'axios';
import { JWT_KEY } from '../../utils/consts';
import { EndPoints } from '../../utils/EndPoints';
import { Album } from '../../components/Album';
import Select from "react-virtualized-select";

const continents = [
  {value: 'EU', label: 'Europa'},
  {value: 'AF', label: 'Africa'},
  {value: 'AS', label: 'Asia'},
  {value: 'NA', label: 'North America'},
  {value: 'SA', label: 'South America'},
  {value: 'OC', label: 'Oceania'},
  {value: 'AN', label: 'Antartica'}
]

function Country(props) {
  const { match } = props;
  const [country, setCountry] = React.useState({});
  const [isError, setIsError] = React.useState(false);
  const [showMessage, setShowMessage] = React.useState(false);
  const [message, setMessage] = React.useState();
  const [uploading, setUploading] = React.useState(false);
  const { albums } = country;
  const pictures = albums?.map((a) => a.pictures).flat() ?? [];

  const getCountry = () => {
    const { id } = match.params;
    if (isNaN(id)) {
      return
    }
    setUploading(true);
    BackendService.getCountry(id).then((result) => {
      if (result.message === 'OK') {
        setCountry(result.country);
      }
      setUploading(false);
    });
  };

  React.useEffect(() => {
    getCountry();
  }, []);

  /* Login for uploading pictures */
  const onImageUpload = (e) => {
    const { id } = match.params;

    const errs = [];
    const files = Array.from(e.target.files);
    console.log(files);

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time';
      return alertMsg(msg, true);
    }

    const formData = new FormData();
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    const supportedFiles = files.filter((file) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
        return false
      }
      return true
    });

    if (errs.length) {
      return alertMsg(errs.join(','), true);
    }

    setUploading(true);
    const jwt = localStorage.getItem(JWT_KEY);

    supportedFiles.forEach(async (file, i) => {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${EndPoints.coreURL}pictures/country/${id}`, formData, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setUploading(false);
          }
        })
        .catch((err) => {
          setUploading(false);
          if (err.response) {
            alertMsg(err.response.data.message, true);
          } else {
            alertMsg(
              'Something went wrong. Operation was not completed. Please try again',
              true,
            );
          }
        });
    });


  };

  const alertMsg = (message, isError = false) => {
    setMessage(message);
    setIsError(isError);
    setShowMessage(true);
    setTimeout(() => {
      setMessage('');
      setIsError(false);
      setShowMessage(false);
    }, 5000);
  };

  const onSavePress = () => {
    const { id } = match.params;

    BackendService.editCountry(id, country).then((result) => {
      if (result && result.country) {
        setCountry(result.country);
        alertMsg('Succesfully edited country');
      } else {
        alertMsg('Something went wrong. Please reload page', true);
      }
    });
  };

  const onPublishPress = () => {
    // if (pictures.length < 2) {
    //   alertMsg('Minimum 2 pictures required for publishing', true);
    // } else {
    const { id } = match.params;

    BackendService.publishCountry(id)
      .then((result) => {
        if (result && result.country) {
          setCountry(result.country);
          alertMsg('Succesfully edited country');
        } else {
          alertMsg('Something went wrong. Please reload page', true);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { error, message } = err.response.data;
          alertMsg(`${error} ${message}`);
        }
      });

  };

  const onUnpublishPress = () => {
    const { id } = match.params;

    BackendService.unpublishCountry(id)
      .then((result) => {
        if (result && result.country) {
          setCountry(result.country);
          alertMsg('Succesfully edited country');
        } else {
          alertMsg('Something went wrong. Please reload page', true);
        }
      })
      .catch((err) => {
        if (err.response) {
          const { error, message } = err.response.data;
          alertMsg(`${error} ${message}`);
        }
      });
  };

  const onDeletePress = () => {
    const { id } = match.params;
    confirmAlert({
      title: 'Confirm to delete',
      message: `Do you want to permanently delete ${country.name}?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            BackendService.deleteCountry(id).then((result) => {
              if (result.message === 'OK') {
                alertMsg('Succesfully deleted country');
                setTimeout(() => props.history.push('/admin/countries'), 2000);
              }
            });
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  if (uploading) {
    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '900px',
        }}
      >
        <Spinner color="primary" />
      </div>
    );
  }

  const countryColorState =
    country.status === 'DATA_ADDED'
      ? 'warning'
      : country.status === 'PHOTO_UPLOADED' || country.status === 'UNPUBLISHED'
        ? 'info'
        : country.status !== 'PUBLISHED'
          ? 'warning'
          : 'success';

  return (
    <>
      <div style={{ paddingTop: 200 }} />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Link
          className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
          to={{
            pathname: '/admin/countries',
          }}
          style={{ marginRight: 5, paddingBottom: 20 }}
        >
          <i class="fas fa-arrow-circle-left fa-lg"></i>
          <i
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              alignSelf: 'center',
              justifyContent: 'center',
            }}
          >
            BACK
          </i>
        </Link>

        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader>
                <Row>
                  <Col xs="8">
                    {/* <h3 className="mb-0"></h3> */}
                    <UncontrolledAlert
                      color={isError ? 'danger' : 'success'}
                      style={{ width: 400, position: 'absolute' }}
                      isOpen={showMessage}
                    >
                      {message}
                    </UncontrolledAlert>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      onClick={
                        country.status === 'PUBLISHED'
                          ? onUnpublishPress
                          : onPublishPress
                      }
                      color={
                        country.status === 'PUBLISHED' ? 'info' : 'success'
                      }
                      // href="#pablo"
                      // onClick={this.onPublishAttraction}
                      size="m"
                    >
                      {country.status === 'PUBLISHED' ? 'UNPUBLISH' : 'PUBLISH'}
                    </Button>

                    <Button color="primary" size="m" onClick={onSavePress}>
                      SAVE
                    </Button>
                    <Button
                      style={{ color: 'red' }}
                      size="m"
                      onClick={onDeletePress}
                    >
                      DELETE
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Country information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            NAME
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={country.name || ''}
                            id="input-attrname"
                            placeholder="Name"
                            type="text"
                            onChange={(e) =>
                              setCountry({ ...country, name: e.target.value })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-shortDescription"
                          >
                            Short Description
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={country.shortDescription || ''}
                            id="input-attrname"
                            placeholder="ShortDescription"
                            type="text"
                            onChange={(e) =>
                              setCountry({
                                ...country,
                                shortDescription: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-description"
                          >
                            DESCRIPTION
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={country.description || ''}
                            id="input-attrname"
                            placeholder="Description"
                            type="textarea"
                            onChange={(e) =>
                              setCountry({
                                ...country,
                                description: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <label
                            className="form-control-label"
                            htmlFor="status"
                          >
                            STATUS
                          </label>
                          <Badge
                            name="status"
                            color=""
                            className="badge-dot mr-4"
                            style={{ fontSize: 15, fontWeight: 'bold' }}
                          >
                            <i
                              className={`bg-${countryColorState}`}
                              style={{ width: 9, height: 9 }}
                            />
                            {country.status}
                          </Badge>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="branchUrl"
                          >
                            Shareable Branch URL
                          </label>
                          <Input
                            className="form-control-alternative"
                            readOnly
                            defaultValue={country.branchUrl || ''}
                            id="input-branch-url"
                            placeholder="Auto-generated Branch URL"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-name"
                          >
                            CONTINENT
                          </label>
                          <Select
                            className="mt-2"
                            id="filter-destination"
                            name="continent"
                            options={continents}
                            value={country.continent}
                            onChange={({value}) => setCountry({ ...country, continent: value })}
                            placeholder="Choose Continent"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="2">
                        <h4 className="alert-heading">Single image upload</h4>

                        <FormGroup>
                          <label htmlFor="single">
                            <FontAwesomeIcon
                              icon={faImage}
                              color="#3B5998"
                              size="3x"
                            />
                          </label>
                          <input
                            type="file"
                            id="single"
                            onChange={onImageUpload}
                            className="upload-input-file"
                          />
                        </FormGroup>
                      </Col>

                      <Col xs="2">
                        <h4 className="alert-heading">
                          Multiple images upload
                        </h4>

                        <FormGroup>
                          <label htmlFor="multi">
                            <FontAwesomeIcon
                              icon={faImages}
                              color="#3B5998"
                              size="3x"
                            />
                          </label>
                          <input
                            className="upload-input-file"
                            type="file"
                            id="multi"
                            onChange={onImageUpload}
                            multiple
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {(albums ?? []).map((album) => {
                        if (!album) {
                          return null;
                        }
                        return (
                          <Album
                            key={`album${album.id}`}
                            album={album}
                            onMutate={getCountry}
                            alert={alertMsg}
                            contentType="country"
                            contentId={country.id}
                          />
                        );
                      })}
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(Country);
