import { JWT_KEY, USER_KEY } from "../utils/consts";

export function isLoggedIn() {
  const jwt = localStorage.getItem(JWT_KEY);
  //// attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
  if (jwt && jwt.length > 20) {
    return true;
  }
  return false;
}

export function checkAuthState(response) {
  if (
    !response ||
    response.status === 401 ||
    response.status === 404 ||
    response.status === 500 ||
    response.status === 403
  ) {
    localStorage.removeItem(JWT_KEY);
    localStorage.removeItem(USER_KEY);
  }
}
