import React, {useEffect, useState} from 'react';

import Select from 'react-select';
import {FormGroup, Label} from 'reactstrap';
import BackendService from "../../services/BackendService";

const AssignedUsers = ({parentId, id}) => {
  const [assignedUsers, setAssignedUsers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState("")
  useEffect(() => {
    const onMount = async () => {
      const {users} = await BackendService.getUsers(search)
      setUsers(users.map(({firstName, lastName, id}) => ({value: id, label: `${firstName} ${lastName}`})))
    }
    onMount()
  }, [search])
  useEffect(() => {
    const onMount = async () => {
      const assignedUsers = await BackendService.getAttractionCategoryAssignedUsers(id)
      setAssignedUsers(assignedUsers.map(({firstName, lastName, userId}) => ({value: userId, label: `${firstName} ${lastName}`})))
      setIsLoading(false)

    }
    if (id) {
      onMount()
    }
  }, [id, refetch])

  const updateAssignedUser = async (users) => {
    setIsLoading(true)
    if (users.length > assignedUsers.length) {
      await BackendService.addAttractionCategoryAssignedUsers([users[users.length-1]?.value], id)
    }
    if (users.length < assignedUsers.length) {
      const [userToDelete] = assignedUsers.filter(({value: userId}) => !users.map(({value}) => value).includes(userId))
      await BackendService.deleteAttractionCategoryAssignedUsers([userToDelete?.value], id)
    }
    setRefetch(!refetch)
  }
  return (
    <div>
      {!parentId && id ?
      <FormGroup className="mt-5">
        <Label>Assigned Users</Label>
        <Select
          id="filter-contentType"
          name="contentType"
          value={assignedUsers}
          disabled={isLoading}
          onInputChange={value => setSearch(value)}
          onChange={(value) => {
            updateAssignedUser(value)
          }}
          options={users}
          placeholder="Assign users on category"
          multi
        />
      </FormGroup>
      : <></>}
    </div>
  );
};

export default AssignedUsers;
