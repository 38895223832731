import Index from 'views/Index.js';
import Profile from 'views/examples/Profile.js';
import Register from 'views/examples/Register.js';
import Login from 'views/examples/Login.js';
import Attractions from 'views/examples/Attractions.js';
import Attraction from 'views/examples/Attraction.js';
import Destinations from 'views/examples/Destinations.js';
import Destination from 'views/examples/Destination.js';
import Icons from 'views/examples/Icons.js';
import Sections from 'views/examples/Sections';
import Section from 'views/examples/Section';
import Collections from 'views/examples/Collections';
import Collection from 'views/examples/Collection';
import Countries from 'views/examples/Countries';
import Country from 'views/examples/Country';
import Translations from 'views/Translations';
import PendingAlbums from 'views/PendingAlbums';
import Album from 'views/PendingAlbum';
import Users from 'views/Users';
import AttractionCategories from "./views/AttractionCategories";
import UsersLocations from "./views/UsersLocations";

var routes = [
  {
    path: '/index',
    name: 'Dashboard',
    icon: 'ni ni-tv-2 text-primary',
    component: Index,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/attractions',
    name: 'Attractions',
    icon: 'ni ni-square-pin text-red',
    component: Attractions,
    layout: '/admin',
    visible: true,
  },

  {
    path: '/attraction/:id',
    name: 'Attraction',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Attraction,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/sections',
    name: 'Sections',
    icon: 'fa fa-align-justify text-purple',
    component: Sections,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/sections/:id',
    name: 'Section',
    icon: 'fa fa-align-justify text-purple',
    component: Section,
    layout: '/admin',
    visible: false,
  },

  {
    path: '/collections',
    name: 'Collections',
    icon: 'fa fa-book text-orange',
    component: Collections,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/collections/:id',
    name: 'Collection',
    icon: 'fa fa-book text-orange',
    component: Collection,
    layout: '/admin',
  },

  {
    path: '/destinations',
    name: 'Destinations',
    icon: 'ni ni-world text-blue',
    component: Destinations,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/destination/:id',
    name: 'Destination',
    icon: 'ni ni-bullet-list-67 text-red',
    component: Destination,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/icons',
    name: 'Icons',
    icon: 'ni ni-planet text-blue',
    component: Icons,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    icon: 'ni ni-single-02 text-yellow',
    component: Profile,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/login',
    name: 'Login',
    icon: 'ni ni-key-25 text-info',
    component: Login,
    layout: '/auth',
    visible: false,
  },
  {
    path: '/register',
    name: 'Register',
    icon: 'ni ni-circle-08 text-pink',
    component: Register,
    layout: '/auth',
    visible: false,
  },
  {
    path: '/countries',
    name: 'Countries',
    icon: 'fa fa-flag text-yellow',
    component: Countries,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/countries/:id',
    name: 'Countries',
    icon: 'fa fa-flag text-yellow',
    component: Country,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/translations',
    name: 'Translations',
    icon: 'fa fa-language text-pink',
    component: Translations,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/pending-albums',
    name: 'Pending Albums',
    icon: 'fa fa-calendar-check text-green',
    component: PendingAlbums,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/pending-albums/:id',
    name: 'Pending Album',
    icon: 'fa fa-calendar-check text-green',
    component: Album,
    layout: '/admin',
    visible: false,
  },
  {
    path: '/users',
    name: 'Users',
    icon: 'fa fa-user text-info',
    component: Users,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/users-locations',
    name: 'Locations assigned users',
    icon: 'fa fa-map text-yellow',
    component: UsersLocations,
    layout: '/admin',
    visible: true,
  },
  {
    path: '/categories',
    name: 'Categories',
    icon: 'ni ni-bullet-list-67 text-green',
    component: AttractionCategories,
    layout: '/admin',
    visible: true,
  },
];
export default routes;
