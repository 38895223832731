import React, {useState} from 'react';
import {ToastContainer, toast} from 'react-toastify';
import { useHistory } from "react-router-dom";
import {Formik, Form} from 'formik';
import {
  Button,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Col,
} from 'reactstrap';
import BackendService from 'services/BackendService';

export const camelCaseToText = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const ModalComponent = ({
  albumId,
  modal,
  toggle,
  userId,
  albumUserId,
  attractionId,
  imageUrl,
  profileImage,
  onMount,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const deeplinkUrl =
    modal === 'PUBLISHED'
      ? `framey.app://album?id=${albumId}&attractionId=${attractionId}`
      : `framey.app://users?id=${userId}`;
  return (
    <>
      <ToastContainer />
      <Modal isOpen={modal} toggle={toggle} size="md">
        <Formik
          onSubmit={async (values) => {
            try {
              setLoading(true);
              if (modal === 'DELETE') {
                await BackendService.deleteAlbum(albumId);
              } else {
                await BackendService.updateAlbum(albumId, values);
                await BackendService.notifyUser(albumUserId, {
                  action:
                    modal === 'PUBLISHED' ? 'post-approved' : 'post-rejected',
                  actor: {
                    type: 'admin_user',
                    id: `${userId}`,
                  },
                  deeplinkUrl,
                  imageUrl: modal === 'PUBLISHED' ? imageUrl : profileImage,
                  object: {
                    type: 'album',
                    id: `${albumId}`,
                  },
                  reason: values.reason,
                });
              }
              setLoading(false);
              toast.success('Successfully updated album');
              setTimeout(() => {
                if (modal === "DELETE") {
                  history.push("/admin/pending-albums")
                } else {
                  onMount();
                }
              }, 500);
            } catch (error) {
              setLoading(false);
              setTimeout(onMount, 500);
              toast.error(error.message);
            }
          }}
          initialValues={{status: modal}}
        >
          {({handleChange, values}) => (
            <Form>
              <ModalHeader toggle={toggle}>
                {modal === 'DELETE' ? 'Delete album' : (modal === 'PUBLISHED' ? 'Approve album' : 'Reject album')}
              </ModalHeader>
              {modal === 'DELETE' ?
                <Col className="ml-1">
                  <p>Are you sure that you want to delete album?</p>
                </Col> :
                <ModalBody>
                  <FormGroup>
                    <label className="form-control-label" htmlFor={`reason`}>
                      Reason
                    </label>
                    <Input
                      className="reason-field"
                      type="textarea"
                      name="reason"
                      onChange={handleChange}
                    />
                  </FormGroup>
                </ModalBody>
              }
              <ModalFooter>
                <Button
                  isLoading={loading}
                  disabled={loading || (modal === 'REJECTED' && !values.reason)}
                  type="submit"
                  color={modal === 'DELETE' ? "danger" : "primary"}
                >
                  {loading ? 'Loading...' : ( modal === 'DELETE' ? 'Delete' : 'Save')}
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ModalComponent;
