import React from 'react';

// reactstrap components
import {Container} from 'reactstrap';

class Header extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-gradient-purple pb-8 pt-5 pt-md-7">
          <Container fluid>
            <div className="header-body"></div>
          </Container>
        </div>
      </>
    );
  }
}

export default Header;
