import React, {useCallback, useEffect, useState} from 'react';
import Pagination from 'react-pagination-js';
import Header from 'components/Headers/Header.js';
import {Card, Container, Row, Table, Spinner} from 'reactstrap';
import CardFooter from 'reactstrap/lib/CardFooter';

import Filters from './Filters';
import BackendService from 'services/BackendService';
import {EndPoints} from 'utils/EndPoints';
import {Link} from 'react-router-dom';
import StatusFormatter from 'components/StatusFormatter';

const ELEMENTS_PER_PAGE = 20;

const getHumanReadableTime = (time) => {
  var localDate = new Date(time);
  return localDate.toLocaleString();
};

const PendingAlbums = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [withoutCoordinates, setWithoutCoordinates] = useState(null);
  const [status, setStatus] = useState([
    {value: 'UNPUBLISHED', label: 'UNPUBLISHED'},
  ]);
  const [albums, setAlbums] = useState([]);
  const [count, setCount] = useState(0);

  useEffect(() => {
    async function onMount() {
      setIsLoading(true);
      const {albums, count} = await BackendService.getAlbums(
        null,
        status,
        (currentPage - 1) * ELEMENTS_PER_PAGE,
        20,
        withoutCoordinates,
      );
      setIsLoading(false);
      setAlbums(albums);
      setCount(count);
    }
    onMount();
  }, [status, currentPage, withoutCoordinates]);

  const picturesWithoutCoords = useCallback((pictures) => {
    const coords = pictures.map(({geoPoint}) => !!geoPoint);
    const zeroCoord = pictures.find(({geoPoint: {coordinates} = {}}) => coordinates && (coordinates[0] === 0 || coordinates[1] === 0))
    if (coords.includes(false) || !!zeroCoord) {
      return true;
    }
    return false;
  }, []);

  return (
    <div className="PendingAlbums">
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filters
                {...{
                  status,
                  setStatus,
                  withoutCoordinates,
                  setWithoutCoordinates,
                }}
              />
              {isLoading ? (
                <div className="Loading__wrapper">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{marginLeft: 10}}>
                      <th scope="col">Attraction Name</th>
                      <th scope="col">Status</th>
                      <th scope="col">User name</th>
                      <th scope="col">Pictures</th>
                      <th scope="col">Coordinates</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Updated At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(albums || []).length ? (
                      albums.map(
                        ({
                          id: albumId,
                          status,
                          createdAt,
                          details = '',
                          updatedAt,
                          attraction,
                          user,
                          pictures,
                        }) => {
                          const {name} = attraction || {};
                          const {firstName, lastName, id} = user || {};
                          const [albumName] = details.split('\n');
                          return (
                            <tr>
                              <td>
                                <Link to={`/admin/pending-albums/${albumId}`}>
                                  {name || albumName || 'New Post'}
                                </Link>
                              </td>
                              <td>
                                <StatusFormatter status={status} />
                              </td>
                              <td>{`(${id}) ${firstName} ${lastName}`}</td>
                              <td>
                                <div className="avatar-group">
                                  {pictures.map((picture) => {
                                    return (
                                      <a
                                        className="avatar avatar-sm"
                                        id="tooltip742438047"
                                        onClick={(e) => e.preventDefault()}
                                      >
                                        <img
                                          alt="..."
                                          style={{
                                            borderRadius: '50%',
                                            width: 30,
                                            height: 30,
                                          }}
                                          src={
                                            picture.bucketUrl
                                              ? EndPoints.mediaURL +
                                                picture.bucketUrl
                                              : require('assets/img/framey/uploadLogo.png')
                                          }
                                        />
                                      </a>
                                    );
                                  })}
                                </div>
                              </td>
                              <td>
                                {picturesWithoutCoords(pictures) ? 'NO' : 'YES'}
                              </td>
                              <td>{getHumanReadableTime(createdAt)}</td>
                              <td>{getHumanReadableTime(updatedAt)}</td>
                            </tr>
                          );
                        },
                      )
                    ) : (
                      <div className="no-results">No results found</div>
                    )}
                  </tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                <Pagination
                  currentPage={currentPage}
                  totalSize={count}
                  sizePerPage={ELEMENTS_PER_PAGE}
                  changeCurrentPage={(page) => setCurrentPage(page)}
                  theme="bootstrap"
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default PendingAlbums;
