import React, {useEffect, useState} from 'react';
import BackendService from 'services/BackendService';

import Image from 'components/Headers/Image';

const SelectAlbumGallery = ({attraction, updateSelectedAlbum, toggle}) => {
  const [albums, setAlbums] = useState({});
  const newAlbumKey = Object.keys(albums).includes(attraction.attractionId);
  useEffect(() => {
    async function onMount() {
      const {attraction: {albums: attrationAlbums} = {}} =
        await BackendService.getAttractionById(attraction.attractionId);
      console.log(attrationAlbums);
      setAlbums({...albums, [attraction.attractionId]: attrationAlbums});
    }
    if (!attraction.albums) {
      onMount();
    }
  }, [newAlbumKey]);

  const mergedAlbums =
    attraction.albums || albums[attraction.attractionId] || [];

  const firstColImages = mergedAlbums.filter((el, index) => index % 2 === 0);
  const secondColImages = mergedAlbums.filter((el, index) => index % 2 !== 0);

  return (
    <div className="imageCollection flex flex-grow-1 flex-wrap">
      <div className={`max-width-half-width flex-grow-1`}>
        {firstColImages.map(({id, pictures: [pictures]}) => (
          <Image
            style={{
              width: '100%',
              borderRadius: 0,
              cursor: 'pointer',
              border:
                attraction.selectedAlbum === id
                  ? '5px solid #2dce89'
                  : '5px solid white',
            }}
            onClick={() => {
              updateSelectedAlbum(id, attraction);
              toggle();
            }}
            pictureURL={pictures.bucketUrl}
          />
        ))}
      </div>
      <div className={`max-width-half-width flex-grow-1`}>
        {secondColImages.map(({id, pictures: [pictures]}) => (
          <Image
            style={{
              width: '100%',
              borderRadius: 0,
              cursor: 'pointer',
              border:
                attraction.selectedAlbum === id
                  ? '5px solid #2dce89'
                  : '5px solid white',
            }}
            onClick={() => {
              updateSelectedAlbum(id, attraction);
              toggle();
            }}
            pictureURL={pictures.bucketUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default SelectAlbumGallery;
