import React from 'react';

import BackendService from '../../services/BackendService';
import {JWT_KEY, USER_KEY} from '../../utils/consts';

import {withRouter, Link} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import {Creatable} from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faImage, faImages} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import {EndPoints} from 'utils/EndPoints';
import {Album} from '../../components/Album';
import Image from '../../components/Headers/Image';
import debounce from 'lodash.debounce';
import SelectAlbumGallery from 'components/SelectAlbumGallery';

const CONTENT_TYPES = {
  ATTRACTION: 'Attraction',
  DESTINATION: 'Destination',
  ITINERARY: 'Itinerary',
};

class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      modal: true,
      albumModal: false,
      collection: {},
      allContent: [],
      destinations: [],
      updateButtonText: 'Save',
      showSuccessAlert: false,
      showErrorAlert: false,
      errorAlertText: '',
      isTextPublish: true,
      isAdmin: false,
      historyObj: {},
      showSaveButton: true,
      selectedTags: null,
      tagList: [],
      shouldUpdateTags: false,
      countries: [],
    };

    this.onUpdatePics = this.onUpdatePics.bind(this);
  }

  contentOptions = [
    {value: 'Attraction', label: 'Attraction'},
    {value: 'Destination', label: 'Destination'},
    {value: 'Itinerary', label: 'Itinerary'},
  ];

  orderOptions = [...Array(100).keys()].map((index) => ({
    label: index,
    value: index,
  }));

  async componentDidMount() {
    const collectionId = this.props.match.params.id;
    let collectionTags;
    let isTextPublish;

    const isAdmin = this.isUserAdmin();

    if (collectionId === 'new') {
      this.setState({fetching: false});
    } else {
      const [{collection}, tagList, {countries}] = await Promise.all([
        BackendService.getCollection(collectionId),
        BackendService.getAllTags(),
        BackendService.getCountries(),
      ]);

      isTextPublish = !collection.status === 'PUBLISHED';
      // this.setState({
      //   destinationList: destinations.result,
      //   tagList,
      //   isAdmin,
      //   historyObj,
      // });

      collectionTags =
        (collection.tags &&
          collection.tags.map((tag) => tag.label).join(',')) ||
        null;

      let collectionContentById;
      if (collection.contents !== undefined) {
        collectionContentById = collection.contents.map(
          (content) => content.id,
        );
      }
      if (collection.contentType === CONTENT_TYPES.DESTINATION) {
        /* Fetch all destinations and filter the ones already in this collection */
        const finalContent = await this.getAllDestinations(
          collectionContentById,
        );
        this.setState({
          collection,
          selectedTags: collectionTags,
          tagList,
          allContent: finalContent,
          fetching: false,
          isAdmin,
          isTextPublish,
          countries: countries.map((country) => ({
            value: country.id,
            label: country.name,
          })),
        });
      } else {
        const [attractions, allDestinations] = await Promise.all([
          this.getAllAttractions(collectionContentById, collection),
          this.getAllDestinations(),
        ]);

        this.setState({
          collection,
          allContent: attractions,
          destinations: allDestinations,
          selectedTags: collectionTags,
          tagList,
          fetching: false,
          isAdmin,
          isTextPublish,
          countries: countries.map((country) => ({
            value: country.id,
            label: country.name,
          })),
        });
      }
    }
  }

  alertErrorMsg = (message) => {
    this.setState({errorAlertText: message, showErrorAlert: true});
    setTimeout(() => {
      this.setState({errorAlertText: '', showErrorAlert: false});
    }, 5000);
  };

  isUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    // attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  };

  handleTagChange = (selectedTags) => {
    this.setState({selectedTags, shouldUpdateTags: true});
  };

  getAllDestinations = async (collectionContentById = []) => {
    const res = await BackendService.getAllDestinations();
    const finalContent = res.result.filter(
      (destination) => !collectionContentById.includes(destination.id),
    ).map(({value, Country, label}) => ({value, label:`${label}, ${Country?.name}`, Country}));
    return finalContent;
  };

  getAllAttractions = async (
    collectionContentById,
    collection = {},
    search,
  ) => {
    const {attractionLabels} =
      await BackendService.fetchAttractionsForDashboard(
        search,
        collection.destinationId,
      );

    let finalContent = attractionLabels;

    if (collectionContentById !== undefined) {
      finalContent = attractionLabels.filter(
        (attraction) => !collectionContentById.includes(attraction.id),
      );
    }

    return finalContent;
  };

  onUpdatePics = (newPictures) => {
    const newState = {...this.state.collection};
    newState.pictures = newPictures;
    this.setState({collection: newState});
  };

  renderAttractionContent = () => {
    const {collection, modal} = this.state;

    const toggle = () => this.setState({modal: false});
    const attraction = collection.contents
      ? collection.contents.sort((a, b) => a.order - b.order)
      : [];
    return (
      <>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr style={{marginLeft: 10}}>
              <th scope="col">Attraction Name</th>
              <th scope="col">Destination Name</th>
              {this.state.isAdmin && <th scope="col">Assignee</th>}
              <th scope="col">Order</th>
              <th scope="col">Status</th>
              <th scope="col">Album</th>
              <th scope="col">Location</th>
              <th scope="col">Completion</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {attraction.map((attraction) => {
              const albumsIds = attraction.albums.map((index) => ({
                label: index.id,
                value: index.id,
              }));
              const [destination, country] = attraction.shortDescription.split(',') ?? [
                'No destination',
              ];
              const onDeleteContent = async () => {
                const {id: collectionId, contentType} = this.state.collection;
                this.setState({fetching: true});
                await BackendService.deleteContentFromCollection({
                  collectionId,
                  contentType,
                  id: attraction.id,
                });
                const {collection} = await BackendService.getCollection(
                  collectionId,
                );
                this.setState({collection, fetching: false});
              };

              const updateOrder = async (value) => {
                const {id: collectionId, contentType} = this.state.collection;
                this.setState({fetching: true});
                await BackendService.updateContentFromCollection({
                  collectionId,
                  contentType,
                  id: attraction.id,
                  order: value,
                });
                const {collection} = await BackendService.getCollection(
                  collectionId,
                );
                this.setState({collection, fetching: false});
              };

              const updateSelectedAlbum = async (value, attractionT) => {
                // const { id: collectionId, contentType } = this.state.collection;
                // this.setState({ fetching: true });
                // await BackendService.updateContentFromCollection({
                //   collectionId,
                //   contentType,
                //   id: attraction.id,
                //   order: value,
                // });
                // const { collection } = await BackendService.getCollection(
                //   collectionId,
                // );
                // this.setState({ collection, fetching: false });
                const {id: collectionId} = this.state.collection;
                await BackendService.updateCollectionContent({
                  id: attraction.collectionContent,
                  albumId: value,
                });

                const {collection} = await BackendService.getCollection(
                  collectionId,
                );
                this.setState({collection, fetching: false});
              };

              const attractionColorState =
                attraction.status === 'DATA ADDED'
                  ? 'warning'
                  : attraction.status === 'PHOTO UPLOADED'
                  ? 'info'
                  : 'success';

              const progressBarValue =
                attraction.status === 'DATA ADDED'
                  ? 60
                  : attraction.status === 'PHOTO UPLOADED'
                  ? 80
                  : 100;
              const firstColImages = attraction.albums.filter(
                (el, index) => index % 2 === 0,
              );
              const secondColImages = attraction.albums.filter(
                (el, index) => index % 2 !== 0,
              );
              return (
                <>
                  <Modal
                    isOpen={modal === attraction.id}
                    toggle={toggle}
                    size="lg"
                  >
                    <ModalHeader toggle={toggle}>Albums</ModalHeader>
                    <ModalBody>
                      <div className="imageCollection flex flex-grow-1 flex-wrap">
                        <div className={`max-width-half-width flex-grow-1`}>
                          {firstColImages.map(({id, pictures: [pictures]}) => (
                            <Image
                              style={{
                                width: '100%',
                                borderRadius: 0,
                                cursor: 'pointer',
                                border:
                                  attraction.selectedAlbum === id
                                    ? '5px solid #2dce89'
                                    : '5px solid white',
                              }}
                              onClick={() => {
                                updateSelectedAlbum(id, attraction);
                                toggle();
                              }}
                              pictureURL={pictures.bucketUrl}
                            />
                          ))}
                        </div>
                        <div className={`max-width-half-width flex-grow-1`}>
                          {secondColImages.map(({id, pictures: [pictures]}) => (
                            <Image
                              style={{
                                width: '100%',
                                borderRadius: 0,
                                cursor: 'pointer',
                                border:
                                  attraction.selectedAlbum === id
                                    ? '5px solid #2dce89'
                                    : '5px solid white',
                              }}
                              onClick={() => {
                                updateSelectedAlbum(id, attraction);
                                toggle();
                              }}
                              pictureURL={pictures.bucketUrl}
                            />
                          ))}
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <tr>
                    <th scope="row">
                      <Link
                        to={{
                          pathname: `/admin/attraction/${attraction.id}`,
                          historyObj: this.state,
                        }}
                      >
                        <Media className="align-items-center">
                          <Media style={{marginLeft: 10}}>
                            <span
                              className="mb-0 text-xm"
                              style={{fontSize: 16}}
                            >
                              {attraction.name}
                            </span>
                          </Media>
                        </Media>
                      </Link>
                    </th>
                    <th scope="row">
                      <Link
                        to={{
                          pathname: `/admin/destination/${attraction.destinationId}`,
                          historyObj: this.state,
                        }}
                      >
                        <Media className="align-items-center">
                          <Media style={{marginLeft: 10}}>
                            {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                            <Badge
                              color=""
                              className="badge-dot mr-4"
                              style={{fontSize: 13, fontWeight: 'bold'}}
                            >
                              <i style={{width: 9, height: 9}} />
                              <span
                                className="mb-0 text-xm"
                                style={{fontSize: 16}}
                              >
                                {destination ? destination : 'No destination'}, {country || ''}
                              </span>
                            </Badge>
                          </Media>
                        </Media>
                      </Link>
                    </th>
                    {this.state.isAdmin && (
                      <td>
                        {attraction.User &&
                          attraction.User.firstName +
                            ' ' +
                            attraction.User.lastName}
                      </td>
                    )}
                    <td>
                      <Select
                        options={this.orderOptions}
                        value={attraction.order}
                        onChange={({value}) => updateOrder(value)}
                      >
                        {attraction.order}
                      </Select>
                    </td>
                    <td>
                      {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
              PUBLISHED = green dot */}
                      <Badge
                        color=""
                        className="badge-dot mr-4"
                        style={{fontSize: 13, fontWeight: 'bold'}}
                      >
                        <i
                          className={`bg-${attractionColorState}`}
                          style={{width: 9, height: 9}}
                        />
                        {attraction.status}
                      </Badge>
                    </td>
                    <td>
                      <Badge
                        color="info"
                        className="cursor-pointer"
                        onClick={() => this.setState({modal: attraction.id})}
                      >
                        {attraction.selectedAlbum}
                      </Badge>
                    </td>

                    <td>
                      <div className="d-flex align-items-center">
                        <span className="mr-2">{progressBarValue + '%'}</span>
                        <div>
                          <Progress
                            max="100"
                            value={progressBarValue}
                            barClassName={`bg-${attractionColorState}`}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          // href="#pablo"
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            // href="#pablo"
                            onClick={onDeleteContent}
                          >
                            Delete From Collection
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                </>
                // </Link>
              );
            })}
          </tbody>
        </Table>
      </>
    );
  };

  getCollection = async (id) => {
    this.setState({fetching: true});
    const collectionId = this.props.match.params.id;
    const {collection} = await BackendService.getCollection(id || collectionId);
    /// get all tags of attraction and add them in state
    if (collection) {
      this.setState({
        collection,
        fetching: false,
      });
    } else {
      alert('Something went wrong. Please reload page.');
    }
  };

  getHumanReadableTime = (time) => {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  };

  onFieldChange = (fieldName, value) => {
    const newState = {...this.state.collection};

    if (value && value.length > 0) {
      newState[fieldName] = value.trim();
    } else {
      newState[fieldName] = value;
    }

    this.setState({collection: newState});
  };

  clickGoBack = () => {
    this.props.history.push('/collections');
  };

  renderDestinationContent = () => {
    const {collection} = this.state;
    const destinations =
      collection && collection.contents
        ? collection.contents.sort((a, b) => a.order - b.order)
        : [];
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr style={{marginLeft: 10}}>
            <th scope="col">Destination Name</th>
            <th scope="col">Order</th>
            <th scope="col">Status</th>
            <th scope="col">Location</th>
            <th scope="col">Completion</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {destinations.map((destination) => {
            const onDeleteContent = async () => {
              const {id: collectionId, contentType} = this.state.collection;
              this.setState({fetching: true});
              await BackendService.deleteContentFromCollection({
                collectionId,
                contentType,
                id: destination.id,
              });
              const {collection} = await BackendService.getCollection(
                collectionId,
              );
              this.setState({collection, fetching: false});
            };

            const updateOrder = async (value) => {
              const {id: collectionId, contentType} = this.state.collection;
              this.setState({fetching: true});
              await BackendService.updateContentFromCollection({
                collectionId,
                contentType,
                id: destination.id,
                order: value,
              });
              const {collection} = await BackendService.getCollection(
                collectionId,
              );
              this.setState({collection, fetching: false});
            };

            const destinationColorState =
              destination.status === 'DATA ADDED'
                ? 'warning'
                : destination.status === 'PHOTO UPLOADED'
                ? 'info'
                : 'success';

            const progressBarValue =
              destination.status === 'DATA ADDED'
                ? 60
                : destination.status === 'PHOTO UPLOADED'
                ? 80
                : 100;

            return (
              <tr>
                <th scope="row">
                  <Link
                    to={{
                      pathname: `/admin/destination/${destination.id}`,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        <span className="mb-0 text-xm" style={{fontSize: 16}}>
                          {destination.name}, {destination?.Country?.name}
                        </span>
                      </Media>
                    </Media>
                  </Link>
                </th>

                <td>
                  <Select
                    options={this.orderOptions}
                    value={destination.order}
                    onChange={({value}) => updateOrder(value)}
                  >
                    {collection.order}
                  </Select>
                </td>
                <td>
                  {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
              PUBLISHED = green dot */}
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      className={`bg-${destinationColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {destination.status}
                  </Badge>
                </td>
                <td>
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      // className={`bg-${attractionColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {(destination.geoPoint && 'Yes') || 'No'}
                  </Badge>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{progressBarValue + '%'}</span>
                    <div>
                      <Progress
                        max="100"
                        value={progressBarValue}
                        barClassName={`bg-${destinationColorState}`}
                      />
                    </div>
                  </div>
                </td>

                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      // href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDeleteContent}
                      >
                        Delete content
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  renderContents = () => {
    switch (this.state.collection.contentType) {
      case 'Attraction':
        return this.renderAttractionContent();
      case 'Itinerary':
        return this.renderAttractionContent();
      case 'Destination':
        return this.renderDestinationContent();
      default:
        return null;
    }
  };

  deleteCollection = () => {
    const collectionId = this.props.match.params.id;

    confirmAlert({
      title: 'Confirm to delete',
      message: `Do you want to permanently delete this collection?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            this.setState({fetching: true});
            const res = await BackendService.deleteCollection(collectionId);
            if (res.status <= 400) {
              this.props.history.push('/admin/collections');
            } else {
              alert('Something went wrong with your request');
              this.setState({fetching: false});
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  addContentToCollection = async (id, albumId, contentType) => {
    const {id: collectionId} = this.state.collection;
    this.setState({fetching: true});

    await BackendService.addContentToCollection({
      collectionId,
      contentType,
      id: id,
      albumId,
    });
    const {collection} = await BackendService.getCollection(collectionId);
    this.setState({collection, fetching: false});
  };

  submitChanges = async () => {
    const {
      contentType,
      name,
      description,
      shortDescription,
      destinationId,
      pictures,
      countryId,
    } = this.state.collection;
    const collectionId = this.props.match.params.id;
    const tags = (this.state.selectedTags || '').split(',');
    const {shouldUpdateTags} = this.state;
    const collectionData = {
      ...this.state.collection,
      // tags,
    };

    if (collectionId === 'new') {
      collectionData.type = 'MANAGED';
      collectionData.status = 'UNPUBLISHED';
      const {collection} = await BackendService.addCollection(collectionData);
      this.props.history.push(`/admin/collections/${collection.id}`);
    } else {
      this.setState({fetching: true});

      const toSend = {
        contentType,
        name,
        description,
        shortDescription,
        tags,
        destinationId,
        countryId,
        shouldUpdateTags,
        pictures,
      };
      const {collection} = await BackendService.updateCollection(
        collectionId,
        toSend,
      );

      this.setState({collection, fetching: false});
    }
  };

  getImages = async () => {
    const {id} = this.props.match.params;

    BackendService.getPictures('collection', id)
      .then(({data}) => {
        if (data.message === 'OK') {
          this.setState({
            collection: {
              ...this.state.collection,
              pictures: data.pictures.sort((a, b) => a.order - b.order),
            },
          });
        }
      })
      .catch((err) => {
        if (err.response) {
          console.log(err);
        }
      });
  };

  onPublishCollection = async () => {
    const {albums} = this.state.collection;
    if (
      (!albums || albums.length < 1) &&
      this.state.collection.status !== 'PUBLISHED'
    ) {
      alert('YOU NEED ONE PICTURE TO PUBLISH');
      return;
    }
    this.setState({fetching: true});

    const {id} = this.state.collection;
    const status =
      this.state.collection.status === 'PUBLISHED'
        ? 'UNPUBLISHED'
        : 'PUBLISHED';
    const {collection} = await BackendService.updateCollectionPublishStatus(
      id,
      status,
    );
    this.setState({
      collection,
      fetching: false,
    });
  };

  onImageUpload = (e) => {
    const {id} = this.props.match.params;

    const errs = [];
    const files = Array.from(e.target.files);
    console.log(files);

    if (files.length > 3) {
      const msg = 'Only 3 images can be uploaded at a time';
      return this.alertErrorMsg(msg);
    }

    const formData = new FormData();
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    const supportedFiles = files.filter((file) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
        return false;
      }
      return true;
    });

    if (errs.length) {
      return this.alertErrorMsg(errs.join(','));
    }

    this.setState({uploadingImages: true});
    const jwt = localStorage.getItem(JWT_KEY);

    supportedFiles.forEach(async (file, i) => {
      const formData = new FormData();
      formData.append('file', file);

      axios
        .post(`${EndPoints.coreURL}pictures/collection/${id}`, formData, {
          headers: {
            Authorization: 'Bearer ' + jwt,
          },
        })
        .then((res) => {
          if (res.status === 201) {
            this.setState({uploadingImages: false});
            this.getImages();
          }
        })
        .catch((err) => {
          this.setState({uploadingImages: false});
          if (err.response) {
            this.alertErrorMsg(err.response.data.message, true);
          } else {
            this.alertErrorMsg(
              'Something went wrong. Operation was not completed. Please try again',
              true,
            );
          }
        });
    });
  };

  render() {
    if (this.state.fetching) {
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '900px',
          }}
        >
          <Spinner color="primary" />
        </div>
      );
    }

    const {collection, albumModal} = this.state;
    const albums = collection?.albums ?? [];
    const collectionColorState =
      collection.status === 'DATA ADDED'
        ? 'warning'
        : collection.status === 'PHOTO UPLOADED' ||
          collection.status === 'UNPUBLISHED'
        ? 'info'
        : collection.status !== 'PUBLISHED'
        ? 'warning'
        : 'success';

    const toggle = () => this.setState({albumModal: false});
    return (
      <>
        <div style={{paddingTop: 200}} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <span
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            onClick={() => this.props.history.goBack()}
            style={{marginRight: 5, paddingBottom: 20, cursor: "pointer"}}
          >
            <i className="fas fa-arrow-circle-left fa-lg"></i>
            <i
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              BACK
            </i>
          </span>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Col className="text-right" xs="2">
                      {this.state.isAdmin && (
                        <Button
                          color={
                            this.state.collection.status === 'PUBLISHED'
                              ? 'info'
                              : 'success'
                          }
                          // href="#pablo"
                          onClick={this.onPublishCollection}
                          size="m"
                        >
                          {this.state.collection.status === 'PUBLISHED'
                            ? 'Unpublish'
                            : 'Publish'}
                        </Button>
                      )}
                    </Col>
                    <Col className="text-right" xs="2">
                      {this.state.showSaveButton && (
                        <Button
                          color="danger"
                          // href="#pablo"
                          onClick={this.deleteCollection}
                          size="m"
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                    <Col xs="2">
                      {this.state.showSaveButton && (
                        <Button
                          color="primary"
                          // href="#pablo"
                          onClick={this.submitChanges}
                          size="m"
                        >
                          {this.state.updateButtonText}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Collection information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="name-input"
                            >
                              NAME
                            </label>
                            <Input
                              className="form-control"
                              defaultValue={this.state.collection.name || ''}
                              id="name-input"
                              placeholder="Collection Name"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('name', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description-input"
                            >
                              DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={
                                this.state.collection.description || ''
                              }
                              id="description-input"
                              placeholder="Description"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'description',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              htmlFor="selectContent"
                              sm={2}
                              className="form-control-label"
                            >
                              CONTENT TYPE
                            </label>

                            <Select
                              name="selectOrder"
                              value={this.state.collection.contentType}
                              options={this.contentOptions}
                              onChange={({value}) => {
                                const {collection} = this.state;
                                if (
                                  !collection.contents ||
                                  collection.contents.length === 0
                                ) {
                                  this.setState((prevState) => ({
                                    collection: {
                                      ...prevState.collection,
                                      contentType: value,
                                    },
                                    allContent: []
                                  }));
                                } else {
                                  alert('Delete all content firs');
                                }
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description-input"
                            >
                              SHORT DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={
                                this.state.collection.shortDescription || ''
                              }
                              id="short-description-input"
                              placeholder="Short description"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'shortDescription',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>

                        {["Attraction", "Itinerary"].includes(this.state.collection.contentType) && (
                          <Col sm="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="destination-select"
                              >
                                DESTINATION
                              </label>
                              <Select
                                value={this.state.collection.destinationId}
                                name="destination-select"
                                options={this.state.destinations}
                                placeholder="Add new content to collection"
                                clearable={true}
                                onInputChange={debounce(async (value) => {
                                  const allContent =
                                    await this.getAllAttractions(
                                      '',
                                      this.state.collection,
                                      value,
                                    );
                                  this.setState({allContent});
                                }, 300)}
                                onChange={(e) => {
                                  this.onFieldChange(
                                    'destinationId',
                                    e ? e.value : null,
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="tags"
                            >
                              TAGS
                            </label>
                            <Select
                              name="tags"
                              // value="one"
                              clearable={true}
                              // autofocus
                              labelKey="label"
                              options={this.state.tagList}
                              value={this.state.selectedTags || ''}
                              valueKey="label"
                              searchable={true}
                              multi={true}
                              simpleValue
                              onChange={(val) => this.handleTagChange(val)}
                              selectComponent={Creatable}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                            }}
                          >
                            <label
                              className="form-control-label"
                              htmlFor="status"
                            >
                              STATUS
                            </label>
                            <Badge
                              name="status"
                              color=""
                              className="badge-dot mr-4"
                              style={{fontSize: 13, fontWeight: 'bold'}}
                            >
                              <i
                                className={`bg-${collectionColorState}`}
                                style={{width: 9, height: 9}}
                              />
                              {collection.status === 'PUBLISHED'
                                ? 'PUBLISHED'
                                : 'UNPUBLISHED'}
                            </Badge>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="country-select"
                            >
                              Country
                            </label>
                            <Select
                              value={this.state.collection.countryId}
                              name="country-select"
                              options={this.state.countries}
                              placeholder="Country for collection"
                              clearable={true}
                              onChange={(e) => {
                                this.onFieldChange(
                                  'countryId',
                                  e ? e.value : null,
                                );
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Shareable Branch URL
                            </label>
                            <Input
                              className="form-control-alternative"
                              readOnly
                              defaultValue={
                                this.state.collection.branchUrl || ''
                              }
                              id="input-branch-url"
                              placeholder="Auto-generated Branch URL"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                    </div>
                  </Form>
                  <Row>
                    <h1>
                      <Badge color="black" style={{marginLeft: '10px'}}>
                        {' '}
                        Content
                      </Badge>
                    </h1>
                  </Row>
                  <Row style={{marginBottom: '10px'}}>
                    <Modal isOpen={albumModal} toggle={toggle} size="lg">
                      <ModalHeader toggle={toggle}>Albums</ModalHeader>
                      <ModalBody>
                        <SelectAlbumGallery
                          toggle={toggle}
                          updateSelectedAlbum={(albumId) =>
                            this.addContentToCollection(albumModal, albumId, "Attraction")
                          }
                          attraction={{attractionId: albumModal}}
                        />
                      </ModalBody>
                      <ModalFooter>
                        <Button color="secondary" onClick={toggle}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Col>
                      <Select
                        options={this.state.allContent}
                        placeholder="Add new content to collection"
                        onInputChange={debounce(async (value) => {
                          let allContent
                          if (["Attraction", "Itinerary"].includes(this.state.collection.contentType)) {
                            allContent = await this.getAllAttractions(
                              '',
                              this.state.collection,
                              value,
                            );
                          }
                          if (this.state.collection.contentType === "Destination") {
                            allContent = await this.getAllDestinations(
                                '',
                                this.state.collection,
                                value,
                            );
                          }
                          this.setState({allContent});
                        }, 300)}
                        onChange={({value}) => {
                          if (["Attraction", "Itinerary"].includes(this.state.collection.contentType)) {
                            return this.setState({albumModal: value});
                          }
                          return this.addContentToCollection(value, null, "Destination");
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>{this.renderContents()}</Row>
                  <Col>
                    <hr className="my-4"></hr>
                    <h1>
                      <Badge color="black" style={{marginLeft: '10px'}}>
                        {' '}
                        Pictures
                      </Badge>
                    </h1>
                    <Row>
                      <Col xs="2">
                        <h4 className="alert-heading">Single image upload</h4>

                        <FormGroup>
                          <label htmlFor="single">
                            <FontAwesomeIcon
                              icon={faImage}
                              color="#3B5998"
                              size="3x"
                            />
                          </label>
                          <input
                            type="file"
                            id="single"
                            onChange={this.onImageUpload}
                            className="upload-input-file"
                          />
                        </FormGroup>
                      </Col>

                      <Col xs="2">
                        <h4 className="alert-heading">
                          Multiple images upload
                        </h4>

                        <FormGroup>
                          <label htmlFor="multi">
                            <FontAwesomeIcon
                              icon={faImages}
                              color="#3B5998"
                              size="3x"
                            />
                          </label>
                          <input
                            className="upload-input-file"
                            type="file"
                            id="multi"
                            onChange={this.onImageUpload}
                            multiple
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Container>
                    {albums.map((album, index) => {
                      return (
                        <Album
                          key={`album${album.albumId}`}
                          album={album}
                          alert={console.log}
                          onMutate={this.getCollection}
                          contentId={this.state.collection.id}
                          contentType="collection"
                        />
                      );
                    })}
                  </Container>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Collection);
