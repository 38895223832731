import React from 'react';
import {debounce} from 'lodash';
import BackendService from '../../services/BackendService';
import {USER_KEY} from '../../utils/consts';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Table,
  Container,
  Row,
  Button,
  Col,
  Spinner,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {withRouter, Link} from 'react-router-dom';
import 'react-pagination-js/dist/styles.css'; // import css
import Select from 'react-virtualized-select';
import {confirmAlert} from 'react-confirm-alert';
import Input from 'reactstrap/lib/Input';

const SECTION_TYPE = {
  ATTRACTION: 'Attraction',
  COLLECTION: 'Collection',
  DESTINATION: 'Destination',
  GENERAL: 'General',
};

const SECTION_TYPE_OPTIONS = Object.values(SECTION_TYPE).map((value) => ({
  label: value,
  value,
}));

class Sections extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      currentPage: 1,
      isAdmin: false,
      activePage: 1,
      countries: [],
      countryId: '',
      city: '',
      sectionType: 'General',
    };
    this.pageLength = 20;
  }

  async componentDidMount() {
    if (this.props.location && this.props.location.historyObj) {
      this.setState({...this.props.location.historyObj});
    } else {
      this.setState({fetching: true});
      this.getAllSections();
      this.getCountries();
    }
  }

  getCountries = async () => {
    const {countries = []} = (await BackendService.getCountries()) || {};
    this.setState({
      countries: countries.map((country) => {
        return {label: country.name, value: country.prefix};
      }),
    });
  };

  getAllSections = debounce(async () => {
    const {sections} = await BackendService.getAllSections(
      this.state.sectionType,
      this.state.city,
      this.state.countryId,
    );

    this.setState({
      sections,
      fetching: false,
    });
  }, 200);

  isUserAdmin() {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    //// attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  }

  getHumanReadableTime(time) {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  }

  onTypeChange = ({value}) => {
    this.setState({sectionType: value}, this.getAllSections);
  };

  render() {
    if (this.state.fetching) {
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '900px',
          }}
        >
          <Spinner color="primary" />
        </div>
      );
    }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="2">
                      <h3 className="mb-0">Sections</h3>
                      {this.state.attractionsCount &&
                        `(${this.state.attractionsCount})`}
                    </Col>
                    <Col
                      className="text-right"
                      xs="4"
                      // style={{ backgroundColor: "blue" }}
                    >
                      <Select
                        value={this.state.sectionType}
                        options={SECTION_TYPE_OPTIONS}
                        onChange={this.onTypeChange}
                      />
                    </Col>
                    <Col
                      className="text-right"
                      xs="4"
                      // style={{ backgroundColor: "blue" }}
                    >
                      <Link
                        to={{
                          pathname: `/admin/sections/new`,
                          historyObj: this.state,
                        }}
                      >
                        <Button color="primary" href="#new" size="xm">
                          Add new
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{marginLeft: 10}}>
                      <th scope="col">Section Title</th>
                      <th scope="col">Content Type</th>
                      {/* {this.state.isAdmin && <th scope="col">Assignee</th>} */}
                      <th scope="col">Visible</th>
                      <th scope="col">Keep content order</th>
                      <th scope="col">Order</th>
                      <th scope="col">Display Mode</th>
                      <th scope="col">Description</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Updated At</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.sections &&
                      Object.keys(this.state.sections).map(
                        (sectionCategory) => (
                          <>
                            <tr>
                              <td className="align-items-center border--none">
                                <h3 className="pl-2 mb-0 mr-2">
                                  {sectionCategory.toUpperCase()}
                                </h3>
                              </td>
                              <td className="border--none" colSpan="2">
                                {sectionCategory === 'city' && (
                                  <Input
                                    className="form-control-alternative height--35"
                                    id="city-input"
                                    placeholder="City"
                                    type="text"
                                    onChange={(e) => {
                                      this.setState({
                                        ...this.state,
                                        city: e.target.value,
                                      });
                                      this.getAllSections();
                                    }}
                                  />
                                )}
                                {sectionCategory === 'country' && (
                                  <Select
                                    name="country-select"
                                    value={this.state.countryId}
                                    options={this.state.countries}
                                    placeholder="Country"
                                    clearable={true}
                                    onChange={(e) => {
                                      this.setState({
                                        ...this.state,
                                        countryId: e && e.value,
                                      });
                                      this.setState({fetching: true});
                                      this.getAllSections();
                                    }}
                                  ></Select>
                                )}
                              </td>
                            </tr>
                            {this.state.sections[sectionCategory].map(
                              (section) => {
                                const deleteSection = () => {
                                  confirmAlert({
                                    title: 'Confirm to delete',
                                    message: `Do you want to permanently delete this section?`,
                                    buttons: [
                                      {
                                        label: 'Yes',
                                        onClick: async () => {
                                          this.setState({fetching: true});
                                          const res =
                                            await BackendService.deleteSection(
                                              section.id,
                                            );
                                          if (res.status === 200) {
                                            this.getAllSections();
                                          } else {
                                            alert(
                                              'Something went wrong with your request',
                                            );
                                            this.setState({fetching: false});
                                          }
                                        },
                                      },
                                      {
                                        label: 'No',
                                        onClick: () => {
                                          return;
                                        },
                                      },
                                    ],
                                  });
                                };
                                return (
                                  <tr>
                                    <th scope="row">
                                      <Link
                                        to={{
                                          pathname: `/admin/sections/${section.id}`,
                                          historyObj: this.state,
                                        }}
                                      >
                                        <Media className="align-items-center">
                                          <Media style={{marginLeft: 10}}>
                                            <span
                                              className="mb-0 text-xm"
                                              style={{fontSize: 16}}
                                            >
                                              {section.title}
                                            </span>
                                          </Media>
                                        </Media>
                                      </Link>
                                    </th>

                                    <td>
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {section.contentType}
                                      </Badge>
                                    </td>

                                    <td>
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {(section.isVisible && 'VISIBLE') ||
                                          'NOT VISIBLE'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {(section.keepOrder &&
                                          'NOT RANDOMIZABLE') ||
                                          'RANDOMIZABLE'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <h1>
                                        <Badge color="primary">
                                          {section.ordering}
                                        </Badge>
                                      </h1>
                                    </td>
                                    <td>
                                      <Badge
                                        color=""
                                        className="badge-dot mr-4"
                                        style={{
                                          fontSize: 13,
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {section.displayMode}
                                      </Badge>
                                    </td>

                                    <td>
                                      <Media className="align-items-center">
                                        <Media style={{marginLeft: 10}}>
                                          <span
                                            className="mb-0 text-xm"
                                            style={{fontSize: 16}}
                                          >
                                            {section.description}
                                          </span>
                                        </Media>
                                      </Media>
                                    </td>

                                    <td>
                                      <label>
                                        {this.getHumanReadableTime(
                                          section.createdAt,
                                        )}
                                      </label>
                                    </td>
                                    <td>
                                      <label>
                                        {this.getHumanReadableTime(
                                          section.createdAt,
                                        ) ===
                                        this.getHumanReadableTime(
                                          section.updatedAt,
                                        )
                                          ? 'Same as created'
                                          : this.getHumanReadableTime(
                                              section.updatedAt,
                                            )}
                                      </label>
                                    </td>
                                    <td className="text-right">
                                      <UncontrolledDropdown>
                                        <DropdownToggle
                                          className="btn-icon-only text-light"
                                          // href="#pablo"
                                          role="button"
                                          size="sm"
                                          color=""
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <i className="fas fa-ellipsis-v" />
                                        </DropdownToggle>
                                        <DropdownMenu
                                          className="dropdown-menu-arrow"
                                          right
                                        >
                                          <DropdownItem
                                            // href="#pablo"
                                            onClick={deleteSection}
                                          >
                                            Delete
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                  // </Link>
                                );
                              },
                            )}
                          </>
                        ),
                      )}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Sections);
