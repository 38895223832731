import React from 'react';

import {ToastContainer, toast} from 'react-toastify';

import BackendService from '../../services/BackendService';
import {USER_KEY} from '../../utils/consts';

import {withRouter, Link} from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Media,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Progress,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';

import {confirmAlert} from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import {UncontrolledAlert} from 'reactstrap';
import debounce from 'lodash.debounce';

import SelectAlbumGallery from 'components/SelectAlbumGallery';

const DISPLAY_MODES = {
  HORIZONTAL_LANDSCAPE_SLIDER: 'HORIZONTAL_LANDSCAPE_SLIDER',
  HORIZONTAL_PORTRAIT_SLIDER: 'HORIZONTAL_PORTRAIT_SLIDER',
  GRID_SQUARE_2COL: 'GRID_SQUARE_2COL',
  GRID_PORTRAIT_2COL: 'GRID_PORTRAIT_2COL',
  LIST_LANDSCAPE: 'LIST_LANDSCAPE',
  LIST_PORTRAIT: 'LIST_PORTRAIT',
};

const CONTENT_TYPES = {
  ATTRACTION: 'Attraction',
  COLLECTION: 'Collection',
  DESTINATION: 'Destination',
  COUNTRY: 'Country',
};

const SECTION_TYPE = {
  ATTRACTION: 'Attraction',
  COLLECTION: 'Collection',
  DESTINATION: 'Destination',
  GENERAL: 'General',
};

const SECTION_TYPE_OPTIONS = Object.values(SECTION_TYPE).map((value) => ({
  label: value,
  value,
}));

const DISPLAY_MODE_OPTIONS = Object.values(DISPLAY_MODES).map((value) => ({
  label: value,
  value,
}));

class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      modal: false,
      section: {},
      allContent: [],
      updateButtonText: 'Save',
      isTextPublish: true,
      isAdmin: false,
      historyObj: {},
      showSaveButton: true,
      message: '',
      isError: false,
      showMessage: false,
    };
  }

  orderOptions = [...Array(100).keys()].map((index) => ({
    label: index,
    value: index,
  }));

  contentOptions = [
    {value: 'Attraction', label: 'Attraction'},
    {value: 'Collection', label: 'Collection'},
    {value: 'Destination', label: 'Destination'},
    {value: 'Country', label: 'Country'},
  ];

  alertMsg = (message, isError = false) => {
    this.setState({message, isError, showMessage: true});

    setTimeout(() => {
      this.setState({message: '', isError: false, showMessage: false});
    }, 5000);
  };

  async componentDidMount() {
    const sectionId = this.props.match.params.id;
    try {
      const {countries} = await BackendService.getCountries();

      if (sectionId === 'new') {
        this.setState({
          fetching: false,
          countries: countries.map((country) => {
            return {
              label: country.name,
              value: country.id,
              prefix: country.prefix,
            };
          }),
        });
      } else {
        const {section} = await BackendService.getSection(sectionId);

        let finalContent = [];
        if (section.contentType === CONTENT_TYPES.DESTINATION) {
          /* Fetch all destinations and filter the ones already in this section */
          finalContent = await this.getAllDestinations(
            section.contents.map((content) => content.id),
          );
        } else if (section.contentType === CONTENT_TYPES.ATTRACTION) {
          finalContent = await this.getAllAttractions(
            section.contents.map((content) => content.id),
          );
        } else if (section.contentType === CONTENT_TYPES.COLLECTION) {
          finalContent = await this.getAllCollections(
            section.contents.map((content) => content.id),
          );
        } else {
          const result = await BackendService.getCountries();
          const currentContentById = section.contents.map(
            (content) => content.id,
          );
          finalContent = result.countries
            .filter((country) => !currentContentById.includes(country.id))
            .map((country) => {
              return {label: country.name, value: country.id};
            });
        }

        this.setState({
          section,
          allContent: finalContent,
          countries: countries.map((country) => {
            return {
              label: country.name,
              value: country.id,
              prefix: country.prefix,
            };
          }),
          fetching: false,
        });
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  }

  getAllDestinations = async (sectionContentsById) => {
    const res = await BackendService.getAllDestinations();
    const finalContent = res.result.filter(
      (destination) => !sectionContentsById.includes(destination.id),
    );
    return finalContent;
  };

  getAllCollections = async (sectionContentsById) => {
    const {collections} = await BackendService.getCollectionsDashboard();
    const finalContent = collections.filter(
      (collection) => !sectionContentsById.includes(collection.id),
    );
    return finalContent;
  };

  getAllAttractions = async (sectionContentsById, search) => {
    const {attractionLabels} =
      await BackendService.fetchAttractionsForDashboard(search);
    const finalContent = attractionLabels.filter(
      (attr) => !sectionContentsById.includes(attr.value),
    );
    return finalContent;
  };

  isUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  };

  getSection = async (id) => {
    const {section} = await BackendService.getSectionById(id);

    /// get all tags of attraction and add them in state
    if (section) {
      this.setState({
        section,
      });
    } else {
      alert('Something went wrong. Please try again.');
    }
  };

  getHumanReadableTime = (time) => {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  };

  onFieldChange = (fieldName, value) => {
    const newState = {...this.state.section};

    if (value && value.length > 0) {
      newState[`${fieldName}`] = value.trim();
    } else {
      newState[`${fieldName}`] = value;
    }

    this.setState({section: newState});
  };

  clickGoBack = () => {
    this.props.history.push('/sections');
  };

  renderDestinationContent = () => {
    const {section} = this.state;
    const destinations =
      section && section.contents
        ? section.contents.sort((a, b) => a.order - b.order)
        : [];
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr style={{marginLeft: 10}}>
            <th scope="col">Destination Name</th>
            {this.state.isAdmin && <th scope="col">Assignee</th>}
            <th scope="col">Order</th>
            <th scope="col">Status</th>
            <th scope="col">Location</th>
            <th scope="col">Completion</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {destinations.map((destination) => {
            const onDeleteContent = async () => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.deleteContentFromSection({
                sectionId,
                contentType,
                id: destination.id,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const updateOrder = async (value) => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.updateContentFromSection({
                sectionId,
                contentType,
                id: destination.id,
                order: value,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const destinationColorState =
              destination.status === 'DATA ADDED'
                ? 'warning'
                : destination.status === 'PHOTO UPLOADED'
                ? 'info'
                : 'success';

            const progressBarValue =
              destination.status === 'DATA ADDED'
                ? 60
                : destination.status === 'PHOTO UPLOADED'
                ? 80
                : 100;

            return (
              <tr>
                <th scope="row">
                  <Link
                    to={{
                      pathname: `/admin/destination/${destination.id}`,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        <span className="mb-0 text-xm" style={{fontSize: 16}}>
                          {destination.name}
                        </span>
                      </Media>
                    </Media>
                  </Link>
                </th>
                {this.state.isAdmin && (
                  <td>
                    {destination.User.firstName +
                      ' ' +
                      destination.User.lastName}
                  </td>
                )}

                <td>
                  <Select
                    options={this.orderOptions}
                    value={destination.order}
                    // onChange={updateOrder}
                    onChange={({value}) => updateOrder(value)}
                  >
                    {section.order}
                  </Select>
                </td>
                <td>
                  {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
              PUBLISHED = green dot */}
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      className={`bg-${destinationColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {destination.status}
                  </Badge>
                </td>
                <td>
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      // className={`bg-${attractionColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {(destination.geoPoint && 'Yes') || 'No'}
                  </Badge>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{progressBarValue + '%'}</span>
                    <div>
                      <Progress
                        max="100"
                        value={progressBarValue}
                        barClassName={`bg-${destinationColorState}`}
                      />
                    </div>
                  </div>
                </td>

                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      // href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDeleteContent}
                      >
                        Delete content
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  renderCollectionContent = () => {
    const {section} = this.state;
    const collections = section.contents
      ? section.contents.sort((a, b) => a.order - b.order)
      : [];
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr style={{marginLeft: 10}}>
            <th scope="col">Name</th>
            <th scope="col">Order</th>
            <th scope="col">Content Type</th>
            <th scope="col">Status</th>
            <th scope="col">Type</th>
            <th scope="col">Description</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated At</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {collections.map((collection) => {
            const onDeleteContent = async () => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.deleteContentFromSection({
                sectionId,
                contentType,
                id: collection.id,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const updateOrder = async (value) => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.updateContentFromSection({
                sectionId,
                contentType,
                id: collection.id,
                order: value,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };
            const collectionColorState =
              collection.status === 'DATA ADDED'
                ? 'warning'
                : collection.status === 'PHOTO UPLOADED'
                ? 'info'
                : 'success';
            return (
              <tr>
                <th scope="row">
                  <Link
                    to={{
                      pathname: `/admin/collections/${collection.id}`,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        <span className="mb-0 text-xm" style={{fontSize: 16}}>
                          {collection.name}
                        </span>
                      </Media>
                    </Media>
                  </Link>
                </th>
                <td>
                  <Select
                    options={this.orderOptions}
                    value={collection.order}
                    // onChange={updateOrder}
                    onChange={({value}) => updateOrder(value)}
                  >
                    {section.order}
                  </Select>
                </td>

                <td>
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    {collection.contentType}
                  </Badge>
                </td>
                <td>
                  {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      className={`bg-${collectionColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {collection.status}
                  </Badge>
                </td>
                <td>
                  <Badge color="" style={{fontSize: 13, fontWeight: 'bold'}}>
                    {collection.type}
                  </Badge>
                </td>

                <td>
                  <Media className="align-items-center">
                    <Media style={{marginLeft: 10}}>
                      <span className="mb-0 text-xm" style={{fontSize: 16}}>
                        {collection.description}
                      </span>
                    </Media>
                  </Media>
                </td>

                <td>
                  <label>
                    {this.getHumanReadableTime(collection.createdAt)}
                  </label>
                </td>
                <td>
                  <label>
                    {this.getHumanReadableTime(collection.createdAt) ===
                    this.getHumanReadableTime(collection.updatedAt)
                      ? 'Same as created'
                      : this.getHumanReadableTime(collection.updatedAt)}
                  </label>
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      // href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDeleteContent}
                      >
                        Remove from section
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
              // </Link>
            );
          })}
        </tbody>
      </Table>
    );
  };

  renderAttractionContent() {
    const {section} = this.state;
    const attractions = section.contents
      ? section.contents.sort((a, b) => a.order - b.order)
      : [];
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr style={{marginLeft: 10}}>
            <th scope="col">Attraction Name</th>
            <th scope="col">Destination Name</th>
            {this.state.isAdmin && <th scope="col">Assignee</th>}
            <th scope="col">Order</th>
            <th scope="col">Status</th>
            <th scope="col">Location</th>
            <th scope="col">Completion</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated At</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {attractions.map((attraction) => {
            const {Destination} = attraction;
            const destinationColorState =
              Destination.status === 'DATA ADDED'
                ? 'warning'
                : Destination.status === 'PHOTO UPLOADED'
                ? 'info'
                : 'success';

            const onDeleteContent = async () => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.deleteContentFromSection({
                sectionId,
                contentType,
                id: attraction.id,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const updateOrder = async (value) => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.updateContentFromSection({
                sectionId,
                contentType,
                id: attraction.id,
                order: value,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };
            const attractionColorState =
              attraction.status === 'DATA ADDED'
                ? 'warning'
                : attraction.status === 'PHOTO UPLOADED'
                ? 'info'
                : 'success';

            const progressBarValue =
              attraction.status === 'DATA ADDED'
                ? 60
                : attraction.status === 'PHOTO UPLOADED'
                ? 80
                : 100;
            return (
              <tr>
                <th scope="row">
                  <Link
                    to={{
                      pathname: `/admin/attraction/${attraction.id}`,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        <span className="mb-0 text-xm" style={{fontSize: 16}}>
                          {attraction.name}
                        </span>
                      </Media>
                    </Media>
                  </Link>
                </th>
                <th scope="row">
                  <Link
                    to={{
                      pathname: Destination
                        ? `/admin/destination/${Destination.id}`
                        : undefined,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                        <Badge
                          color=""
                          className="badge-dot mr-4"
                          style={{fontSize: 13, fontWeight: 'bold'}}
                        >
                          <i
                            className={`bg-${destinationColorState}`}
                            style={{width: 9, height: 9}}
                          />
                          <span className="mb-0 text-xm" style={{fontSize: 16}}>
                            {Destination ? Destination.name : 'No destination'}
                          </span>
                        </Badge>
                      </Media>
                    </Media>
                  </Link>
                </th>
                {this.state.isAdmin && (
                  <td>
                    {attraction.User.firstName + ' ' + attraction.User.lastName}
                  </td>
                )}
                <td>
                  <Select
                    options={this.orderOptions}
                    value={attraction.order}
                    // onChange={updateOrder}
                    onChange={({value}) => updateOrder(value)}
                  >
                    {section.order}
                  </Select>
                </td>
                <td>
                  {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      className={`bg-${attractionColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {attraction.status}
                  </Badge>
                </td>
                <td>
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      // className={`bg-${attractionColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {(attraction.hasAllLocations && 'Yes') || 'No'}
                  </Badge>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{progressBarValue + '%'}</span>
                    <div>
                      <Progress
                        max="100"
                        value={progressBarValue}
                        barClassName={`bg-${attractionColorState}`}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <label>
                    {this.getHumanReadableTime(attraction.createdAt)}
                  </label>
                </td>
                <td>
                  <label>
                    {this.getHumanReadableTime(attraction.createdAt) ===
                    this.getHumanReadableTime(attraction.updatedAt)
                      ? ''
                      : this.getHumanReadableTime(attraction.updatedAt)}
                  </label>
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      // href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDeleteContent}
                      >
                        Remove from section
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
              // </Link>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderCountriesContent() {
    const {section} = this.state;
    const countries = section.contents
      ? section.contents.sort((a, b) => a.order - b.order)
      : [];
    return (
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr style={{marginLeft: 10}}>
            <th scope="col">Name</th>
            <th scope="col">Order</th>
            <th scope="col">Status</th>
            <th scope="col">Completion</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated At</th>
          </tr>
        </thead>
        <tbody>
          {countries.map((country) => {
            const countryColorState =
              country.status === 'DATA_ADDED'
                ? 'warning'
                : country.status === 'PHOTO_UPLOADED' ||
                  country.status === 'UNPUBLISHED'
                ? 'info'
                : 'success';

            const onDeleteContent = async () => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.deleteContentFromSection({
                sectionId,
                contentType,
                id: country.id,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const updateOrder = async (value) => {
              const {id: sectionId, contentType} = this.state.section;
              this.setState({fetching: true});
              await BackendService.updateContentFromSection({
                sectionId,
                contentType,
                id: country.id,
                order: value,
              });
              const {section} = await BackendService.getSection(sectionId);
              this.setState({section, fetching: false});
            };

            const progressBarValue =
              country.status === 'DATA_ADDED'
                ? 60
                : country.status === 'PHOTO_UPLOADED' ||
                  country.status === 'UNPUBLISHED'
                ? 80
                : 100;
            return (
              <tr>
                <th scope="row">
                  <Link
                    to={{
                      pathname: `/admin/countries/${country.id}`,
                      historyObj: this.state,
                    }}
                  >
                    <Media className="align-items-center">
                      <Media style={{marginLeft: 10}}>
                        <span className="mb-0 text-xm" style={{fontSize: 16}}>
                          {country.name}
                        </span>
                      </Media>
                    </Media>
                  </Link>
                </th>

                <td>
                  <Select
                    options={this.orderOptions}
                    value={country.order}
                    // onChange={updateOrder}
                    onChange={({value}) => updateOrder(value)}
                  >
                    {section.order}
                  </Select>
                </td>
                <td>
                  {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                  <Badge
                    color=""
                    className="badge-dot mr-4"
                    style={{fontSize: 13, fontWeight: 'bold'}}
                  >
                    <i
                      className={`bg-${countryColorState}`}
                      style={{width: 9, height: 9}}
                    />
                    {country.status}
                  </Badge>
                </td>

                <td>
                  <div className="d-flex align-items-center">
                    <span className="mr-2">{progressBarValue + '%'}</span>
                    <div>
                      <Progress
                        max="100"
                        value={progressBarValue}
                        barClassName={`bg-${countryColorState}`}
                      />
                    </div>
                  </div>
                </td>
                <td>
                  <label>{this.getHumanReadableTime(country.createdAt)}</label>
                </td>
                <td>
                  <label>{this.getHumanReadableTime(country.updatedAt)}</label>
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light"
                      // href="#pablo"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        // href="#pablo"
                        onClick={onDeleteContent}
                      >
                        Remove from section
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
              // </Link>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderContents = () => {
    switch (this.state.section.contentType) {
      case 'Collection':
        return this.renderCollectionContent();
      case 'Destination':
        return this.renderDestinationContent();
      case 'Attraction':
        return this.renderAttractionContent();
      case 'Country':
        return this.renderCountriesContent();

      default:
        return null;
    }
  };

  deleteSection = () => {
    const sectionId = this.props.match.params.id;

    confirmAlert({
      title: 'Confirm to delete',
      message: `Do you want to permanently delete this section?`,
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            this.setState({fetching: true});
            const res = await BackendService.deleteSection(sectionId);
            if (res.status === 204) {
              this.props.history.push('/admin/sections');
            } else {
              alert('Something went wrong with your request');
              this.setState({fetching: false});
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  addContentToSection = async (id, albumId) => {
    const {modal, section} = this.state;
    const {id: sectionId, contentType} = section;
    this.setState({fetching: true});
    try {
      await BackendService.addContentToSection({
        sectionId,
        contentType,
        id: id || modal,
        albumId,
      });
      const {section} = await BackendService.getSection(sectionId);
      this.setState({section, fetching: false});
    } catch (err) {
      if (err.response) {
        this.setState({fetching: false});
        this.alertMsg(err.response.data.message, true);
      }
    }
  };

  submitChanges = async () => {
    const {
      order,
      contentType,
      displayMode,
      title,
      description = '',
      isVisible = false,
      sectionType = 'General',
      keepOrder = false,
      countryId,
      city,
      level,
      SectionsOrderings: [{id: sectionOrderId}] = [{}],
    } = this.state.section;
    const sectionId = this.props.match.params.id;
    const [countryDetails] = this.state.countries.filter(
      ({value}) => value === countryId,
    );

    try {
      if (sectionId === 'new') {
        const {section} = await BackendService.createSection({
          sectionType,
          country: countryDetails && countryDetails.prefix,
          ...this.state.section,
        });
        this.props.history.push(`/admin/sections/${section.id}`);
      } else {
        this.setState({fetching: true});

        const toSend = {
          order,
          contentType,
          displayMode,
          title,
          description,
          isVisible,
          sectionType,
          keepOrder,
          countryId,
          country: countryDetails && countryDetails.prefix,
          city,
          level,
          sectionOrderId,
        };
        const {section} = await BackendService.updateSection(sectionId, toSend);
        this.setState({section, fetching: false});
      }
    } catch (error) {
      toast.error('Something went wrong');
      this.setState({fetching: false});
    }
  };

  render() {
    const {section, modal} = this.state;
    const [{level, city, countryId}] = section.SectionsOrderings || [{}];

    const toggle = () => this.setState({modal: false});

    if (this.state.fetching) {
      return (
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '900px',
          }}
        >
          <Spinner color="primary" />
        </div>
      );
    }
    return (
      <>
        <ToastContainer />
        <div style={{paddingTop: 200}} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Link
            className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"
            to={{
              pathname: '/admin/sections',
              historyObj: this.state.historyObj,
            }}
            style={{marginRight: 5, paddingBottom: 20}}
          >
            <i class="fas fa-arrow-circle-left fa-lg"></i>
            <i
              style={{
                fontSize: 18,
                fontWeight: 'bold',
                alignSelf: 'center',
                justifyContent: 'center',
              }}
            >
              BACK
            </i>
          </Link>

          <Row>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Col xs="8">
                      {/* <h3 className="mb-0"></h3> */}
                      <UncontrolledAlert
                        color={this.state.isError ? 'danger' : 'success'}
                        style={{width: 400, position: 'absolute'}}
                        isOpen={this.state.showMessage}
                      >
                        {this.state.message}
                      </UncontrolledAlert>
                    </Col>
                    <Col className="text-right" xs="2">
                      {this.state.showSaveButton && (
                        <Button
                          color="danger"
                          // href="#pablo"
                          onClick={this.deleteSection}
                          size="m"
                        >
                          Delete
                        </Button>
                      )}
                    </Col>
                    <Col xs="2">
                      {this.state.showSaveButton && (
                        <Button
                          color="primary"
                          // href="#pablo"
                          onClick={this.submitChanges}
                          size="m"
                        >
                          {this.state.updateButtonText}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Section information
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <label
                              htmlFor="selectSectionType"
                              sm={2}
                              className="form-control-label"
                            >
                              SECTION SCREEN
                            </label>

                            <Select
                              name="selectSectionType"
                              value={
                                this.state.section.sectionType || 'General'
                              }
                              options={SECTION_TYPE_OPTIONS}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    sectionType: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="title-input"
                            >
                              TITLE
                            </label>
                            <Input
                              className="form-control"
                              defaultValue={this.state.section.title || ''}
                              id="title-input"
                              placeholder="Title"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('title', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="description-input"
                            >
                              DESCRIPTION
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={
                                this.state.section.description || ''
                              }
                              id="description-input"
                              placeholder="Description"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange(
                                  'description',
                                  e.target.value,
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="2">
                          <FormGroup>
                            <label
                              htmlFor="selectVisible"
                              sm={2}
                              className="form-control-label"
                            >
                              VISIBLE
                            </label>

                            <Select
                              name="selectVisible"
                              value={this.state.section.isVisible}
                              options={[
                                {value: true, label: 'TRUE'},
                                {value: false, label: 'FALSE'},
                              ]}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    isVisible: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col sm="1">
                          <FormGroup>
                            <label
                              htmlFor="selectOrder"
                              sm={2}
                              className="form-control-label"
                            >
                              ORDER
                            </label>

                            <Select
                              name="selectOrder"
                              value={this.state.section.order}
                              options={this.orderOptions}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    order: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              htmlFor="selectContent"
                              sm={2}
                              className="form-control-label"
                            >
                              CONTENT TYPE
                            </label>

                            <Select
                              name="selectContentType"
                              value={this.state.section.contentType}
                              options={this.contentOptions}
                              onChange={({value}) => {
                                const {section} = this.state;
                                if (!section.contents) {
                                  this.setState((prevState) => ({
                                    section: {
                                      ...prevState.section,
                                      contentType: value,
                                    },
                                  }));
                                } else {
                                  alert('Delete all content firs');
                                }
                              }}
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <label
                              htmlFor="selectDisplay"
                              sm={2}
                              className="form-control-label"
                            >
                              DISPLAY MODE
                            </label>

                            <Select
                              name="selectDisplay"
                              value={this.state.section.displayMode}
                              options={DISPLAY_MODE_OPTIONS}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    displayMode: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              htmlFor="selectDisplay"
                              sm={2}
                              className="form-control-label"
                            >
                              KEEP CONTENT ORDER
                            </label>

                            <Select
                              name="selectKeepOrder"
                              value={this.state.section.keepOrder}
                              options={[
                                {value: true, label: 'TRUE'},
                                {value: false, label: 'FALSE'},
                              ]}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    keepOrder: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              htmlFor="selectDisplay"
                              sm={2}
                              className="form-control-label"
                            >
                              LEVEL
                            </label>

                            <Select
                              name="level"
                              value={this.state.section.level || level}
                              options={[
                                {value: 0, label: 'Worldwide'},
                                {value: 1, label: 'City'},
                                {value: 2, label: 'Country'},
                                {value: 3, label: 'General'},
                              ]}
                              onChange={({value}) =>
                                this.setState((prevState) => ({
                                  section: {
                                    ...prevState.section,
                                    level: value,
                                  },
                                }))
                              }
                            ></Select>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              htmlFor="selectDisplay"
                              sm={2}
                              className="form-control-label"
                            >
                              CITY
                            </label>

                            <Input
                              className="form-control-alternative height--35"
                              defaultValue={
                                this.state.section.city || city || ''
                              }
                              id="city-input"
                              placeholder="City"
                              type="text"
                              onChange={(e) =>
                                this.onFieldChange('city', e.target.value)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="country-select"
                            >
                              Country
                            </label>
                            <Select
                              value={this.state.section.countryId || countryId}
                              name="country-select"
                              options={this.state.countries}
                              placeholder="Country"
                              clearable={true}
                              onChange={(e) => {
                                this.onFieldChange(
                                  'countryId',
                                  e ? e.value : null,
                                );
                              }}
                            ></Select>
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr className="my-4" />
                    </div>
                  </Form>
                  <Row>
                    <h1>
                      <Badge color="black" style={{marginLeft: '10px'}}>
                        {' '}
                        Content
                      </Badge>
                    </h1>
                  </Row>
                  <Modal isOpen={modal} toggle={toggle} size="lg">
                    <ModalHeader toggle={toggle}>Albums</ModalHeader>
                    <ModalBody>
                      <SelectAlbumGallery
                        toggle={toggle}
                        updateSelectedAlbum={(albumId) =>
                          this.addContentToSection(null, albumId)
                        }
                        attraction={{attractionId: modal}}
                      />
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={toggle}>
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Row style={{marginBottom: '10px'}}>
                    <Col>
                      <Select
                        options={this.state.allContent}
                        placeholder="Add new content to section"
                        onChange={({value}) => {
                          if (
                            section.contentType === CONTENT_TYPES.ATTRACTION
                          ) {
                            this.setState({modal: value});
                          } else {
                            this.addContentToSection(value);
                          }
                        }}
                        onInputChange={debounce(async (value) => {
                          if (
                            section.contentType === CONTENT_TYPES.ATTRACTION
                          ) {
                            const allContent = await this.getAllAttractions(
                              '',
                              value,
                            );
                            this.setState({allContent});
                          }
                        }, 300)}
                      ></Select>
                    </Col>
                  </Row>
                  <Row>{this.renderContents()}</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Section);
