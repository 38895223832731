import React from 'react';

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import {USER_KEY, JWT_KEY} from '../../utils/consts';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      firstName: '',
    };
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    this.setState({user});
  }

  handleLogout = () => {
    localStorage.removeItem(JWT_KEY);
    localStorage.removeItem(USER_KEY);
    this.props.history.push('/auth/login');
  };

  render() {
    const {value, onChange, onSubmit} = this.props;
    return (
      <>
        <Navbar
          className="navbar-top navbar-dark bg-gradient-default opacity-8"
          expand="md"
          id="navbar-main"
        >
          <Container fluid>
            <Form
              className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
              onSubmit={onSubmit}
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Search"
                    type="text"
                    value={value}
                    onChange={(e) => {
                      const {target} = e;
                      onChange(target.value);
                    }}
                  />
                </InputGroup>
              </FormGroup>
            </Form>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.user.firstName +
                          ' ' +
                          this.state.user.lastName +
                          ' | Options'}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome to Framey</h6>
                  </DropdownItem>
                  <DropdownItem>
                    <i className="ni ni-single-02" />
                    <span>Role: {this.state.user.userRole}</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem href="" onClick={this.handleLogout}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default AdminNavbar;
