import React, {useState} from 'react';
import Select from 'react-select';
import {CardHeader, Row, Col, FormGroup} from 'reactstrap';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

const statuses = [
  {value: 'UNPUBLISHED', label: 'UNPUBLISHED'},
  {value: 'REJECTED', label: 'REJECTED'},
  {value: 'PUBLISHED', label: 'PUBLISHED'},
];

const Filters = ({
  status,
  setStatus,
  withoutCoordinates,
  setWithoutCoordinates,
}) => {
  const [coordsDropdown, setcoordsDropdown] = useState(false);
  return (
    <CardHeader className="border-0">
      <Row className="align-items-center">
        <Col xs="4">
          <h3 className="mb-0">Pending Albums</h3>
        </Col>
        <Col xs="2" />
        <Col xs="6">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-contentType">
              Status
            </label>
            <Select
              id="filter-contentType"
              name="contentType"
              value={status}
              onChange={(value) => {
                setStatus(value);
              }}
              options={statuses}
              placeholder="Filter by status"
              multi
            />
          </FormGroup>
        </Col>
        <Col xs="6" />
        <FormGroup className="col-6 switch">
          <Dropdown
            isOpen={coordsDropdown}
            toggle={() => setcoordsDropdown(!coordsDropdown)}
          >
            <DropdownToggle caret>{`Albums without coords ${
              withoutCoordinates === null
                ? 'ALL'
                : `${withoutCoordinates ? 'YES' : 'NO'}`
            }`}</DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setWithoutCoordinates(null);
                  setcoordsDropdown(false);
                }}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setWithoutCoordinates(true);
                  setcoordsDropdown(false);
                }}
              >
                Yes
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setWithoutCoordinates(false);
                  setcoordsDropdown(false);
                }}
              >
                No
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </FormGroup>
      </Row>
    </CardHeader>
  );
};

export default Filters;
