import * as React from 'react';
import {useEffect, useState} from "react";
import BackendService from "../../services/BackendService";
import {Button, Collapse, FormGroup, Input, Label, Spinner} from "reactstrap";
import {ToastContainer, toast} from 'react-toastify';
import EditCategory from "./EditCategory";

const CategoryExpander = ({id, description, name, selectedParents = [], subcategories = [], selected = {}, setSelected, inpName, ...prop}) => {
  const [open, setOpen] = useState(false)
  useEffect(() => {
    if (selectedParents.map(({id}) => id).includes(id)) {
      setOpen(true)
    }
  }, [selectedParents])
  return (
    <div className="CategoryExpander">
        <div onClick={() => {
          setOpen(!open)
        }} className="flex">
          <FormGroup check>
            <Label check>
              <Input checked={id === selected?.id} value={id} name={`category${inpName}`} type="radio" onChange={() => {
                setOpen(true)
                setSelected({id, description, name, subcategories, ...prop})
              }} />
              {description}
            </Label>
          </FormGroup>
          {subcategories.length ? <span className="Select-arrow"/> : ''}
        </div>
        {subcategories.length ?
          <Collapse isOpen={open}>
            <div className="pl-4">
              {subcategories.map(subCategory => <CategoryExpander {...{selected, selectedParents, setSelected, inpName, ...subCategory}}/>)}
            </div>
          </Collapse>
          : ''
        }
    </div>
)};

const findCategoryById = (id, categories) => {
  let category = {}
  for (let categoryDetails of categories) {
    if (categoryDetails.id === id) {
      category = categoryDetails
      break
    }
    if (categoryDetails?.subcategories?.length) {
      const response = findCategoryById(id, categoryDetails.subcategories)
      if (response.id) {
        category = response
        break
      }
    }
  }
  return category
}

const findParents = (parentId, categories) => {
  let parents = []
  const parent = findCategoryById(parentId, categories)
  parents.push(parent)
  if (parent.parentId) {
    const nextParents = findParents(parent.parentId, categories)
    parents = [...nextParents, ...parents]
  }
  return parents
}

const SelectedCategory = ({description, id, parents, attractionId, categories}) => {
  const [loading, setLoading] = useState(false)
  let category = {}

  if (!description) {
    category = findCategoryById(id, categories)
  }

  return (
    <div className={'SelectedCategory'}>
      <ToastContainer/>
      <h6 className="heading-small text-muted">
        <i className="fa fa-check text-pink"></i> Selected Category
      </h6>
      {id ? <h6 className="heading text-muted">
        (Id: {id}) {description || category.description}
      </h6> : <h3 className="heading-small text-muted">No category yet</h3>}
      {parents.length ?
        <h6 className="heading-smallest text-muted">
          Parents: {parents.map(({description, id}) => `(Id: ${id}) ${description}`).join(' > ')}
        </h6> : ''}
      <div className={'flex'}>
        <Button size="sm" onClick={async () => {
          setLoading(true)
          try {
            await BackendService.editAttraction({id: attractionId, categoryId: id})
            setLoading(false)
            toast.success('Successfully updated attraction');
          } catch (e) {
            setLoading(false)
            toast.error(e.message);
          }
        }} color={"success"}>Save</Button>
        {loading && <Spinner size={"sm"} />}
      </div>
    </div>
  )
}

let initialParents = []

export const CategoriesExpander = ({categories, selected, setSelected, inpName}) => <div>{categories.map(category => <CategoryExpander {...{selected, setSelected, selectedParents:initialParents, inpName, ...category}} />)} </div>

export default function AttractionCategories({category, attractionId, editMode}) {
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState({});
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    const init = async () => {
      const response = await BackendService.getAttractionCategories()
      setCategories(response)

    }
    init()
  }, [refetch])
  useEffect(() => {
    setSelected(category)
  }, [category])

  if (category?.parentId && !initialParents.length) {
    initialParents = findParents(category?.parentId, categories)
    if (!initialParents.filter(({id}) => !!id).length) {
      initialParents = []
    }
  }

  let selectedParents = []
  if (selected?.parentId) {
    selectedParents = findParents(selected?.parentId, categories)
  }

  return (
    <div className={"AttractionCategories"}>
      <div className={'flex mt-4'}>
        <div>
          <h6 className="heading-small text-muted mb-2">
            Attraction Categories
          </h6>
          <CategoriesExpander {...{categories, selected, setSelected}} />
          {editMode ? <Button onClick={() => setSelected({})} className="mt-3" color="primary">Add new</Button> : ''}
        </div>
        {editMode ? <EditCategory {...{...selected, selectedParents, categories, refetch, setRefetch}} /> : <SelectedCategory {...{categories, parents: selectedParents, attractionId, ...selected}} />}
      </div>
    </div>
  );
}
