import React from 'react';
import {ToastContainer, toast} from 'react-toastify';
import {Formik, Form} from 'formik';
import {
  Button,
  FormGroup,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import BackendService from 'services/BackendService';

export const camelCaseToText = (text) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

const ModalComponent = ({modal, toggle, translations, setTranslations}) => {
  const {original, content, contentId, contentType, lang, name} = modal;
  return (
    <>
      <ToastContainer />
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <Formik
          initialValues={content}
          onSubmit={async (values) => {
            try {
              const updatedTranslation = await BackendService.updateTranslation(
                {
                  contentId,
                  contentType,
                  lang,
                  values,
                },
              );
              const updatedTranslations = [...translations].map(
                ({i18n, ...props}) =>
                  props.content.id === contentId
                    ? {...props, i18n: {...i18n, [lang]: updatedTranslation}}
                    : {...props, i18n},
              );
              setTranslations(updatedTranslations);
              toast.success('Successfully updated translation');
              toggle();
            } catch (error) {
              toast.error(error.message);
            }
          }}
        >
          {({initialValues, handleChange}) => (
            <Form>
              <ModalHeader toggle={toggle}>{name}</ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    {Object.keys(original || {}).map((key) => (
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor={`filter-${key}`}
                        >
                          Original {camelCaseToText(key)}
                        </label>
                        <Input
                          disabled
                          id={`filter-${key}`}
                          rows={key === 'description' ? 7 : 3}
                          type="textarea"
                          defaultValue={original[key]}
                        />
                      </FormGroup>
                    ))}
                  </Col>
                  <Col>
                    {Object.keys(content || {}).map((key) => (
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor={`filter-${key}`}
                        >
                          {camelCaseToText(key)}
                        </label>
                        <Input
                          id={`filter-${key}`}
                          type="textarea"
                          rows={key === 'description' ? 7 : 3}
                          onChange={handleChange}
                          defaultValue={initialValues[key]}
                          name={key}
                        />
                      </FormGroup>
                    ))}
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button type="submit" color="primary">
                  Save
                </Button>
                <Button
                  color="danger"
                  onClick={async () => {
                    try {
                      await BackendService.deleteTranslation({
                        contentId,
                        contentType,
                        lang,
                      });
                      toast.success('Successfully deleted translation');
                      toggle();
                    } catch (error) {
                      toast.error(error.message);
                    }
                  }}
                >
                  Delete
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ModalComponent;
