import * as React from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Button,
  Row,
  Col,
} from 'reactstrap';
import {ToastContainer, toast} from 'react-toastify';
import PictureItem from './PictureItem';
import {useMutation} from 'react-query';
import {JWT_KEY} from '../utils/consts';
import {EndPoints} from 'utils/EndPoints';
import axios from 'axios';
import {useUserLabels} from '../utils/users';
import CustomSelect from '../components/Select';
import {useEditAlbum} from '../utils/album';
import {FormGroup} from 'react-bootstrap';
import Input from 'reactstrap/lib/Input';
import debounce from 'lodash.debounce';
import BackendService from "../services/BackendService";

const FRAMEY_ADMIN_ID = 999999999;

function useUploadPicture({
  contentType,
  contentId,
  onPictureUpload,
  albumId = null,
}) {
  const token = localStorage.getItem(JWT_KEY);
  const {mutate, ...rest} = useMutation(
    (formData) => {
      // append albumId to formData
      if (albumId) {
        formData.append('albumId', albumId);
      }
      return axios.post(
        `${EndPoints.coreURL}pictures/${contentType}/${contentId}`,
        formData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );
    },
    {onSettled: () => onPictureUpload()},
  );

  const onInputChange = (e) => {
    const errs = [];
    const files = Array.from(e.target.files);

    const formData = new FormData();
    const types = ['image/png', 'image/jpeg', 'image/gif'];

    files.forEach((file, i) => {
      if (types.every((type) => file.type !== type)) {
        errs.push(`'${file.type}' is not a supported format`);
      }

      formData.append('file', file);
    });

    if (errs.length) {
      return alert(errs.join(','));
    }

    mutate(formData);
  };

  return {onInputChange, ...rest};
}

function AlbumDropDown({
  isOpen,
  toggle,
  contentType,
  contentId,
  albumId,
  onMutate,
  attractionId,
  featuredAlbumId,
  showFeatured
}) {
  const {onInputChange, isLoading} = useUploadPicture({
    contentType,
    contentId,
    albumId,
    onMutate,
  });
  const inputRef = React.useRef();
  const [featuredLoading, setFeaturedLoading] = React.useState(false)
  return (
    <>
      {isLoading ? <Spinner color="primary" /> : null}
      <Dropdown isOpen={isOpen} toggle={toggle}>
        <DropdownToggle caret>Carousel Options</DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => inputRef.current?.click()}>
            <input
              type="file"
              ref={inputRef}
              onChange={onInputChange}
              className="upload-input-file"
            />
            Add new picture
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {showFeatured && <Button
          color={featuredAlbumId === albumId ? "success" : "info"}
          onClick={async () => {
            setFeaturedLoading(true)
            try {
              await BackendService.editAttraction({id: attractionId, featuredAlbumId: albumId})
              onMutate()
              toast.success("Successfully updated attraction")
              setFeaturedLoading(false)
            } catch (e) {
              setFeaturedLoading(false)
              toast.error(e)
            }
          }}
          isLoading={isLoading}
          size="md"
          style={{marginBottom: '20px', marginTop: '20px'}}
      >
        {featuredLoading ? 'Loading...' : `${featuredAlbumId === albumId ? 'Featured' : 'Mark as featured'}`}
      </Button>}
      {`(id: #${albumId})`}
    </>
  );
}
export function Album({
  contentType,
  contentId,
  album,
  alert,
  onMutate,
  attractions,
  attraction,
  getAllAttractions,
  showFeatured,
}) {
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');
  const [label, setLabel] = React.useState('');
  const initialUserId = contentType !== 'attraction' ? FRAMEY_ADMIN_ID : album.user.id;
  const [userId, setUserId] = React.useState(initialUserId);
  const [description, setDescription] = React.useState(album.description || '');
  const [attractionId, setAttractionId] = React.useState();
  const {data: userLabels} = useUserLabels(search);
  const {mutate, isLoading} = useEditAlbum(album.id, onMutate);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const {pictures = [], attractionId: currentAttractionId} = album;
  return (
    <Row
      style={{
        borderStyle: 'solid',
        borderRadius: '8px',
        borderColor: '#99ccff',
        borderWidth: '1px',
        position: 'relative',
        margin: '4px 2px',
        padding: '4px 4px',
      }}
    >
      <ToastContainer />
      <Col>
        <AlbumDropDown
          isOpen={dropdownOpen}
          toggle={toggle}
          contentId={contentId}
          contentType={contentType}
          albumId={album.id}
          attractionId={album?.attractionId}
          featuredAlbumId={attraction?.featuredAlbumId}
          showFeatured={showFeatured}
          onMutate={onMutate}
        />
        <CustomSelect
          isLoading={isLoading}
          name="User"
          options={userLabels}
          defaultValue={{
            value: userId,
            label:
              label ||
              (album?.user
                ? `${album?.user?.email || album?.user?.username} - ${
                    album?.user?.firstName
                  } ${album?.user?.lastName}`
                : userId),
          }}
          onInputChange={debounce((val) => {
            setSearch(val);
          }, 300)}
          onChange={(val) => {
            setUserId(val.value);
            setLabel(val.label);
          }}
        />
        <FormGroup>
          <Input
            className="form-control-alternative"
            id={`album-description${album.id}`}
            placeholder="Album Description"
            type="textarea"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormGroup>
        <Button
          color="info"
          onClick={() => mutate({userId, description})}
          isLoading={isLoading}
          size="md"
          style={{marginBottom: '20px'}}
        >
          {isLoading ? 'Loading...' : 'Save'}
        </Button>
        <FormGroup>
          <label className="form-control-label" htmlFor="input-first-name">
            Move album to another attraction
          </label>
          <CustomSelect
            isLoading={isLoading}
            name="attraction"
            key={attraction?.id}
            selected={attractionId}
            defaultValue={{
              label: attraction && `id: ${attraction.id} - ${attraction.name}`,
              value: attractionId,
            }}
            options={attractions}
            value={attractionId}
            onInputChange={debounce((val) => getAllAttractions(null, val), 300)}
            onChange={(val) => setAttractionId(val.value)}
          />
        </FormGroup>
        <Button
          color="info"
          onClick={() => mutate({attractionId, currentAttractionId})}
          isLoading={isLoading}
          size="md"
          style={{marginBottom: '20px'}}
        >
          {isLoading ? 'Loading...' : 'Move'}
        </Button>
      </Col>

      <Row>
        {pictures.map((pic) => (
          <PictureItem
            key={`pic${pic.pictureId || pic.id}`}
            onDeletePicture={onMutate}
            onEditPicture={onMutate}
            alert={alert}
            picture={pic}
          />
        ))}
      </Row>
    </Row>
  );
}
