import * as React from 'react'
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {CategoriesExpander} from "./index";
import {useEffect, useState} from "react";

export default function ChooseCategory({value, label, selected, setSelected, categories, onSelect, onClear, modal, setModal = () => {}}) {
  const [category, setCategory] = useState({})
  useEffect(() => {
    setCategory(selected)
  }, [selected])
  const toggle = () => setModal(!modal)
  const handleSelected = (category) => {
    setCategory(category)
    setSelected(category)
  }
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>
          Choose parent
        </ModalHeader>
        <ModalBody>
          <CategoriesExpander selected={category} setSelected={handleSelected} categories={categories} inpName="parent" />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            onSelect && onSelect(category)
            toggle()
          }}>Choose</Button>
        </ModalFooter>
      </Modal>
      <FormGroup>
        <Label>{label}</Label>
        <Row>
          <Col>
            <Input bsSize="sm" disabled value={value} />
          </Col>
          <Col>
            <Button type="button" size="sm" onClick={() => setModal(true)} type="button">Choose</Button>
            <Button size="sm" color="warning" onClick={() => {
              setSelected({name: ' '});
              onClear && onClear()
            }} type="button">
              Clear
            </Button>
          </Col>

        </Row>
      </FormGroup>
    </>
  )
}
