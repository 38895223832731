import {EndPoints} from '../utils/EndPoints';

import {JWT_KEY} from '../utils/consts';
import axios from 'axios';

const commonHeaders = {
  Accept: 'application/json',
  'content-type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
};

class BackendService {
  /////////////////////////////// User related functions ///////////////////////////////

  static async getUserInfos(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}${EndPoints.getUserInfos}${id ? `/${id}` : ''}`,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async createUser() {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.baseURL + EndPoints.createUser, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: '{}',
    });
    const json = await response.json();
    return json.userInfo;
  }

  static async loginUser(userData) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.loginUser, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify(userData),
    });
    const result = await response.json();
    if (result.type === 'success') {
      return result;
    } else {
      alert(result.message);
      return [];
    }
  }

  static async uploadPhoto(file) {
    const jwt = localStorage.getItem(JWT_KEY);
    const formData = new FormData();
    formData.append('image', file);
    let result = await axios.post(
      EndPoints.coreURL + EndPoints.uploadPhoto,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    if (result && result.data && result.status === 201) {
      return result.data;
    } else {
      return [];
    }
  }

  static async getTranslations(filters, offset = 0, limit = 20) {
    const jwt = localStorage.getItem(JWT_KEY);
    const {contentType, maxWordCount, keyword, name} = filters;
    let response = await fetch(
      `${EndPoints.coreURL}i18n?${
        contentType
          ? contentType.map(({value}) => `contentType=${value}`).join('&')
          : ''
      }${maxWordCount ? `&maxWordCount=${maxWordCount}` : ''}${
        name ? `&name=${name}` : ''
      }${`&keyword=${keyword || ''}`}&limit=${limit}&offset=${offset}`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getAlbums(
    id,
    status,
    offset = 0,
    limit = 20,
    withoutCoordinates = null,
  ) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURLV2}albums?${
        status ? status.map(({value}) => `status=${value}`).join('&') : ''
      }${id ? `&id=${id}` : ''}${
        withoutCoordinates !== null
          ? `&withoutCoordinates=${withoutCoordinates}`
          : ''
      }&limit=${limit}&offset=${offset}&sortByUserRoleId=false`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getUsers(
    search = null,
    levels = null,
    verified = null,
    offset = 0,
    limit = 20,
  ) {
    const jwt = localStorage.getItem(JWT_KEY);
    const levelsFormatted = levels && levels.map(({value}) => value);
    let response = await fetch(
      `${EndPoints.coreURL}users?limit=${limit}&offset=${offset}${
        search ? `&search=${search}` : ''
      }${
        levels ? `${levelsFormatted.map((l) => `&level=${l}`).join('')}` : ''
      }${verified ? `&verified=${verified}` : ''}`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getLocationsUsers(
    search = null,
  ) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}locations/${search ? search : 'all'}/assigned-users`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getAttractionCategories() {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}attraction-categories`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async getAttractionCategoryAssignedUsers(categoryId) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}attraction-categories/${categoryId}/assigned-users`,
      {
        method: 'GET',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async addAttractionCategoryAssignedUsers(userIds, attractionCategoryId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      let response = await fetch(
        `${EndPoints.coreURL}attraction-categories/${attractionCategoryId}/assigned-users`,
        {
          body: JSON.stringify({userIds}),
          method: 'PUT',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async addLocationAssignedUsers(userIds, location) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      let response = await fetch(
        `${EndPoints.coreURL}locations/${location}/assigned-users`,
        {
          body: JSON.stringify({userIds}),
          method: 'PUT',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );

      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteAttractionCategoryAssignedUsers(userIds, attractionCategoryId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      let response = await fetch(
        `${EndPoints.coreURL}attraction-categories/${attractionCategoryId}/assigned-users?userIds[]=${userIds.join('&userIds[]=')}`,
        {
          method: 'DELETE',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteLocationAssignedUsers(userIds, location) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      let response = await fetch(
        `${EndPoints.coreURL}locations/${location}/assigned-users?userIds[]=${userIds.join('&userIds[]=')}`,
        {
          method: 'DELETE',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async addAttractionCategories(data) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}attraction-categories`,
      {
        body: JSON.stringify(data),
        method: 'POST',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateAttractionCategories(data, id) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}attraction-categories/${id}`,
      {
        body: JSON.stringify(data),
        method: 'PUT',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteAttractionCategories(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      `${EndPoints.coreURL}attraction-categories/${id}`,
      {
        method: 'DELETE',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      },
    );
    try {
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateAlbum(albumId, values) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(`${EndPoints.coreURL}albums/${albumId}`, {
        body: JSON.stringify(values),
        method: 'PATCH',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      });
      const result = await response.json();
      if (result.error || response.status === 500) {
        throw new Error(result.error || result.message);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteAlbum(albumId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(`${EndPoints.coreURL}albums/${albumId}`, {
        method: 'DELETE',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      });
      const result = await response.json();
      if (result.error || response.status === 500) {
        throw new Error(result.error || result.message);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async notifyUser(userId, values, type = 'notification') {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(`${EndPoints.coreURL}user/${userId}/feeds`, {
        body: JSON.stringify([{type, ...values}]),
        method: 'POST',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      });
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateUser(userId, values) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(`${EndPoints.coreURL}user/${userId}`, {
        body: JSON.stringify(values),
        method: 'PUT',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      });
      const result = await response.json();
      if (result.error || response.status === 500) {
        throw new Error(result.error || result.message);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }


  static async deleteUser(userId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(`${EndPoints.coreURL}user/${userId}`, {
        method: 'DELETE',
        headers: {
          ...commonHeaders,
          Authorization: 'Bearer ' + jwt,
        },
      });
      const result = await response.json();
      if (result.error || response.status === 500) {
        throw new Error(result.error || result.message);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateTranslation({contentType, lang, contentId, values}) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(
        `${EndPoints.coreURL}i18n/${contentType}/${contentId}/languages/${lang}`,
        {
          body: JSON.stringify(values),
          method: 'PUT',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteTranslation({contentType, lang, contentId}) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await fetch(
        `${EndPoints.coreURL}i18n/${contentType}/${contentId}/languages/${lang}`,
        {
          method: 'DELETE',
          headers: {
            ...commonHeaders,
            Authorization: 'Bearer ' + jwt,
          },
        },
      );
      const result = await response.json();
      if (result.error) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  /////////////////////////////// Destinations related functions ///////////////////////////////

  static async getDestinations(
    {offset, limit, assigneeId, status, location},
    search,
  ) {
    const jwt = localStorage.getItem(JWT_KEY);

    const url =
      EndPoints.coreURL +
      EndPoints.getDestinations +
      offset +
      '/' +
      limit +
      '/dashboard' +
      '/assigneeId=' +
      (assigneeId || 'all') +
      '/status=' +
      (status || 'all') +
      '/location=' +
      (location || 'all');
    const params = search ? {search} : {};
    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        params,
      });
      if (result.status < 400) {
        return result.data;
      } else {
        alert(`Something went wrong: ${result.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getAllDestinations({assigneeId = 'all'} = {}) {
    const jwt = localStorage.getItem(JWT_KEY);
    const url = `${EndPoints.coreURL}${EndPoints.getAllDestinations}assigneeId=${assigneeId}`;
    let response = await axios.get(url, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    if (response.data.type === 'success') {
      return response.data;
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async getAllTags() {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.getAllTags, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    const result = await response.json();
    if (result.message === 'OK') {
      return result.tags;
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async getManagedCollections(offset, limit, includeBanner) {
    //// includeBanner = 'y' / 'n'
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL +
      EndPoints.getManagedCollections +
      'includeBanner=' +
      includeBanner +
      '/' +
      offset +
      '/' +
      limit,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const result = await response.json();
    if (result.type === 'success') {
      return result.result;
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async getDestination(destinationId) {
    const jwt = localStorage.getItem(JWT_KEY);
    const result = await axios.get(
      EndPoints.coreURL + EndPoints.getDestination + destinationId,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        params: {
          fromDashboard: true,
        },
      },
    );
    if (result.data) {
      return result.data;
    } else {
      console.log('There seems to be an error');
      return [];
    }
  }

  /////////////////////////////// Attractions related functions ///////////////////////////////
  static async getAttractions({
                                offset = 0,
                                limit = 20,
                                assigneeId = 'ALL',
                                status = 'ALL',
                                location = 'ALL',
                                description = 'ALL',
                                search = null,
                                destinationId = null,
                                countryId = null,
                                category = null,
                              }) {
    const url = EndPoints.coreURL + EndPoints.getAttractions;
    const jwt = localStorage.getItem(JWT_KEY);
    const params = {
      offset,
      limit,
      assigneeId,
      hasLocation: location,
      description,
      status,
      search,
      destinationId,
      countryId,
      category
    };

    try {
      const result = await axios.get(url, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        params,
      });
      if (result.status < 400) {
        return result.data;
      } else {
        alert(`Something went wrong: ${result.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getAttractionById(attractionId) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL + EndPoints.getAttraction + attractionId,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    try {
      const result = await response.json();
      return result;
    } catch (error) {
      return [];
    }
  }

  static async getNearbyAttractions({lat, lng, offset, limit}) {
    const jwt = localStorage.getItem(JWT_KEY);

    let response = await fetch(
      EndPoints.coreURL +
      EndPoints.getNearbyAttractions +
      lat +
      '/' +
      lng +
      '/' +
      offset +
      '/' +
      limit,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const result = await response.json();
    if (result.type === 'success') {
      return result.result;
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async addAttraction(attractionObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.addAttraction, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify(attractionObj),
    });
    const result = await response.json();
    return result;
  }

  static async addDestination(destinationObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.addDestination, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify(destinationObj),
    });
    const result = await response.json();
    return result;
  }

  static async editAttraction(attractionObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response;
    try {
      response = await fetch(
        EndPoints.coreURL + EndPoints.editAttraction + attractionObj.id,
        {
          method: 'PUT',
          headers: {
            Authorization: 'Bearer ' + jwt,
            'content-type': 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
          body: JSON.stringify(attractionObj),
        },
      );
      const result = await response.json();

      if (result.error || result.errors) {
        throw new Error(result.error);
      }
      return result;
    } catch (error) {
      throw new Error(error);
    }
  }

  static async editDestination(destinationObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL + EndPoints.editDestination + destinationObj.id,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        body: JSON.stringify(destinationObj),
      },
    );
    const result = await response.json();
    return result;
  }

  static async removeAttraction(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL + EndPoints.removeAttraction + id,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const result = await response.json();
    if (result.message === 'OK') {
      return 'success';
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async removeDestination(destinationObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL + EndPoints.removeDestination,
      {
        method: 'DELETE',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        body: JSON.stringify({destinationId: destinationObj}),
      },
    );
    const result = await response.json();
    if (result.type === 'success') {
      return 'success';
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async removePic(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.removePic, {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify({pictureId: id}),
    });
    const result = await response.json();
    if (result.type === 'success') {
      return 'success';
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async getAllAssignees(dropdown) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.coreURL +
      EndPoints.getAllAssignees +
      ((dropdown && '/dropdown') || ''),
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const json = await response.json();
    console.log(json);
    return json;
  }

  static async addToCollection(attractionObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.addToCollection, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify(attractionObj),
    });
    const result = await response.json();
    if (result.type === 'success') {
      return 'success';
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async removeAttractionFromCollection(attractionObj) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.baseURL + EndPoints.removeAttractionFromCollection,
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + jwt,
          'content-type': 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        body: JSON.stringify(attractionObj),
      },
    );
    const json = await response.json();
    return json;
  }

  /////////////////////////////// Collection related functions ///////////////////////////////
  static async getMyCollections() {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(EndPoints.coreURL + EndPoints.getMyCollections, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    const result = await response.json();
    if (result.type === 'success') {
      return result.result;
    } else {
      alert('There seems to be an error');
      return [];
    }
  }

  static async createCollection(name, photoUrl) {
    const jwt = localStorage.getItem(JWT_KEY);

    var data = {
      name: name,
      picture: photoUrl,
    };
    let response = await fetch(EndPoints.baseURL + EndPoints.createCollection, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + jwt,
        'content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 404) return [];
    const json = await response.json();
    return json;
  }

  static async getMySavedItemsByCollection(collectionName) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.baseURL +
      EndPoints.getMySavedItemsByCollection +
      collectionName,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const json = await response.json();
    return json;
  }

  /////////////////////////////// Contributor related functions ///////////////////////////////
  static async getContributorAttractions(contributorName) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await fetch(
      EndPoints.baseURL + EndPoints.getContributorAttractions + contributorName,
      {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    const json = await response.json();
    return json;
  }

  static async getAllSections(sectionType = 'General', city, country) {
    const jwt = localStorage.getItem(JWT_KEY);
    let response = await axios.get(EndPoints.coreURL + `sections/`, {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      params: {
        sectionType,
        city,
        country,
      },
    });

    return response.data;
  }

  static async getSection(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    const response = await axios.get(`${EndPoints.coreURL}sections/${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
      params: {
        dashboard: true,
      },
    });
    return response.data;
  }

  static async updateSection(id, updateContent) {
    const jwt = localStorage.getItem(JWT_KEY);

    const resp = await axios.patch(
      `${EndPoints.coreURL}sections/${id}`,
      updateContent,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );

    return resp.data;
  }

  static async deleteContentFromSection(deleteContentData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.delete(`${EndPoints.coreURL}sections/content`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        data: deleteContentData,
      });
      return resp.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async updateContentFromSection(updateContentData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.patch(
        `${EndPoints.coreURL}sections/content`,
        updateContentData,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      return resp.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async deleteSection(sectionId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      return axios.delete(`${EndPoints.coreURL}sections/${sectionId}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async addContentToSection(addData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      return axios.post(`${EndPoints.coreURL}sections/content`, addData, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async getAllCollections() {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      return axios.get(`${EndPoints.coreURL}collection/all`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async getCollectionsDashboard() {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.get(
        `${EndPoints.coreURL}collection/getCollections/dashboard`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      if (result.status < 400) {
        return result.data;
      } else {
        alert(`Something went wrong: ${result.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getCollection(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await axios.get(
        `${EndPoints.coreURL}collections/${id}?allAlbums=true`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async deleteCollection(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const response = await axios.delete(
        `${EndPoints.coreURL}collection/removeCollection/`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
          data: {
            collectionId: id,
          },
        },
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }

  static async updateCollection(id, updateContent) {
    const jwt = localStorage.getItem(JWT_KEY);

    const resp = await axios.patch(
      `${EndPoints.coreURL}collection/updateCollection/${id}`,
      updateContent,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );

    return resp.data;
  }

  static async updateContentFromCollection(updateContentData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.patch(
        `${EndPoints.coreURL}collection/updateContent`,
        updateContentData,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      if (resp.status < 400) {
        return resp.data;
      } else {
        alert(`Something went wrong: ${resp.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async updateCollectionContent(updateContentData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.patch(
        `${EndPoints.coreURL}collections/collectionContent`,
        updateContentData,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      if (resp.status < 400) {
        return resp.data;
      } else {
        alert(`Something went wrong: ${resp.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async deleteContentFromCollection(deleteContentData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.delete(
        `${EndPoints.coreURL}collection/deleteContent`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/x-www-form-urlencoded',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
          data: deleteContentData,
        },
      );
      return resp.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async addContentToCollection(addData) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      return axios.post(`${EndPoints.coreURL}collection/addContent`, addData, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async addCollection(addContent) {
    const jwt = localStorage.getItem(JWT_KEY);

    const resp = await axios.post(
      `${EndPoints.coreURL}collection/addCollection/`,
      addContent,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );

    return resp.data;
  }

  static async fetchAttractionsForDashboard(search, destinationId) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const resp = await axios.get(
        `${EndPoints.coreURL}attractions/labels?search=${search || ''}${
          destinationId ? `&destinationId=${destinationId}` : ''
        }`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      return resp.data;
    } catch (err) {
      console.log(err);
    }
  }

  static async createSection(addData) {
    const jwt = localStorage.getItem(JWT_KEY);

    const result = await axios.post(`${EndPoints.coreURL}sections/`, addData, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
    return result.data;
  }

  static async updateCollectionPublishStatus(id, status) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.post(
        `${EndPoints.coreURL}collection/publish/${id}`,
        {status, managed: true},
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      if (result.status < 400) {
        return result.data;
      } else {
        alert(`Something went wrong: ${result.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getCollectionsPages(
    limit = 20,
    offset = 0,
    status = 'ALL',
    search,
    destinationId,
    countryId
  ) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.get(
        `${EndPoints.coreURL}collections/getCollections/${limit}/${offset}`,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
          params: {
            status,
            ...(search && {search}),
            destinationId,
            countryId,
          },
        },
      );
      if (result.status < 400) {
        return result.data;
      } else {
        alert(`Something went wrong: ${result.data.message}`);
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getCountries(status = 'ALL') {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.get(`${EndPoints.coreURL}countries`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
        params: {
          status,
        },
      });
      if (result.status === 200) {
        return result.data;
      } else {
        alert(result.data.message);
      }
    } catch (err) {
      alert(`Something went wrong: ${err.message}`);
    }
  }

  static async getCountry(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.get(`${EndPoints.coreURL}countries/${id}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
      if (result.status === 200) {
        return result.data;
      } else {
        alert(result.data.message);
      }
    } catch (err) {
      alert(`Something went wrong: ${err.message}`);
    }
  }

  static async editCountry(id, body = {}) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.patch(
        `${EndPoints.coreURL}countries/${id}`,
        body,
        {
          headers: {
            Authorization: 'Bearer ' + jwt,
            Accept: 'application/json',
            'Cache-Control': 'no-cache, no-store, must-revalidate',
          },
        },
      );
      return result.data;
    } catch (err) {
      console.log(err);
      alert(`Something went wrong: ${err.message}`);
    }
  }

  static async publishCountry(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    const result = await axios.post(
      `${EndPoints.coreURL}countries/${id}/publish`,
      {},
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    return result.data;
  }

  static async unpublishCountry(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    const result = await axios.delete(
      `${EndPoints.coreURL}countries/${id}/publish`,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      },
    );
    return result.data;
  }

  static async deleteCountry(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    try {
      const result = await axios.delete(`${EndPoints.coreURL}countries/${id}`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/json',
          'Cache-Control': 'no-cache, no-store, must-revalidate',
        },
      });
      return result.data;
    } catch (err) {
      alert(`Something went wrong: ${err.message}`);
    }
  }

  static async getPictures(contentType, id) {
    const jwt = localStorage.getItem(JWT_KEY);
    return axios.get(`${EndPoints.coreURL}pictures/${contentType}/${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
  }

  static async deletePicture(id) {
    const jwt = localStorage.getItem(JWT_KEY);
    return axios.delete(`${EndPoints.coreURL}pictures/${id}`, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
  }

  static async editPicture(id, editBody) {
    const jwt = localStorage.getItem(JWT_KEY);
    return axios.patch(`${EndPoints.coreURL}pictures/${id}`, editBody, {
      headers: {
        Authorization: 'Bearer ' + jwt,
        Accept: 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
      },
    });
  }
}

export default BackendService;
