import React from 'react';

import Select from 'react-select';
import debounce from 'lodash.debounce';
import {CardHeader, FormGroup, Input, Row, Col} from 'reactstrap';

const contentTypes = [
  {value: 'collections', label: 'Collection'},
  {value: 'sections', label: 'Sections'},
  {value: 'countries', label: 'Countries'},
  {value: 'attractions', label: 'Attractions'},
  {value: 'destinations', label: 'Destinations'},
  {value: 'albums', label: 'Albums'},
  {value: 'users', label: 'Users'},
];

const Filters = ({filters, setFilters}) => {
  const setKeyword = debounce((value) => {
    setFilters({
      keyword: value,
    });
  }, 300);
  const setName = debounce((value) => {
    setFilters({
      name: value,
    });
  }, 300);
  return (
    <CardHeader className="border-0">
      <Row className="align-items-center">
        <Col xs="3">
          <h3 className="mb-0">Translations</h3>
        </Col>

        <Col xs="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-maxWordCount">
              Number of words
            </label>
            <Input
              id="filter-maxWordCount"
              type="number"
              name="maxWordCount"
              onChange={(e) => {
                e.persist();
                debounce(() => {
                  setFilters({
                    maxWordCount: e.target.value,
                  });
                }, 300)();
              }}
              placeholder="Set the max word count"
            />
          </FormGroup>
        </Col>
        <Col xs="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-keyword">
              Keywords
            </label>
            <Input
              id="filter-keyword"
              onChange={(e) => {
                e.persist();
                setKeyword(e.target.value);
              }}
              name="keyword"
              placeholder="Search by key words"
            />
          </FormGroup>
        </Col>
        <Col xs="3">
          <FormGroup>
            <label className="form-control-label" htmlFor="name-keyword">
              Name
            </label>
            <Input
              id="name-keyword"
              onChange={(e) => {
                e.persist();
                setName(e.target.value);
              }}
              name="name"
              placeholder="Search by name"
            />
          </FormGroup>
        </Col>
        <Col xs="3"></Col>
        <Col xs="9">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-contentType">
              Content type
            </label>
            <Select
              id="filter-contentType"
              name="contentType"
              value={filters?.contentType}
              onChange={(value) => {
                setFilters({contentType: value});
              }}
              options={contentTypes}
              placeholder="Filter by Content Type"
              multi
            />
          </FormGroup>
        </Col>
      </Row>
    </CardHeader>
  );
};

export default Filters;
