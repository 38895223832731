import React from 'react';
import {Button, CardHeader, Col, FormGroup, Row} from 'reactstrap';
import Input from 'reactstrap/lib/Input';


const Filters = ({
   search,
   setSearch,
   addNew,
   setAddNew
 }) => {
  return (
    <CardHeader className="border-0">
      <Row className="align-items-center">
        <Col xs="4" className="flex">
          <h3 className="mb-0 mr-5">Locations Users</h3>
          <Button onClick={() => setAddNew(!addNew)} color="primary"
                  size="sm">{addNew ? 'Show list' : 'Add new location user'}</Button>
        </Col>
        <Col xs={4}></Col>

        <Col style={{opacity: addNew ? 0 : 1}} xs="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-contentType">
              Search location
            </label>
            <Input value={search} onChange={(e) => setSearch(e.target.value)}/>
          </FormGroup>
        </Col>

      </Row>
    </CardHeader>
  );
};

export default Filters;
