import React, {useEffect, useMemo, useState} from 'react';
import BackendService from '../../services/BackendService';
import {EndPoints} from '../../utils/EndPoints';
import {USER_KEY} from '../../utils/consts';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
  FormGroup,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {withRouter, Link} from 'react-router-dom';
import Pagination from 'react-pagination-js';
import 'react-pagination-js/dist/styles.css'; // import css
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Select from 'react-virtualized-select';
import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import CountrySelect from 'components/CountrySelect';
import {debounce} from 'lodash';
import ChooseCategory from "../../components/AttractionCategories/ChooseCategory";

const initialState = {
  attractions: [],
  currentPage: 1,
  attractionsOffset: 0,
  attractionsLimit: 20,
  isAdmin: false,
  isDropdownOpen: false,
  assignees: [],
  selectedAssigneeId: null,
  activePage: 1,
  attractionsCount: 0,
  currentAssigneeId: null,
  currentAssigneeName: 'ALL',
  currentStatus: 'ALL',
  isStatusDropdownOpen: false,
  locationFilterText: 'ALL',
  descriptionFilterText: 'ALL',
  locationFilterOpen: false,
  descriptionFilterOpen: false,
  search: null,
  countryId: null,
  pageLength: 20,
};

const getChildrenCategoriesIds = (id, categories) => {
  const childrenCategories = [];

  const pushIds = (childrens) => {
    childrens.forEach(({id: childsId, subcategories = []}) => {
      childrenCategories.push(childsId)
      if (subcategories.length) {
        pushIds(subcategories)
      }
    })
  }

  const findSelected = (cats) => {
    cats.forEach(({id: catId, subcategories = []}) => {
      if (`${catId}` === id && subcategories.length) {
        return pushIds(subcategories)
      }
      if (subcategories.length) {
        findSelected(subcategories)
      }
    })
  }

  findSelected(categories)

  return childrenCategories
}

const Attractions = (props) => {
  const [state, setState] = useState(initialState);
  const searchString = props?.location?.search
  const countryId = new URLSearchParams(searchString).get("countryId")
  const category = new URLSearchParams(searchString).get("category")
  const destinationId = new URLSearchParams(searchString).get("destinationId")
  const descriptionFilterText = new URLSearchParams(searchString).get("description") || 'ALL'
  const locationFilterText = new URLSearchParams(searchString).get("location") || 'ALL'
  const currentStatus = new URLSearchParams(searchString).get("status") || 'ALL'
  const currentAssigneeId = new URLSearchParams(searchString).get("assigneeId")
  const search = new URLSearchParams(searchString).get("search") || ""
  const [destinationList, setDestinationList] = useState([]);
  const {
    attractions: attractionsState,
    attractionsOffset,
    attractionsLimit,
    isDropdownOpen,
    assignees,
    activePage,
    attractionsCount,
    currentAssigneeName,
    isStatusDropdownOpen,
    locationFilterOpen,
    descriptionFilterOpen,
    pageLength,
  } = state;

  const [selectedDestination = {}] = destinationList.filter(({value}) => value === Number(destinationId))

  const [fetching, setFetching] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [categories, setCategories] = useState([]);
  const [modal, setModal] = useState(false)
  const [remountHeader, setRemountHeader] = useState(false)

  useEffect(() => {
    const init = async () => {
      const response = await BackendService.getAttractionCategories()
      setCategories(response)

    }
    init()
  }, [])

  const isUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    //// attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  };

  const getNewAttractions = async () => {
    const isAdmin = isUserAdmin();

    let getAttrForUserId;
    if (isAdmin && currentAssigneeId) {
      getAttrForUserId = currentAssigneeId;
    } else if (isAdmin && !currentAssigneeId) {
      getAttrForUserId = undefined;
    } else if (!isAdmin && !currentAssigneeId) {
      const user = JSON.parse(localStorage.getItem(USER_KEY));
      getAttrForUserId = user.id;
    }

    let categoriesIds
    if (category) {
      categoriesIds = getChildrenCategoriesIds(category, categories)
      categoriesIds.push(category)
    }

    const {attractions = [], count} =
      (await BackendService.getAttractions({
        offset: attractionsOffset,
        limit: attractionsLimit,
        assigneeId: getAttrForUserId,
        location: locationFilterText || undefined,
        status: currentStatus || undefined,
        search,
        destinationId: destinationId,
        countryId,
        category: categoriesIds
      })) || {};
    setState({...state, attractions, isAdmin, attractionsCount: count});
  };

  const parseParamsAndRedirect = (params) => {
    const allParams = {
      countryId,
      destinationId,
      description: descriptionFilterText,
      location: locationFilterText,
      status: currentStatus,
      assigneeId: currentAssigneeId,
      search,
      ...params,
    }
    const url = `/admin/attractions?${Object.keys(allParams).map(key => !!allParams[key] && `${key}=${allParams[key]}`).filter(param => !!param).join("&")}`
    props.history.push(url)
  }

  const handleDestinationChange = async (val) => {
    parseParamsAndRedirect({
      destinationId: val?.value,
    })
  };

  const handleCountryChange = async (val) => {
    parseParamsAndRedirect({
      destinationId: null,
      countryId: val
    })
  };

  const handleSearchChange = async (search) => {
    parseParamsAndRedirect({
      search
    })
  };

  const getAllAssignees = async () => {
    const {result} = await BackendService.getAllAssignees(true);
    setState({...state, assignees: result});
  };

  const handlePageChange = async (pageNumber) => {
    let newOffset = (pageNumber - 1) * pageLength;
    let newLimit = newOffset + pageLength;
    setState({
      ...state,
      activePage: pageNumber,
      attractionsOffset: newOffset,
      attractionsLimit: newLimit,
    });
  };

  const handleClickAssignee = async (assignee) => {
    let currentAssigneeId = (assignee === 'all' && null) || assignee.id;
    let currentAssigneeName =
      (assignee === 'all' && 'ASSIGNEE ') ||
      assignee.firstName + ' ' + assignee.lastName + '  ';

    setState({
      ...state,
      currentAssigneeName
    });

    parseParamsAndRedirect({
      assigneeId: currentAssigneeId,
      location: locationFilterText,
      status: currentStatus,
    })


  };

  const isAdmin = isUserAdmin();
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  const handleClickStatus = async (statusReceived) => {
    parseParamsAndRedirect({
      assigneeId: isAdmin ? currentAssigneeId : user.id,
      status: statusReceived,
      destinationId: selectedDestination ? selectedDestination.value : null,
    })
  };

  const handleClickLocation = async (locationReceived) => {
    parseParamsAndRedirect({
      assigneeId: isAdmin ? currentAssigneeId : user.id,
      location: locationReceived,
    })
  };

  const handleDescriptionFilter = async (descriptionReceived) => {
    parseParamsAndRedirect({
      assigneeId: isAdmin ? currentAssigneeId : user.id,
      description: descriptionReceived,
    })
  };

  const getHumanReadableTime = (time) => {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  };

  const toggleDropdown = () => {
    setState({...state, isDropdownOpen: !state.isDropdownOpen});
  };

  const toggleStatusDropdown = () => {
    setState({...state, isStatusDropdownOpen: !state.isStatusDropdownOpen});
  };

  const toggleLocationFilter = () => {
    setState({...state, locationFilterOpen: !state.locationFilterOpen});
  };

  const toggleDescriptionFilter = () => {
    setState({...state, descriptionFilterOpen: !state.descriptionFilterOpen});
  };

  const getLocationDropdownTitle = (title) => {
    if (title === 'ALL') {
      return 'All';
    }
    if (title === 'YES') {
      return 'Yes';
    }
    if (title === 'NO') {
      return 'No';
    }
    return '';
  };

  const getStatusDropdownTitle = (title) => {
    if (title === 'all') {
      return 'STATUS ';
    }
    return title;
  };

  const getAssigneeDropdownTitle = (title) => {
    if (title === 'ALL') {
      return 'ASSIGNEE ';
    }
    return title;
  };

  useEffect(() => {
    async function onMount() {
      if (props.location && props.location.historyObj) {
        setState({...state, ...props.location.historyObj});
      } else {
        await getAllAssignees();
        const destinations = await BackendService.getAllDestinations();
        setDestinationList(destinations.result);
      }
    }
    onMount();
  }, []);

  const reMountKey = `
    ${activePage}
    ${attractionsLimit}
    ${attractionsOffset}
    ${destinationId}
    ${locationFilterText}
    ${currentStatus}
    ${search}
    ${destinationList}
    ${countryId}
    ${category}
    `;

  useEffect(() => {
    async function onMount() {
      setFetching(true);
      await getNewAttractions();
      setFetching(false);
    }
    if (!fetching) {
      onMount();
    }
  }, [reMountKey]);

  const CachedNavbar = useMemo(
    () => () =>
      (
        <AdminNavbar
          {...props}
          brandText={'Attractions'}
          value={state.search}
          onChange={debounce((value) => {
            handleSearchChange(value)
          }, 300)}
        />
      ),
    [],
  );


  const CachedHeader = useMemo(
    () => () =>
      (
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col xs="2">
              <h3 className="mb-0">Attractions</h3>
              {attractionsCount && `(${attractionsCount})`}
            </Col>
            <Col xs="4">
              <ChooseCategory label="Category" modal={modal} setModal={setModal} setSelected={(selected) => {
                setSelectedCategory(selected)
              }} onSelect={
                (selected) => parseParamsAndRedirect({category: selected.id})
              }
              onClear={() => {
                parseParamsAndRedirect({category: null})
                setRemountHeader(!remountHeader)
              }} categories={categories} selected={selectedCategory} value={selectedCategory.name} />
            </Col>
            <Col xs="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="filter-destination"
                >
                  COUNTRY
                </label>
                <CountrySelect
                  value={countryId}
                  onChange={(e) =>
                    handleCountryChange(e.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col xs="3">
              <FormGroup>
                <label
                  className="form-control-label"
                  htmlFor="filter-destination"
                >
                  DESTINATION
                </label>
                <Select
                  id="filter-destination"
                  name="destination"
                  key={countryId}
                  options={countryId ? destinationList.filter(({Country}) => Country?.id === Number(countryId)) : destinationList}
                  value={selectedDestination}
                  onChange={(val) => handleDestinationChange(val)}
                  placeholder="Filter by Destination"
                />
              </FormGroup>
            </Col>
            <Col
              className="text-right"
              xs="12"
              // style={{ backgroundColor: "blue" }}
            >
              <Dropdown
                isOpen={descriptionFilterOpen}
                toggle={toggleDescriptionFilter}
                // hidden={!isAdmin}
              >
                <DropdownToggle caret>
                  {`Description: ${descriptionFilterText}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={(e) => handleDescriptionFilter('ALL', e)}
                  >
                    All
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleDescriptionFilter('YES', e)}
                  >
                    Yes
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleDescriptionFilter('NO', e)}
                  >
                    No
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={locationFilterOpen}
                toggle={toggleLocationFilter}
                // hidden={!isAdmin}
              >
                <DropdownToggle caret>
                  {`LOCATION: ${getLocationDropdownTitle(locationFilterText)}`}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e) => handleClickLocation('ALL', e)}>
                    All
                  </DropdownItem>
                  <DropdownItem onClick={(e) => handleClickLocation('YES', e)}>
                    Yes
                  </DropdownItem>
                  <DropdownItem onClick={(e) => handleClickLocation('NO', e)}>
                    No
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Dropdown
                isOpen={isDropdownOpen}
                toggle={toggleDropdown}
                hidden={!isAdmin}
              >
                <DropdownToggle caret>
                  {getAssigneeDropdownTitle(currentAssigneeName)}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e) => handleClickAssignee('ALL', e)}>
                    All
                  </DropdownItem>
                  {assignees.map((assignee) => {
                    return (
                      <DropdownItem
                        onClick={(e) => handleClickAssignee(assignee, e)}
                      >
                        {assignee.firstName + ' ' + assignee.lastName}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                isOpen={isStatusDropdownOpen}
                toggle={toggleStatusDropdown}
                // style={{ width: 200 }}
                // hidden={!isAdmin}
              >
                <DropdownToggle caret>
                  {getStatusDropdownTitle(currentStatus)}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={(e) => handleClickStatus('ALL', e)}>
                    All
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleClickStatus('DATA ADDED', e)}
                  >
                    DATA ADDED
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleClickStatus('PHOTO UPLOADED', e)}
                  >
                    PHOTO UPLOADED
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleClickStatus('PUBLISHED', e)}
                  >
                    PUBLISHED
                  </DropdownItem>
                  <DropdownItem
                    onClick={(e) => handleClickStatus('UNPUBLISHED', e)}
                  >
                    UNPUBLISHED
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              <Link
                to={{
                  pathname: `/admin/attraction/new`,
                  historyObj: state,
                }}
              >
                <Button color="primary" href="#new" size="xm">
                  Add new
                </Button>
              </Link>
            </Col>
          </Row>
        </CardHeader>
      ),
    [
      remountHeader,
      modal,
      attractionsCount,
      destinationList,
      countryId,
      destinationId,
      locationFilterOpen,
      descriptionFilterOpen,
      isStatusDropdownOpen,
      isDropdownOpen,
    ],
  );

  return (
    <>
      <CachedNavbar />
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CachedHeader />
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr style={{marginLeft: 10}}>
                    <th scope="col">Attraction Name</th>

                    <th scope="col">Status</th>
                    <th scope="col">Location</th>
                    <th scope="col">Coords</th>
                    <th scope="col">Pictures</th>
                    <th scope="col">Completion</th>
                    <th scope="col">Category</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {attractionsState.map((attraction) => {
                    const attractionColorState =
                      attraction.status !== 'PUBLISHED'
                        ? 'warning'
                        : attraction.status === 'PHOTO UPLOADED'
                        ? 'info'
                        : 'success';

                    const progressBarValue =
                      attraction.status === 'DATA ADDED'
                        ? 60
                        : attraction.status === 'PHOTO UPLOADED'
                        ? 80
                        : 100;
                    return (
                      <tr>
                        <th scope="row">
                          <Link
                            to={{
                              pathname: `/admin/attraction/${attraction.id}`,
                              historyObj: state,
                            }}
                          >
                            <Media className="align-items-center">
                              <Media style={{marginLeft: 10}}>
                                <span
                                  className="mb-0 text-xm"
                                  style={{fontSize: 16}}
                                >
                                  {attraction.name}
                                </span>
                              </Media>
                            </Media>
                          </Link>
                        </th>

                        <td>
                          {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                          <Badge
                            color=""
                            className="badge-dot mr-4"
                            style={{fontSize: 13, fontWeight: 'bold'}}
                          >
                            <i
                              className={`bg-${attractionColorState}`}
                              style={{width: 9, height: 9}}
                            />
                            {attraction.status}
                          </Badge>
                        </td>
                        <td>
                          <Badge
                            color=""
                            className="badge-dot mr-4"
                            style={{fontSize: 13, fontWeight: 'bold'}}
                          >
                            <i
                              // className={`bg-${attractionColorState}`}
                              style={{width: 9, height: 9}}
                            />
                            {(attraction.hasAllLocations && 'Yes') || 'No'}
                          </Badge>
                        </td>
                        <td style={{fontSize: 11}}>
                          {attraction?.geoPoint?.coordinates[1]}, {attraction?.geoPoint?.coordinates[0]}
                        </td>
                        <td>
                          <div className="avatar-group">
                            {attraction.pictures.map((picture) => {
                              return (
                                <a
                                  className="avatar avatar-sm"
                                  // href="#pablo"
                                  id="tooltip742438047"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    alt="..."
                                    style={{
                                      borderRadius: '50%',
                                      width: 30,
                                      height: 30,
                                    }}
                                    src={
                                      picture.bucketUrl
                                        ? EndPoints.mediaURL + picture.bucketUrl
                                        : require('assets/img/framey/uploadLogo.png')
                                    }
                                  />
                                </a>
                              );
                            })}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              {progressBarValue + '%'}
                            </span>
                            <div>
                              <Progress
                                max="100"
                                value={progressBarValue}
                                barClassName={`bg-${attractionColorState}`}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          {attraction.categoryDescription || '-'}
                        </td>
                        <td>
                          <label>
                            {getHumanReadableTime(attraction.createdAt)}
                          </label>
                        </td>
                        <td>
                          <label>
                            {getHumanReadableTime(attraction.createdAt) ===
                            getHumanReadableTime(attraction.updatedAt)
                              ? ''
                              : getHumanReadableTime(attraction.updatedAt)}
                          </label>
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              // href="#pablo"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Action
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Another action
                              </DropdownItem>
                              <DropdownItem
                                // href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                Something else here
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                      // </Link>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <Pagination
                  currentPage={activePage}
                  // totalPages={(attractions && (attractionsCount / pageLength) + 1) || 10}
                  totalSize={attractionsCount}
                  sizePerPage={pageLength}
                  changeCurrentPage={handlePageChange}
                  theme="bootstrap"
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default withRouter(Attractions);
