import React from 'react';
import BackendService from '../../services/BackendService';
import {EndPoints} from '../../utils/EndPoints';
import {USER_KEY} from '../../utils/consts';

// reactstrap components
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
} from 'reactstrap';
// core components
import Header from 'components/Headers/Header.js';
import {withRouter, Link} from 'react-router-dom';
import Pagination from 'react-pagination-js';
import 'react-pagination-js/dist/styles.css'; // import css
import AdminNavbar from 'components/Navbars/AdminNavbar';

class Destinations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      destinations: [],
      currentPage: 1,
      destinationsOffset: 0,
      destinationsLimit: 20,
      isAdmin: false,
      isDropdownOpen: false,
      assignees: [],
      selectedAssigneeId: null,
      activePage: 1,
      destinationsCount: 0,
      currentAssigneeId: null,
      currentAssigneeName: 'all',
      isStatusDropdownOpen: false,
      currentStatus: 'all',
      locationFilterText: 'all',
      locationFilterOpen: false,
      search: '',
    };
    this.pageLength = 20;
  }

  async componentDidMount() {
    if (this.props.location && this.props.location.historyObj) {
      this.setState({...this.props.location.historyObj});
    } else {
      this.getNewDestinations();
      this.getAllAssignees();
    }
  }

  getAllAssignees = async () => {
    const {result} = await BackendService.getAllAssignees(true);
    this.setState({assignees: result});
  };

  getNewDestinations = async () => {
    const {
      destinationsOffset,
      destinationsLimit,
      currentAssigneeId,
      currentStatus,
      locationFilterText,
      search,
    } = this.state;
    const isAdmin = this.isUserAdmin();

    let getDestForUserId;
    if (isAdmin && currentAssigneeId) {
      getDestForUserId = currentAssigneeId;
    } else if (isAdmin && !currentAssigneeId) {
      getDestForUserId = undefined;
    } else if (!isAdmin && !currentAssigneeId) {
      const user = JSON.parse(localStorage.getItem(USER_KEY));
      getDestForUserId = user.id;
    }

    const {result, count} = await BackendService.getDestinations(
      {
        offset: destinationsOffset,
        limit: destinationsLimit,
        assigneeId: getDestForUserId,
        status: currentStatus,
        location: locationFilterText,
      },
      search,
    );
    this.setState({
      destinations: result,
      isAdmin,
      destinationsCount: count,
      currentAssigneeId: !isAdmin ? getDestForUserId : undefined,
    });
  };

  handlePageChange = async (pageNumber) => {
    let newOffset = (pageNumber - 1) * this.pageLength;
    let newLimit = newOffset + this.pageLength;
    this.setState(
      {
        activePage: pageNumber,
        destinationsOffset: newOffset,
        destinationsLimit: newLimit,
      },
      async () => {
        await this.getNewDestinations();
      },
    );
  };

  handleClickAssignee = async (assignee) => {
    let currentAssigneeId = (assignee === 'all' && null) || assignee.id;
    let currentAssigneeName =
      (assignee === 'all' && 'ASSIGNEE ') ||
      assignee.firstName + ' ' + assignee.lastName + '  ';

    const {currentStatus, locationFilterText} = this.state;
    const {result, count} = await BackendService.getDestinations({
      offset: 0,
      limit: 20,
      assigneeId: currentAssigneeId,
      location: locationFilterText,
      status: currentStatus,
    });
    this.setState({
      destinationsOffset: 0,
      destinationsLimit: 20,
      destinations: result,
      destinationsCount: count,
      currentAssigneeId,
      currentAssigneeName,
      activePage: 0,
    });
  };

  handleClickStatus = async (statusReceived) => {
    this.setState(
      {
        destinationsOffset: 0,
        destinationsLimit: 20,
        currentStatus: statusReceived,
      },
      this.getNewDestinations,
    );
  };

  handleClickLocation = async (locationReceived) => {
    const {currentStatus, currentAssigneeId} = this.state;

    const {result, count} = await BackendService.getDestinations({
      offset: 0,
      limit: 20,
      assigneeId: currentAssigneeId,
      status: currentStatus,
      location: locationReceived,
    });

    this.setState({
      destinationsOffset: 0,
      destinationsLimit: 20,
      destinations: result,
      destinationsCount: count,
      activePage: 0,
      locationFilterText: locationReceived,
    });
  };

  isUserAdmin() {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    //// attention: user stored in localStorage (loggedIn user) is different than destination.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  }

  getHumanReadableTime(time) {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  }

  toggleDropdown = () => {
    this.setState({isDropdownOpen: !this.state.isDropdownOpen});
  };

  toggleStatusDropdown = () => {
    this.setState({isStatusDropdownOpen: !this.state.isStatusDropdownOpen});
  };

  toggleLocationFilter = () => {
    this.setState({locationFilterOpen: !this.state.locationFilterOpen});
  };

  getLocationDropdownTitle(title) {
    if (title === 'all') {
      return 'All';
    }
    if (title === 'true') {
      return 'Yes';
    }
    if (title === 'false') {
      return 'No';
    }
    return '';
  }

  getStatusDropdownTitle(title) {
    if (title === 'all') {
      return 'STATUS ';
    }
    return title;
  }

  // getAssigneeDropdownTitle(title) {
  //   if (title === 'all') {
  //     return 'ASSIGNEE ';
  //   }
  //   return title;
  // }

  render() {
    return (
      <>
        <AdminNavbar
          {...this.props}
          brandText={'Destinations'}
          value={this.state.search}
          onChange={(value) =>
            this.setState({search: value}, this.getNewDestinations)
          }
        />
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Destinations</h3>
                      {this.state.destinationsCount &&
                        `(${this.state.destinationsCount})`}
                    </Col>
                    <Col className="text-right" xs="12">
                      <Dropdown
                        isOpen={this.state.locationFilterOpen}
                        toggle={this.toggleLocationFilter}
                        // hidden={!this.state.isAdmin}
                      >
                        <DropdownToggle caret>
                          {`LOCATION: ${this.getLocationDropdownTitle(
                            this.state.locationFilterText,
                          )}`}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => this.handleClickLocation('all', e)}
                          >
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) => this.handleClickLocation('true', e)}
                          >
                            Yes
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              this.handleClickLocation('false', e)
                            }
                          >
                            No
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      <Dropdown
                        isOpen={this.state.isDropdownOpen}
                        toggle={this.toggleDropdown}
                        hidden={!this.state.isAdmin}
                      >
                        {/* <DropdownToggle caret>
                          {this.getAssigneeDropdownTitle(
                            this.state.currentAssigneeName,
                          )}
                        </DropdownToggle> */}
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => this.handleClickAssignee('all', e)}
                          >
                            All
                          </DropdownItem>
                          {this.state.assignees.map((assignee) => {
                            return (
                              <DropdownItem
                                onClick={(e) =>
                                  this.handleClickAssignee(assignee, e)
                                }
                              >
                                {assignee.firstName + ' ' + assignee.lastName}
                              </DropdownItem>
                            );
                          })}
                        </DropdownMenu>
                      </Dropdown>
                      <Dropdown
                        isOpen={this.state.isStatusDropdownOpen}
                        toggle={this.toggleStatusDropdown}
                        // style={{ width: 200 }}
                        // hidden={!this.state.isAdmin}
                      >
                        <DropdownToggle caret>
                          {this.getStatusDropdownTitle(
                            this.state.currentStatus,
                          )}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={(e) => this.handleClickStatus('all', e)}
                          >
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              this.handleClickStatus('DATA ADDED', e)
                            }
                          >
                            DATA ADDED
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              this.handleClickStatus('PHOTO UPLOADED', e)
                            }
                          >
                            PHOTO UPLOADED
                          </DropdownItem>
                          <DropdownItem
                            onClick={(e) =>
                              this.handleClickStatus('PUBLISHED', e)
                            }
                          >
                            PUBLISHED
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      {this.state.isAdmin && (
                        <Link
                          to={{
                            pathname: `/admin/destination/new`,
                            historyObj: this.state,
                          }}
                        >
                          <Button color="primary" href="#new" size="xm">
                            Add new
                          </Button>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{marginLeft: 10}}>
                      <th scope="col">Destination Name</th>
                      {/* {this.state.isAdmin && <th scope="col">Assignee</th>} */}
                      <th scope="col">Status</th>
                      <th scope="col">Location</th>
                      <th scope="col">Pictures</th>
                      <th scope="col">Completion</th>
                      <th scope="col">Created At</th>
                      <th scope="col">Updated At</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.destinations.map((destination) => {
                      const destinationColorState =
                        destination.status === 'DATA ADDED'
                          ? 'warning'
                          : destination.status === 'PHOTO UPLOADED'
                          ? 'info'
                          : 'success';

                      const progressBarValue =
                        destination.status === 'DATA ADDED'
                          ? 60
                          : destination.status === 'PHOTO UPLOADED'
                          ? 80
                          : 100;
                      return (
                        <tr>
                          <th scope="row">
                            <Link
                              to={{
                                pathname: `/admin/destination/${destination.id}`,
                                historyObj: this.state,
                              }}
                            >
                              <Media className="align-items-center">
                                <Media style={{marginLeft: 10}}>
                                  <span
                                    className="mb-0 text-xm"
                                    style={{fontSize: 16}}
                                  >
                                    {destination.name}
                                  </span>
                                </Media>
                              </Media>
                            </Link>
                          </th>
                          {/* {this.state.isAdmin && (
                            <td>
                              {destination.User.firstName +
                                ' ' +
                                destination.User.lastName}
                            </td>
                          )} */}
                          <td>
                            {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                            <Badge
                              color=""
                              className="badge-dot mr-4"
                              style={{fontSize: 13, fontWeight: 'bold'}}
                            >
                              <i
                                className={`bg-${destinationColorState}`}
                                style={{width: 9, height: 9}}
                              />
                              {destination.status}
                            </Badge>
                          </td>
                          <td>
                            <Badge
                              color=""
                              className="badge-dot mr-4"
                              style={{fontSize: 13, fontWeight: 'bold'}}
                            >
                              <i
                                // className={`bg-${attractionColorState}`}
                                style={{width: 9, height: 9}}
                              />
                              {(destination.geoPoint && 'Yes') || 'No'}
                            </Badge>
                          </td>
                          <td>
                            <div className="avatar-group">
                              {destination.pictures.map((picture) => {
                                return (
                                  <a
                                    className="avatar avatar-sm"
                                    // href="#pablo"
                                    id="tooltip742438047"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    <img
                                      alt="..."
                                      style={{
                                        borderRadius: '50%',
                                        width: 30,
                                        height: 30,
                                      }}
                                      src={
                                        picture.bucketUrl
                                          ? EndPoints.mediaURL +
                                            picture.bucketUrl
                                          : require('assets/img/framey/uploadLogo.png')
                                      }
                                    />
                                  </a>
                                );
                              })}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <span className="mr-2">
                                {progressBarValue + '%'}
                              </span>
                              <div>
                                <Progress
                                  max="100"
                                  value={progressBarValue}
                                  barClassName={`bg-${destinationColorState}`}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <label>
                              {this.getHumanReadableTime(destination.createdAt)}
                            </label>
                          </td>
                          <td>
                            <label>
                              {this.getHumanReadableTime(
                                destination.createdAt,
                              ) ===
                              this.getHumanReadableTime(destination.updatedAt)
                                ? ''
                                : this.getHumanReadableTime(
                                    destination.updatedAt,
                                  )}
                            </label>
                          </td>
                          <td className="text-right">
                            <UncontrolledDropdown>
                              <DropdownToggle
                                className="btn-icon-only text-light"
                                // href="#pablo"
                                role="button"
                                size="sm"
                                color=""
                                onClick={(e) => e.preventDefault()}
                              >
                                <i className="fas fa-ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu
                                className="dropdown-menu-arrow"
                                right
                              >
                                <DropdownItem
                                  // href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Action
                                </DropdownItem>
                                <DropdownItem
                                  // href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Another action
                                </DropdownItem>
                                <DropdownItem
                                  // href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  Something else here
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <CardFooter className="py-4">
                  <Pagination
                    currentPage={this.state.activePage}
                    totalSize={this.state.destinationsCount}
                    sizePerPage={this.pageLength}
                    changeCurrentPage={this.handlePageChange}
                    theme="bootstrap"
                  />
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(Destinations);
