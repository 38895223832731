import React, {useEffect, useState} from 'react';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  Col,
  FormGroup,
  Spinner,
} from 'reactstrap';

import ModalComponent from './Modal';
import {Album} from 'components/Album';
import BackendService from 'services/BackendService';
import {Link} from 'react-router-dom';
import Input from 'reactstrap/lib/Input';
import StatusFormatter from 'components/StatusFormatter';

const getAllAttractions = (setAttractions) => async (attractionId, search) => {
  const {attractionLabels} = await BackendService.fetchAttractionsForDashboard(
    search,
  );

  const finalContent = attractionLabels
    .filter((attr) => attractionId !== attr.value)
    .map(({label, value}) => ({label: `id: ${value} - ${label}`, value}));
  setAttractions(finalContent);
};

const getAttraction = async (id) => {
  const {attraction} = await BackendService.getAttractionById(id);
  return attraction;
};

const PendingAlbum = ({match}) => {
  const {id} = match.params;
  const [album, setAlbum] = useState({});
  const [destination, setDestination] = useState({});
  const [user, setUser] = useState({});
  const [attractions, setAttractions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [{albums, ...attraction}, setAttraction] = useState({});
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(false);

  async function onMount() {
    setIsLoading(true);
    const [
      {
        albums: [album],
      },
      {user},
      {data: {pictures} = {}},
    ] = await Promise.all([
      BackendService.getAlbums(id),
      BackendService.getUserInfos(),
      BackendService.getPictures('album', id),
      getAllAttractions(setAttractions)(),
    ]);

    if (album?.attraction?.id) {
      const attraction = await getAttraction(album.attraction.id);
      const {destination} = await BackendService.getDestination(
        attraction.destinationId,
      );
      setDestination(destination);
      setAttraction(attraction);
    }
    setIsLoading(false);
    setUser(user);
    setAlbum({...album, pictures});
    toggle();
  }

  useEffect(() => {
    onMount();
  }, []);

  const [albumName, albumDestination, albumCountry] =
    album?.details?.split('\n') || [];

  const [firstPicture] = album?.pictures || [];

  return (
    <div style={{paddingTop: 100}} className="PendingAlbum">
      <ModalComponent
        {...{
          toggle,
          modal,
          albumId: id,
          albumUserId: album?.userId,
          attractionId: album?.attraction?.id,
          imageUrl: firstPicture?.bucketUrl,
          profileImage: user?.profileImageUrl,
          userId: user?.id,
          onMount,
        }}
      />
      <Container fluid>
        <Link
          className="h4 mb-0 m-t-3 text-black text-uppercase d-none d-lg-inline-block"
          to="/admin/pending-albums"
          style={{marginRight: 5, paddingBottom: 20}}
        >
          <i className="fas fa-arrow-circle-left fa-lg"></i>
          <i
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              alignSelf: 'center',
              justifyContent: 'center',
            }}
          >
            BACK
          </i>
        </Link>
        <Card className="bg-secondary shadow">
          <CardHeader className="PendingAlbum__header">
            <div style={{maxWidth: 400}}>
              <Row>
                <Col>
                  <Button
                    color={'success'}
                    onClick={() => setModal('PUBLISHED')}
                    disabled={
                      album?.status === 'PUBLISHED' || !album?.attraction?.id
                    }
                    style={{width: '100%'}}
                  >
                    Approve
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="warning"
                    disabled={album?.status === 'REJECTED'}
                    onClick={() => setModal('REJECTED')}
                    style={{width: '100%'}}
                  >
                    Decline
                  </Button>
                </Col>
                <Col>
                  <Button
                    color="danger"
                    onClick={() => setModal('DELETE')}
                    style={{width: '100%'}}
                  >
                    Delete
                  </Button>
                </Col>
              </Row>
            </div>
            <StatusFormatter status={album?.status} />
          </CardHeader>
          {isLoading ? (
            <div className="Loading__wrapper">
              <Spinner color="primary" />
            </div>
          ) : (
            <CardBody>
              <Row>
                <div className="col">
                  {album?.id && (
                    <Album
                      onMutate={() => setTimeout(onMount, 100)}
                      attraction={album.attraction}
                      contentType="attraction"
                      getAllAttractions={getAllAttractions(setAttractions)}
                      attractions={attractions}
                      contentId={album?.attraction?.id}
                      key={`Alb${album.id}${album.description}`}
                      album={album}
                    />
                  )}
                </div>
              </Row>{' '}
              <Row>
                <Col>
                  <br />
                  <h3>Attraction info</h3>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="atraction-name"
                    >
                      Name
                    </label>
                    <Input
                      disabled
                      id="atraction-name"
                      defaultValue={attraction.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="atraction-name"
                    >
                      Destination
                    </label>
                    <Input
                      disabled
                      id="atraction-name"
                      defaultValue={destination.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="atraction-name"
                    >
                      Country
                    </label>
                    <Input
                      disabled
                      id="atraction-name"
                      defaultValue={destination?.country?.name}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <br />
                  <h3>Album info</h3>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="album-name">
                      Name
                    </label>
                    <Input disabled id="album-name" defaultValue={albumName} />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="atraction-name"
                    >
                      Destination
                    </label>
                    <Input
                      disabled
                      id="atraction-name"
                      defaultValue={albumDestination}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="atraction-name"
                    >
                      Country
                    </label>
                    <Input
                      disabled
                      id="atraction-name"
                      defaultValue={albumCountry}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          )}
        </Card>
      </Container>
    </div>
  );
};

export default PendingAlbum;
