import * as React from 'react';
import BackendService from '../../services/BackendService';
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Container,
  Row,
  Button,
  Col,
} from 'reactstrap';
import Header from 'components/Headers/Header.js';
import {Link} from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';

function Countries(props) {
  const [countries, setCountries] = React.useState([]);
  const [search, setSearch] = React.useState('');
  const [countriesToShow, setCountriesToShow] = React.useState([]);
  const [statusDropdownOpen, setStatusDropdownOpen] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState('ALL');

  React.useEffect(() => {
    BackendService.getCountries().then((result) => {
      if (Array.isArray(result.countries)) {
        setCountries(result.countries);
        setCountriesToShow(result.countries);
      }
    });
  }, []);

  React.useEffect(() => {
    setCountriesToShow(
      countries.filter((country) =>
        country.name.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  }, [search, countries]);

  React.useEffect(() => {
    if (currentStatus === 'ALL') {
      setCountriesToShow(countries);
    } else {
      setCountriesToShow(
        countries.filter((country) => country.status === currentStatus),
      );
    }
  }, [currentStatus, countries]);

  const getHumanReadableTime = (time) => {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  };

  const renderStatusFilterDropdown = () => (
    <Dropdown
      isOpen={statusDropdownOpen}
      toggle={() => setStatusDropdownOpen(!statusDropdownOpen)}
    >
      <DropdownToggle caret>{currentStatus}</DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => setCurrentStatus('ALL')}>ALL</DropdownItem>
        <DropdownItem onClick={() => setCurrentStatus('DATA_ADDED')}>
          DATA_ADDED
        </DropdownItem>
        <DropdownItem onClick={() => setCurrentStatus('PHOTO_UPLOADED')}>
          PHOTO_UPLOADED
        </DropdownItem>
        <DropdownItem onClick={() => setCurrentStatus('PUBLISHED')}>
          PUBLISHED
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );

  return (
    <>
      <AdminNavbar
        {...props}
        brandText={'countrys'}
        value={search}
        onChange={setSearch}
      />
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Countries</h3>
                    {countries.length}
                  </Col>
                  <Col className="text-right" xs="12">
                    {renderStatusFilterDropdown()}
                    <Link
                      to={{
                        pathname: `/admin/countries/new`,
                      }}
                    >
                      <Button color="primary" href="#new" size="xm">
                        Add new
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr style={{marginLeft: 10}}>
                    <th scope="col">Country Name</th>
                    <th scope="col">Short description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Completion</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  {countriesToShow.map((country) => {
                    const countryColorState =
                      country.status === 'DATA_ADDED'
                        ? 'warning'
                        : country.status === 'PHOTO_UPLOADED'
                        ? 'info'
                        : 'success';

                    const progressBarValue =
                      country.status === 'DATA_ADDED'
                        ? 60
                        : country.status === 'PHOTO_UPLOADED'
                        ? 80
                        : 100;
                    return (
                      <tr>
                        <th scope="row">
                          <Link
                            to={{
                              pathname: `/admin/countries/${country.id}`,
                            }}
                          >
                            <Media className="align-items-center">
                              <Media style={{marginLeft: 10}}>
                                <span
                                  className="mb-0 text-xm"
                                  style={{fontSize: 16}}
                                >
                                  {country.name}
                                </span>
                              </Media>
                            </Media>
                          </Link>
                        </th>
                        <td>
                          <Media className="align-items-center">
                            <Media style={{marginLeft: 10}}>
                              <span
                                className="mb-0 text-xm"
                                style={{fontSize: 16}}
                              >
                                {country.shortDescription ||
                                  'No short description'}
                              </span>
                            </Media>
                          </Media>
                        </td>

                        <td>
                          {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                          <Badge
                            color=""
                            className="badge-dot mr-4"
                            style={{fontSize: 13, fontWeight: 'bold'}}
                          >
                            <i
                              className={`bg-${countryColorState}`}
                              style={{width: 9, height: 9}}
                            />
                            {country.status}
                          </Badge>
                        </td>

                        <td>
                          <div className="d-flex align-items-center">
                            <span className="mr-2">
                              {progressBarValue + '%'}
                            </span>
                            <div>
                              <Progress
                                max="100"
                                value={progressBarValue}
                                barClassName={`bg-${countryColorState}`}
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <label>
                            {getHumanReadableTime(country.createdAt)}
                          </label>
                        </td>
                        <td>
                          <label>
                            {getHumanReadableTime(country.updatedAt)}
                          </label>
                        </td>
                      </tr>
                      // </Link>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Countries;
