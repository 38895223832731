import React, {useEffect, useState} from 'react';
import BackendService from '../../services/BackendService';
import {USER_KEY} from '../../utils/consts';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Media,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Pagination from 'react-pagination-js';

// core components
import Header from 'components/Headers/Header.js';
import {Link, withRouter} from 'react-router-dom';
import 'react-pagination-js/dist/styles.css'; // import css
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Select from "react-virtualized-select";
import CountrySelect from "../../components/CountrySelect";


const pageLength = 20;

const Collections = (props) => {
  const searchString = props?.location?.search
  const countryId = new URLSearchParams(searchString).get("countryId")
  const destinationId = new URLSearchParams(searchString).get("destinationId")
  const currentStatus = new URLSearchParams(searchString).get("status") || 'ALL'
  const search = new URLSearchParams(searchString).get("search") || ""

  const [state, setState] = useState({
    sections: [],
    collections: [],
    destinationsList: [],
    selectedDestination: {},
    isAdmin: false,
    activePage: 1,
    collectionsLength: 1,
    offset: 0,
    limit: 20,
    search: '',
  });

  useEffect(() => {
    const onMount = async () => {
      let destinationsList
      if (!state.destinationsList.length) {
        const {result} = await BackendService.getAllDestinations();
        destinationsList = result
        setState({...state, destinationsList})
      }
      if (props.location && props.location.historyObj) {
        setState({...state, ...props.location.historyObj});
      } else {
        getNewCollections(destinationsList ? {destinationsList} : {});
      }
    }
    onMount()
  }, [currentStatus, countryId, destinationId, search, state.offset, state.limit])

  const parseParamsAndRedirect = (params) => {
    const {offset, limit} = state;
    const allParams = {
      offset,
      limit,
      currentStatus,
      search,
      ...params,
    }
    setState({
      offset,
      limit,
      ...state
    })
    const url = `/admin/collections?${Object.keys(allParams).map(key => !!allParams[key] && `${key}=${allParams[key]}`).filter(param => !!param).join("&")}`
    props.history.push(url)
  }

  const getNewCollections = async (extendState = {}) => {
    const {offset, limit} = state;
    const result = await BackendService.getCollectionsPages(
      limit,
      offset,
      currentStatus,
      search,
      destinationId,
      countryId
    );

    if (result !== undefined) {
      setState({
        ...state,
        ...extendState,
        collections: result.collections,
        collectionsLength: result.count,
      });
    }
  };

  const handleClickStatus = async (statusReceived) => {
    parseParamsAndRedirect({
      offset: 0,
      limit: 20,
      status: statusReceived,
    });
  };

  const handleDestinationChange = async (destination) => {
    setState({...state, selectedDestination: destination})
    parseParamsAndRedirect({
      offset: 0,
      limit: 20,
      destinationId: destination?.value,
    });
  };

  const handleCountryChange = async (countryId) => {
    parseParamsAndRedirect({
      offset: 0,
      limit: 20,
      countryId,
    });
  };

  const isUserAdmin = () => {
    const user = JSON.parse(localStorage.getItem(USER_KEY));
    //// attention: user stored in localStorage (loggedIn user) is different than attraction.User (assignee)
    if (
      user.userRole &&
      (user.userRole === 'Admin' ||
        user.userRole === 'ADMIN' ||
        user.userRole === 'admin')
    ) {
      return true;
    }
    return false;
  }

  const getStatusDropdownTitle = (title) => {
    if (title === 'all') {
      return 'STATUS ';
    }
    return title;
  }

  const getHumanReadableTime = (time) => {
    var localDate = new Date(time);
    return localDate.toLocaleString();
  }

  const toggleStatusDropdown = () => {
    setState({...state, isStatusDropdownOpen: !state.isStatusDropdownOpen});
  };

  const handlePageChange = async (pageNumber) => {
    let offset = (pageNumber - 1) * pageLength;
    let limit = pageLength;
    setState(
      {
        ...state,
        activePage: pageNumber,
        offset,
        limit,
      },
    );
  };

  return (
    <>
      <AdminNavbar
        {...props}
        brandText={'Collections'}
        value={search}
        onChange={(value) =>
          parseParamsAndRedirect({
            offset: 0,
            limit: 20,
            search: value,
          })
        }
      />
      <Header/>

      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="6">
                    <h3 className="mb-0">
                      Collections ({state.collectionsLength})
                    </h3>
                  </Col>
                  <Col
                    className="text-right"
                    xs="6"
                    // style={{ backgroundColor: "blue" }}
                  >
                    <Row className="align-items-center">
                      <Col xs="6">
                        <FormGroup className="text-left">
                          <label
                            className="form-control-label"
                            htmlFor="filter-country"
                          >
                            COUNTRY
                          </label>
                          <CountrySelect
                            value={countryId}
                            onChange={(e) =>
                              handleCountryChange(e?.value)
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <FormGroup className="text-left">
                          <label
                            className="form-control-label"
                            htmlFor="filter-destination"
                          >
                            DESTINATIONS
                          </label>
                          <Select
                            id="filter-destination"
                            name="destination"
                            value={state.selectedDestination}
                            options={state.countryId ? state.destinationsList.filter(({Country}) => Country?.id === state.countryId) : state.destinationsList}
                            onChange={handleDestinationChange}
                            placeholder="Filter by Destination"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Dropdown
                      isOpen={state.isStatusDropdownOpen}
                      toggle={toggleStatusDropdown}
                      // style={{ width: 200 }}
                      // hidden={!state.isAdmin}
                    >
                      <DropdownToggle caret>
                        {getStatusDropdownTitle(
                          currentStatus,
                        )}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => handleClickStatus('ALL', e)}
                        >
                          All
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            handleClickStatus('DATA ADDED', e)
                          }
                        >
                          DATA ADDED
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            handleClickStatus('PHOTO UPLOADED', e)
                          }
                        >
                          PHOTO UPLOADED
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) =>
                            handleClickStatus('PUBLISHED', e)
                          }
                        >
                          PUBLISHED
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    <Link
                      to={{
                        pathname: `/admin/collections/new`,
                        historyObj: state,
                      }}
                    >
                      <Button color="primary" href="#new" size="xm">
                        Add new
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr style={{marginLeft: 10}}>
                  <th scope="col">Name</th>
                  <th scope="col">Content Type</th>
                  <th scope="col">Status</th>
                  <th scope="col">Destination</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Updated At</th>
                  <th scope="col"/>
                </tr>
                </thead>
                <tbody>
                {state.collections.map((collection) => {
                  const collectionColorState =
                    collection.status === 'DATA ADDED'
                      ? 'warning'
                      : collection.status === 'PHOTO UPLOADED' ||
                      collection.status === 'UNPUBLISHED'
                        ? 'info'
                        : collection.status !== 'PUBLISHED'
                          ? 'warning'
                          : 'success';
                  return (
                    <tr>
                      <th scope="row">
                        <Link
                          to={{
                            pathname: `/admin/collections/${collection.id}`,
                            historyObj: state,
                          }}
                        >
                          <Media className="align-items-center">
                            <Media style={{marginLeft: 10}}>
                                  <span
                                    className="mb-0 text-xm"
                                    style={{fontSize: 16}}
                                  >
                                    {collection.name}
                                  </span>
                            </Media>
                          </Media>
                        </Link>
                      </th>

                      <td>
                        <Badge
                          color=""
                          className="badge-dot mr-4"
                          style={{fontSize: 13, fontWeight: 'bold'}}
                        >
                          {collection.contentType}
                        </Badge>
                      </td>
                      <td>
                        {/* DATA ADDED = red dot PHOTO UPLOADED = blue dot
                            PUBLISHED = green dot */}
                        <Badge
                          color=""
                          className="badge-dot mr-4"
                          style={{fontSize: 13, fontWeight: 'bold'}}
                        >
                          <i
                            className={`bg-${collectionColorState}`}
                            style={{width: 9, height: 9}}
                          />
                          {collection.status}
                        </Badge>
                      </td>

                      <td>
                        <Media className="align-items-center">
                          <Media style={{marginLeft: 10}}>
                                <span
                                  className="mb-0 text-xm"
                                  style={{fontSize: 16}}
                                >
                                  {collection.Destination ? `${collection?.Destination?.name}, ${collection?.Destination?.Country?.name}` : '-'}
                                </span>
                          </Media>
                        </Media>
                      </td>

                      <td>
                        <label>
                          {getHumanReadableTime(collection.createdAt)}
                        </label>
                      </td>
                      <td>
                        <label>
                          {getHumanReadableTime(
                            collection.createdAt,
                          ) ===
                          getHumanReadableTime(collection.updatedAt)
                            ? 'Same as created'
                            : getHumanReadableTime(
                              collection.updatedAt,
                            )}
                        </label>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            // href="#pablo"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v"/>
                          </DropdownToggle>
                          <DropdownMenu
                            className="dropdown-menu-arrow"
                            right
                          >
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Action
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Another action
                            </DropdownItem>
                            <DropdownItem
                              // href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              Something else here
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                    // </Link>
                  );
                })}
                </tbody>
              </Table>
              <p className="pl-3 pt-3">{!state.collections.length ? 'No collections found' : ''}</p>
              <CardFooter>
                {state.collectionsLength ? <Pagination
                  currentPage={state.activePage}
                  totalSize={state.collectionsLength}
                  sizePerPage={pageLength}
                  changeCurrentPage={handlePageChange}
                  theme="bootstrap"
                  style={{zIndex: 99}}
                /> : ''}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(Collections);
