import React, {useEffect, useState} from 'react';
import Pagination from 'react-pagination-js';
import ReactTooltip from 'react-tooltip';
import Header from 'components/Headers/Header.js';
import {Card, Container, Row, Spinner, Table} from 'reactstrap';
import CardFooter from 'reactstrap/lib/CardFooter';
import BackendService from 'services/BackendService';

import en from 'assets/flags/us.svg';
import fr from 'assets/flags/fr.svg';
import it from 'assets/flags/it.svg';
import ro from 'assets/flags/ro.svg';
import de from 'assets/flags/de.svg';
import ru from 'assets/flags/ru.svg';
import pt from 'assets/flags/pt.svg';
import es from 'assets/flags/es.svg';
import Filters from './Filters';
import ModalComponent, {camelCaseToText} from './Modal';

const flags = {
  en,
  fr,
  it,
  ro,
  de,
  ru,
  pt,
  es,
};

const ELEMENTS_PER_PAGE = 20;

const Translations = () => {
  const [translations, setTranslations] = useState([]);
  const [count, setCount] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState({
    contentType: [
      {value: 'collections', label: 'Collection'},
      {value: 'sections', label: 'Sections'},
      {value: 'countries', label: 'Countries'},
      {value: 'attractions', label: 'Attractions'},
      {value: 'destinations', label: 'Destinations'},
    ],
  });
  const toggle = () => setModal(false);

  useEffect(() => {
    async function onMount() {
      setIsLoading(true);
      const {count, results} = await BackendService.getTranslations(
        filters,
        (currentPage - 1) * ELEMENTS_PER_PAGE,
      );
      setIsLoading(false);
      setTranslations(results);
      setCount(count);
    }
    onMount();
  }, [filters, currentPage]);

  const setComposedFilters = (filterToChange) => {
    setFilters({...filters, ...filterToChange});
  };

  return (
    <div className="Translations">
      <Header />
      <ModalComponent {...{toggle, modal, translations, setTranslations}} />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Filters setFilters={setComposedFilters} filters={filters} />
              {isLoading ? (
                <div className="Loading__wrapper">
                  <Spinner color="primary" />
                </div>
              ) : (
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr style={{marginLeft: 10}}>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      {Object.keys((translations || [])[0]?.i18n || {}).map(
                        (key) => (
                          <th scope="col">{key}</th>
                        ),
                      )}
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {translations.length ? (
                      translations.map(({content, contentType, i18n}) => (
                        <tr>
                          <td>
                            <span
                              className="Translations__name"
                              data-tip={`${Object.keys(i18n?.original).map(
                                (key) =>
                                  `${camelCaseToText(key)}: ${
                                    i18n?.original[key]
                                  } <br/>`,
                              )}`}
                            >
                              {i18n?.original?.name || content?.name}
                            </span>
                            <ReactTooltip
                              className="Translations__tooltip"
                              multiline
                            />
                          </td>
                          <td>{contentType.toUpperCase()}</td>
                          {Object.keys((translations || [])[0]?.i18n || {}).map(
                            (key) => (
                              <th className="lang__field" scope="col">
                                <div>
                                  <span
                                    onClick={() =>
                                      setModal({
                                        original: i18n.original,
                                        content: i18n[key],
                                        contentType,
                                        contentId: content?.id,
                                        name: content?.name,
                                        lang: key,
                                      })
                                    }
                                    className="cursor-pointer"
                                  >
                                    {key === 'original' ? (
                                      <i className="fa fa-globe text-pink" />
                                    ) : (
                                      <img
                                        width="20"
                                        src={flags[key]}
                                        alt={key}
                                      />
                                    )}
                                  </span>
                                </div>
                              </th>
                            ),
                          )}
                        </tr>
                      ))
                    ) : (
                      <div className="no-results">No results found</div>
                    )}
                  </tbody>
                </Table>
              )}
              <CardFooter className="py-4">
                <Pagination
                  currentPage={currentPage}
                  totalSize={count / ELEMENTS_PER_PAGE}
                  sizePerPage={ELEMENTS_PER_PAGE}
                  changeCurrentPage={(page) => setCurrentPage(page)}
                  theme="bootstrap"
                />
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Translations;
