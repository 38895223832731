import React, {useState} from 'react';
import Select from 'react-select';
import {CardHeader, Row, Col, FormGroup} from 'reactstrap';
import Dropdown from 'reactstrap/lib/Dropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import Input from 'reactstrap/lib/Input';

const levels = [
  {value: '4', label: 'Regular'},
  {value: '5', label: 'Creator'},
  {value: '6', label: 'Ambassador'},
];

const Filters = ({
  level,
  setLevel,
  verified,
  setVerified,
  search,
  setSearch,
}) => {
  const [verifiedDropdown, setverifiedDropdown] = useState(false);
  return (
    <CardHeader className="border-0">
      <Row className="align-items-center">
        <Col xs="4">
          <h3 className="mb-0">Users</h3>
        </Col>
        <Col xs="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-contentType">
              Search
            </label>
            <Input value={search} onChange={(e) => setSearch(e.target.value)} />
          </FormGroup>
        </Col>
        <Col xs="4">
          <FormGroup>
            <label className="form-control-label" htmlFor="filter-contentType">
              Levels
            </label>
            <Select
              id="filter-contentType"
              name="contentType"
              value={level}
              onChange={(value) => {
                setLevel(value);
              }}
              options={levels}
              placeholder="Filter by level"
              multi
            />
          </FormGroup>
        </Col>
        <Col xs={4}></Col>
        <FormGroup className="col-6 switch">
          <Dropdown
            isOpen={verifiedDropdown}
            toggle={() => setverifiedDropdown(!verifiedDropdown)}
          >
            <DropdownToggle caret>
              {`Is user verified: `}
              {verified === null ? 'ALL' : `${verified ? 'YES' : 'NO'}`}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                onClick={() => {
                  setVerified(null);
                  setverifiedDropdown(false);
                }}
              >
                All
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setVerified('TRUE');
                  setverifiedDropdown(false);
                }}
              >
                Yes
              </DropdownItem>
              <DropdownItem
                onClick={(e) => {
                  setVerified('FALSE');
                  setverifiedDropdown(false);
                }}
              >
                No
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </FormGroup>
      </Row>
    </CardHeader>
  );
};

export default Filters;
