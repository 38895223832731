import React from 'react';
// reactstrap components
import {Navbar} from 'reactstrap';

class AdminNavbar extends React.Component {
  render() {
    return (
      <>
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        ></Navbar>
      </>
    );
  }
}

export default AdminNavbar;
