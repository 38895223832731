import React from 'react';

const StatusFormatter = ({status}) => {
  switch (status) {
    case 'PUBLISHED':
      return <div className="text-green">APPROVED</div>;
    case 'UNPUBLISHED':
      return <div className="text-gray">PENDING</div>;
    case 'REJECTED':
      return <div className="text-red">REJECTED</div>;

    default:
      return <div>{status}</div>;
  }
};

export default StatusFormatter;
