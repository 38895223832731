import {useMutation} from 'react-query';
import {client} from './api-client';

import {JWT_KEY} from '../utils/consts';

const token = localStorage.getItem(JWT_KEY);

export function useEditAlbum(albumId, refetchAlbum) {
  return useMutation(
    (data) =>
      client(`v1/albums/${albumId}`, {
        token,
        method: 'PATCH',
        data,
      }),
    {onSettled: () => refetchAlbum && refetchAlbum()},
  );
}
