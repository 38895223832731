import * as React from 'react';
import {useEffect, useState} from 'react';
import {Form, Formik} from "formik"
import {Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import BackendService from "../../services/BackendService";
import {toast, ToastContainer} from "react-toastify";
import ChooseCategory from "./ChooseCategory";
import AssignedUsers from "./AssignedUsers";

export default function EditCategory(
  props
) {
  const {
    id,
    description,
    name,
    parentId,
    iconUrl,
    selectedParents,
    categories,
    refetch,
    setRefetch
  } = props;
  const [deleteModal, setDeleteModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [selectedParent, setSelectedParent] = useState({})
  const toggleDelete = () => setDeleteModal(!deleteModal)
  useEffect(() => {
    setSelectedParent({})
  }, [id])
  return (
    <Col xs={6}>
      <ToastContainer/>
      <div className="max-width-500 mb-3">
        <h3 className="mb-3">{id ? `Edit category ${name} (#${id})` : 'Add new category'}</h3>
        <Formik
          key={id}
          initialValues={{description, name, parentId, iconUrl}}
          onSubmit={async val => {
            if (!id) {
              try {
                await BackendService.addAttractionCategories(val)
                setRefetch(!refetch)
                toast.success('Successfully created category');
              } catch (e) {
                toast.error(e.message);
              }
            } else {
              try {
                await BackendService.updateAttractionCategories(val, id)
                setRefetch(!refetch)
                toast.success('Successfully updated category');
              } catch (e) {
                toast.error(e.message);
              }
            }
          }}
        >
          {({values, setFieldValue, handleChange}) => (
            <Form>
              <Modal isOpen={deleteModal} toggle={toggleDelete} size="md">
                <ModalHeader toggle={toggleDelete}>
                  Delete category
                </ModalHeader>
                <ModalBody>
                  Are you sure that you want to delete this category #{id}?
                </ModalBody>
                <ModalFooter>
                  <Button color="warning" onClick={async () => {
                    try {
                      await BackendService.deleteAttractionCategories(id)
                      setRefetch(!refetch)
                      toggleDelete()
                      toast.success('Successfully deleted category');
                    } catch (e) {
                      toast.error(e.message);
                    }
                  }}>Delete</Button>
                </ModalFooter>
              </Modal>
              <FormGroup>
                <Label>Name</Label>
                <Input bsSize="sm" name="name" onChange={handleChange} defaultValue={values.name}/>
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input bsSize="sm" name="description" onChange={handleChange} value={values.description}/>
              </FormGroup>
              <FormGroup>
                <Label>Icon url</Label>
                <Input bsSize="sm" name="iconUrl" onChange={handleChange} value={values.iconUrl}/>
              </FormGroup>
              <Input bsSize="sm" hidden value={values.parentId}/>
              <ChooseCategory
                label="Parent" modal={modal} setModal={setModal} setSelected={setSelectedParent}
                categories={categories} onClear={() => setFieldValue("parentId", null)}
                onSelect={() => setFieldValue("parentId", selectedParent.id)} selected={selectedParent}
                value={selectedParent.name || (parentId && (selectedParents.filter(({id: parentid}) => parentid === values.parentId)[0] || {}).name)}/>
              <Button color="primary">{id ? 'Update' : 'Create'}</Button>
              {id ? <Button color="warning" onClick={() => setDeleteModal(true)} type="button">Delete</Button> : ''}
            </Form>
          )}
        </Formik>
        <AssignedUsers {...props}/>
      </div>
    </Col>
  )
}
